<template>
    <GFTable
        :cols="[['key', 'Key'], 'actions']"
        :data="data"
        flash-new-rows
        flash-new-property="updated_at"
    >
        <template #key="{ row }">
            <div>
                <div class="flex flex-wrap items-center space-x-2">
                    <div class="font-semibold text-gray-700">
                        {{ row.label }}
                    </div>
                    <GFTag v-if="row.service_name">
                        {{ row.service_name }}
                    </GFTag>
                </div>

                <div v-if="row.description" class="mt-1 text-sm text-gray-700">
                    {{ row.description }}
                </div>

                <div class="mt-1 text-sm">
                    {{ row.key }}
                </div>
            </div>
        </template>

        <template #actions="{ row }">
            <div class="flex items-center justify-end space-x-2">
                <GFButtonIcon
                    icon="pencil"
                    class="h-6 w-6"
                    @click="emit('edit', row)"
                />

                <GFButtonIcon
                    icon="trash"
                    danger
                    :loading="deletingId === row.id"
                    class="h-6 w-6"
                    @click="onDeleteStub(row.id)"
                />

                <GFButtonText
                    size="sm"
                    class="-m-1"
                    @click="
                        $router.push({
                            name: 'stub',
                            params: { stubId: row.id },
                        })
                    "
                >
                    View
                </GFButtonText>
            </div>
        </template>
    </GFTable>
</template>

<script setup lang="ts">
import type Stub from "@/models/Stub"
import GFTag from "../base/GFTag.vue"
import GFTable from "../base/GFTable.vue"
import GFButtonIcon from "../base/GFButtonIcon.vue"
import GFButtonText from "../base/GFButtonText.vue"
import { useStubDelete } from "@/queries/stubs/useStubDelete"
import { useBillingModelType } from "@/composables/useBillingModelType"
import { useAllStubs, type StubQueryKey } from "@/queries/stubs/useStubsQuery"

const emit = defineEmits<{
    (event: "edit", stub: Stub): void
}>()

const { modelId, modelType } = useBillingModelType()

const stubKey: StubQueryKey = computed(() => ({
    modelType: modelType.value,
    modelId: modelId.value,
}))

const { data } = useAllStubs(stubKey)

const { mutate: deleteMutation } = useStubDelete()

const deletingId = ref<null | Stub["id"]>(null)

function onDeleteStub(stubId: string) {
    if (!modelId.value) return

    deletingId.value = stubId

    deleteMutation(
        {
            modelId: modelId.value,
            modelType: modelType.value,
            stubId,
        },
        {
            onSettled: () => {
                deletingId.value = null
            },
        }
    )
}
</script>
