import type { ApiGlobalId, ApiUserData, UserStatus } from "types/api"
import type { AvatarStatusColor } from "types/types"
import { Billable } from "./Billable"

export default class User extends Billable {
    id: ApiGlobalId
    avatar_base64?: string
    created_at: string
    deleted_at?: null | string
    disabled_at: null | string
    email: string
    human_readable_activity_status: UserStatus
    email_verified_at: string
    first_name: string
    full_name: string
    human_readable_created_at: string
    human_readable_deleted_at: string | null
    human_readable_updated_at: string
    is_active: boolean
    last_name: string
    updated_at: string
    is_support_staff: boolean
    is_developer: boolean
    is_billable?: boolean

    constructor(data: ApiUserData) {
        super()

        this.avatar_base64 = data.avatar_base64
        this.created_at = data.created_at
        this.deleted_at = data.deleted_at
        this.disabled_at = data.disabled_at
        this.email = data.email
        this.human_readable_activity_status =
            data.human_readable_activity_status
        this.email_verified_at = data.email_verified_at
        this.first_name = data.first_name
        this.full_name = data.full_name
        this.human_readable_created_at = data.human_readable_created_at
        this.human_readable_deleted_at = data.human_readable_deleted_at
        this.human_readable_updated_at = data.human_readable_updated_at
        this.id = data.id
        this.is_active = data.is_active
        this.last_name = data.last_name
        this.updated_at = data.updated_at
        this.is_support_staff = data.is_support_staff
        this.is_developer = data.is_developer

        this.is_billable = data.is_billable
    }

    get statusColor(): AvatarStatusColor {
        if (this.human_readable_activity_status === "away") return "yellow"
        if (this.human_readable_activity_status === "online") return "green"
        return "gray"
    }

    get verifiedEmail() {
        return this.email_verified_at !== null
    }

    get isDeveloper() {
        return this.is_developer
    }

    get isSupportStaff() {
        return this.is_support_staff
    }
}
