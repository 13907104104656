<template>
    <div :key="orgId" class="flex flex-col space-y-8">
        <SectionLayout
            id="subscription-plan"
            title="Subscription Plan"
            description=" Manage what Galaxy Forms subscription plan you use."
        >
            <UpdateSubscriptionPlanForm
                :subscription="subscription ?? null"
                :subscription-refetching="subscriptionRefetching"
                :subscription-loading="subscriptionIsLoading"
                :has-access-code="accessCodeData ?? false"
                :current-seat-occupancy="currentSeatOccupancy"
                @add-payment-method="showNewPM"
            />
        </SectionLayout>

        <SectionLayout
            v-if="showPaymentMethods"
            title="Payment Methods"
            description="Manage which payment methods we have on file for you. Your set default payment method will be used to bill your account."
            is-table
        >
            <template #actions>
                <GFButton label="Add card" @click="showNewPM" />
            </template>

            <PaymentMethodsTable
                :cards="cards ?? []"
                :is-loading="cardsLoading"
            />
        </SectionLayout>

        <SectionLayout
            v-if="modelId && subscription"
            title="Promo codes"
            description="Add a promo code to your account to get a discount on your subscription. Only one promo code can be applied at a time."
        >
            <PromoCodeForm
                :model-id="modelId"
                :model-type="modelType"
                :subscription="subscription ?? null"
            />
        </SectionLayout>

        <SectionLayout
            v-if="modelId"
            title="Access code"
            description="Add an access code to your account."
        >
            <AccessCodeForm
                :model-id="modelId"
                :model-type="modelType"
                :access-code-data="accessCodeData"
                :access-code-loading="accessCodeLoading"
            />
        </SectionLayout>

        <SectionLayout
            title="Invoices"
            description="Here are your most recent invoices."
            is-table
        >
            <template #actions>
                <GFButton
                    v-if="invoice"
                    label="View upcoming invoice"
                    @click="showUpcomingInvoice = true"
                />
            </template>

            <InvoiceTable />
        </SectionLayout>

        <SectionLayout
            v-if="modelId && subscription && modelType === 'organizations'"
            title="Billing email"
            description="The email address we will send this organization's invoices to."
        >
            <OrganizationBillingEmail />
        </SectionLayout>

        <SectionInviteUsers :users="users ?? []" :invitees="invitees ?? []" />

        <SectionOrganizationUsers :users="users ?? []" />

        <CreateNewPaymentMethodModal
            v-model:show="showAddCardModal"
            @closed="resetNewPmModal"
            @payment-method-added="paymentMethodAdded"
        />

        <UpcomingInvoiceModal
            v-if="invoice"
            v-model:show="showUpcomingInvoice"
            :invoice="invoice"
            :subscription="subscription ?? undefined"
        />
    </div>
</template>
<script lang="ts" setup>
import GFButton from "../base/GFButton.vue"
import { useUserStore } from "@/stores/user"
import InvoiceTable from "./InvoiceTable.vue"
import PromoCodeForm from "./PromoCodeForm.vue"
import AccessCodeForm from "./AccessCodeForm.vue"
import SectionLayout from "../layout/SectionLayout.vue"
import PaymentMethodsTable from "./PaymentMethodsTable.vue"
import { useOrganizationStore } from "@/stores/organization"
import OrganizationBillingEmail from "./OrganizationBillingEmail.vue"
import UpcomingInvoiceModal from "../modals/UpcomingInvoiceModal.vue"
import SectionInviteUsers from "./organizations/SectionInviteUsers.vue"
import UpdateSubscriptionPlanForm from "./UpdateSubscriptionPlanForm.vue"
import { useAccessCodeQuery } from "@/queries/access-codes/useAccessCodeQuery"
import CreateNewPaymentMethodModal from "../modals/CreateNewPaymentMethodModal.vue"
import SectionOrganizationUsers from "./organizations/SectionOrganizationUsers.vue"
import { useUpcomingInvoiceQuery } from "@/queries/invoices/useUpcomingInvoiceQuery"
import { usePaymentMethodsQuery } from "@/queries/payment-methods/usePaymentMethodsQuery"
import { useOrganizationUsersAllQuery } from "@/queries/organizations/useOrganizationUsers"
import { useOrgInvitationsAllQuery } from "@/queries/org-invitations/useOrgInvitationsQuery"
import { useModelSubscriptionQuery } from "@/queries/subscription/useModelSubscriptionQuery"

const { orgId, orgIdString } = storeToRefs(useOrganizationStore())
const { user } = storeToRefs(useUserStore())

const showAddCardModal = ref(false)
const showUpcomingInvoice = ref(false)
const callbackAfterNewPmSuccess = ref<() => void>(() => ({}))

const isOrg = computed(() => orgId.value !== "private")
const modelId = computed(() => (isOrg.value ? orgId.value : user.value?.id))
const modelType = computed(() => (isOrg.value ? "organizations" : "users"))

const { data: invoice } = useUpcomingInvoiceQuery(modelType, modelId)

const { data: users } = useOrganizationUsersAllQuery(orgId)
const { data: invitees } = useOrgInvitationsAllQuery(orgIdString)

const { data: accessCodeData, isLoading: accessCodeLoading } =
    useAccessCodeQuery(modelType, modelId)

const {
    data: subscription,
    isLoading: subscriptionIsLoading,
    isRefetching: subscriptionRefetching,
} = useModelSubscriptionQuery(modelType, modelId)

const { data: cards, isLoading: cardsLoading } = usePaymentMethodsQuery(
    modelType,
    modelId
)

const currentSeatOccupancy = computed(() => {
    const inviteesCount = invitees.value?.length ?? 0
    const usersCount = users.value?.length ?? 0

    return inviteesCount + usersCount
})

const showPaymentMethods = computed(() => {
    if (!!cards.value && cards.value?.length > 0) return true
    return !!subscription.value
})

function showNewPM(cbWhenSuccess?: () => void) {
    showAddCardModal.value = true
    if (cbWhenSuccess) callbackAfterNewPmSuccess.value = cbWhenSuccess
}

function resetNewPmModal() {
    showAddCardModal.value = false
    callbackAfterNewPmSuccess.value = () => ({})
}

function paymentMethodAdded() {
    if (typeof callbackAfterNewPmSuccess.value !== "function") return
    callbackAfterNewPmSuccess.value()
}
</script>
