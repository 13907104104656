import type {
    ApiGlobalId,
    ApiOrganizationData,
    ApiOrganizationUserData,
    OrganizationUserRole,
} from "types/api"
import GFLogo from "@/assets/images/galaxy-forms-favicon-white.png"
import type User from "./User"
import { Billable } from "./Billable"

export default class Organization extends Billable {
    id: ApiGlobalId
    name: string
    primary_color: string | null
    secondary_color: string | null
    created_at: string | null
    updated_at: string | null
    deleted_at?: string | null
    created_by?: User["id"] | null
    updated_by?: User["id"] | null
    is_active: boolean
    logo_base64?: string | null
    /** If the authenticated user is a signee, then this will be null. */
    authOrganizationUser?: ApiOrganizationUserData | null
    is_billable?: boolean

    constructor(data: ApiOrganizationData) {
        super()

        this.id = data.id
        this.name = data.name
        this.primary_color = data.primary_color
        this.secondary_color = data.secondary_color
        this.created_at = data.created_at
        this.updated_at = data.updated_at
        this.deleted_at = data.deleted_at
        this.created_by = data.created_by
        this.updated_by = data.updated_by
        this.is_active = data.is_active
        this.logo_base64 = data.logo_base64
        this.authOrganizationUser = data.authOrganizationUser
        this.is_billable = data.is_billable
    }

    get logo() {
        return this.logo_base64 ?? GFLogo
    }

    /**
     * Returns the role of the authenticated user in this organization.
     */
    get authUserRole(): OrganizationUserRole | undefined {
        if (this.authOrganizationUser === undefined) return undefined
        if (this.authOrganizationUser === null) return "signee"
        return this.authOrganizationUser.role
    }
}
