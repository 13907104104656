<template>
    <GFModalSimple
        v-model:show="showInput"
        size="large"
        title="Upcoming invoice"
    >
        <template #buttons>
            <GFButtonSimple label="Close" @click="showInput = false" />
        </template>

        <div
            class="flex max-h-96 w-full flex-col space-y-1 overflow-y-auto overflow-x-hidden px-2 md:max-h-full"
        >
            <dl class="mt-5 space-y-6 text-sm font-medium text-gray-500">
                <div class="flex justify-between">
                    <dt>Date</dt>

                    <dd class="text-gray-900">
                        <span>
                            {{ invoice.readableCreatedAt }}
                        </span>
                    </dd>
                </div>

                <div
                    v-for="(lineItem, i) in invoice.line_items ?? []"
                    :key="i"
                    class="flex justify-between"
                >
                    <dt>{{ lineItem.description }}</dt>

                    <dd class="text-gray-900">
                        <span>
                            {{ toCurrency(lineItem.amount) }}
                        </span>
                    </dd>
                </div>

                <dl class="space-y-6 border-t border-gray-200 py-6">
                    <div class="flex items-center justify-between">
                        <dt class="text-sm">Subtotal</dt>

                        <dd class="text-sm font-medium text-gray-900">
                            <div class="flex flex-col text-end">
                                <span>{{ invoice.subtotal }}</span>
                            </div>
                        </dd>
                    </div>

                    <div
                        v-if="subscription?.applied_promo_code"
                        class="flex items-center justify-between"
                    >
                        <dt class="text-sm">Applied promo code</dt>

                        <dd class="text-sm font-medium text-gray-900">
                            <div class="flex flex-col text-end">
                                <span>{{
                                    subscription.applied_promo_code.name
                                }}</span>
                            </div>
                        </dd>
                    </div>

                    <div class="flex items-center justify-between">
                        <dt class="text-sm">Tax</dt>

                        <dd class="text-sm font-medium text-gray-900">
                            <div class="flex flex-col text-end">
                                <span>{{ invoice.tax }}</span>
                            </div>
                        </dd>
                    </div>

                    <div
                        class="flex flex-col space-y-3 border-t border-gray-200 pt-6 text-base font-medium text-gray-900"
                    >
                        <div class="flex w-full items-center justify-between">
                            <dt>Total</dt>

                            <dd class="text-gray-900">
                                <div class="flex flex-col text-end">
                                    <span>{{ invoice.total }}</span>
                                </div>
                            </dd>
                        </div>
                    </div>
                </dl>
            </dl>
        </div>
    </GFModalSimple>
</template>
<script setup lang="ts">
import GFModalSimple from "../base/GFModalSimple.vue"
import GFButtonSimple from "../base/GFButtonSimple.vue"
import type { Invoice } from "@/models/Invoice"
import type { ModelSubscription } from "@/models/ModelSubscription"

const emit = defineEmits<{
    (e: "update:show", show: boolean): void
}>()

const props = defineProps<{
    show: boolean
    invoice: Invoice
    subscription?: ModelSubscription
}>()

const showInput = computed({
    get: () => props.show,
    set: (value) => emit("update:show", value),
})

function toCurrency(amount: number) {
    return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    }).format(amount / 100)
}
</script>
