<template>
    <SwitchGroup
        :class="[
            position === 'right' && 'flex items-center',
            position === 'top' && 'flex flex-col',
        ]"
        as="div"
    >
        <SwitchLabel
            v-if="position === 'top'"
            class="mb-1 text-sm font-medium text-gray-700"
        >
            <slot name="label">
                {{ label }}
            </slot>
        </SwitchLabel>
        <Switch
            v-model="inputValue"
            :class="inputValue ? 'bg-primary' : 'bg-primary-opacity-30'"
            class="relative inline-flex h-6 w-11 items-center rounded-full"
        >
            <span
                :class="inputValue ? 'translate-x-6' : 'translate-x-1'"
                class="inline-block h-4 w-4 transform rounded-full bg-white"
            />
        </Switch>
        <SwitchLabel v-if="position === 'right'" as="span" class="ml-3">
            <slot name="label">
                {{ label }}
            </slot>
        </SwitchLabel>
    </SwitchGroup>
</template>
<script setup lang="ts">
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue"
import type { PropType } from "vue"

const emit = defineEmits(["update:modelValue"])

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true,
    },
    label: {
        type: String,
        default: null,
    },
    position: {
        type: String as PropType<"top" | "right">,
        default: "top",
    },
})

const inputValue = computed<boolean>({
    get() {
        return props.modelValue
    },
    set(val) {
        emit("update:modelValue", val)
    },
})
</script>
