import type { ApiPaymentMethodData, CardDetails } from "types/api"

export class PaymentMethod {
    id: string
    friendly_name: string
    is_default: boolean
    card: CardDetails

    constructor(data: ApiPaymentMethodData) {
        this.id = data.id
        this.friendly_name = data.friendly_name
        this.is_default = data.is_default
        this.card = data.card
    }
}
