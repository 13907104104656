<template>
    <GFModalSimple
        v-model:show="showInput"
        title="Create subscription plan"
        size="large"
        @closed="reset"
    >
        <div class="flex flex-grow flex-col space-y-4">
            <SubscriptionPlanForm
                v-model:stripe-product-id="stripeProductIdInput"
                v-model:stripe-monthly-price-id="stripeMonthlyPriceIdInput"
                v-model:stripe-yearly-price-id="stripeYearlyPriceIdInput"
                v-model:is-stripe-plan="isStripePlan"
                v-model:fallback-name="fallbackName"
                v-model:fallback-description="fallbackDescription"
                v-model:fallback-monthly-price="fallbackMonthlyPrice"
                v-model:fallback-yearly-price="fallbackYearlyPrice"
                v-model:button-text="buttonText"
                v-model:button-url="buttonUrl"
                v-model:order="orderInput"
                :is-published="isPublished"
                hide-delete
            />
        </div>
        <template #buttons>
            <div class="flex items-center space-x-2">
                <GFButtonSimple label="Cancel" @click="showInput = false" />
                <GFButton label="Save" :loading="isCreating" @click="onSave" />
            </div>
        </template>
    </GFModalSimple>
</template>
<script setup lang="ts">
import GFButton from "../base/GFButton.vue"
import GFModalSimple from "../base/GFModalSimple.vue"
import GFButtonSimple from "../base/GFButtonSimple.vue"
import SubscriptionPlanForm from "./SubscriptionPlanForm.vue"
import { useSubscriptionPlanCreate } from "@/queries/subscription-plans/useSubscriptionPlanCreate"

const emit = defineEmits<{
    (event: "update:show", show: boolean): void
    (event: "update:is-published", show: boolean): void
}>()

const props = defineProps({
    show: {
        type: Boolean,
        required: true,
    },
})

const isPublished = ref(false)

const showInput = computed({
    get: () => props.show,
    set: (value) => emit("update:show", value),
})

const stripeProductIdInput = ref(null)
const stripeMonthlyPriceIdInput = ref(null)
const stripeYearlyPriceIdInput = ref(null)
const orderInput = ref(null)
const isStripePlan = ref(true)
const fallbackName = ref(null)
const fallbackDescription = ref(null)
const fallbackMonthlyPrice = ref(null)
const fallbackYearlyPrice = ref(null)
const buttonText = ref(null)
const buttonUrl = ref(null)

const { mutate: create, isLoading: isCreating } = useSubscriptionPlanCreate()

function onSave(e: MouseEvent) {
    e.preventDefault()

    create(
        {
            is_stripe_plan: isStripePlan.value,
            stripe_product_id: stripeProductIdInput.value,
            stripe_monthly_price_id:
                stripeMonthlyPriceIdInput.value ?? undefined,
            stripe_yearly_price_id: stripeYearlyPriceIdInput.value ?? undefined,
            order: orderInput.value ?? undefined,
            fallback_name: fallbackName.value ?? undefined,
            fallback_description: fallbackDescription.value ?? undefined,
            fallback_monthly_price: fallbackMonthlyPrice.value ?? undefined,
            fallback_yearly_price: fallbackYearlyPrice.value ?? undefined,
            button_txt: buttonText.value ?? undefined,
            button_url: buttonUrl.value ?? undefined,
        },
        {
            onSettled: () => {
                showInput.value = false
            },
        }
    )
}

function reset() {
    stripeProductIdInput.value = null
    stripeMonthlyPriceIdInput.value = null
    stripeYearlyPriceIdInput.value = null
    orderInput.value = null
}
</script>
