import type PDF from "@/models/PDF"
import type Signee from "@/models/Signee"
import type User from "@/models/User"
import type { Channel } from "laravel-echo"
import { echo } from "@/plugins/echo"

type DottedEvent = `.${EventType}`
type RawEvent = EventType

type ChannelName =
    | `pdfs.${PDF["id"]}.events`
    | `users.${User["id"]}.notifications`
    | `users.${User["id"]}.events`
    | "test-channel" // for testing, obviously

type WhisperEventName =
    | `presentation:user-join`
    | `presentation:user-leave`
    | `presentation:scroll-to-page`
    | `presentation:user-lobby-sync`
    | `presentation:drawing-rectangle-end`
    | `signing:signeeId-${Signee["id"]}-completed`
    | `presentation:drawing-rectangle-start`
    | `presentation:drawing-rectangle-update`
    | `signing:signeeId-${Signee["id"]}-updated-progress`
    | `collab-edit:translate-models`
    | `collab-edit:translate-models-end`
    | `collab-edit:user-mouse-off-page`
    | `collab-edit:mouse-move`
    | `collab-edit:models-update`

    // for testing, obviously
    | "test-whisper-event-1"
    | "test-whisper-event-2"

type EventType =
    | "merged"
    | "copied"
    | "merging"
    | "pages-merged"
    | "pdf-compiled"
    | "page-compiled"
    | "snapshot-created"
    | "snapshot-imported"
    | "engine-status-updated"
    | "energizer-progress-updated"
    | "*"

    // for testing, obviously
    | "test-event-1"
    | "test-event-2"

type ChannelStore = {
    instance: Channel
    events: EventType[]
    whisperEvents: WhisperEventName[]
}

type ListenerCallback<T> = (data: T) => void

export const useWebsocketsStore = defineStore("websockets", () => {
    const subscribedChannels = ref(new Map<ChannelName, ChannelStore>())

    function subscribe(channel: ChannelName): ChannelStore {
        const currentChannel = subscribedChannels.value.get(channel)
        if (currentChannel) return currentChannel

        const channelInstance = echo.private(channel)

        const channelStore: ChannelStore = {
            instance: channelInstance,
            events: [],
            whisperEvents: [],
        }

        subscribedChannels.value.set(channel, channelStore)

        return channelStore
    }

    function listen<T = unknown>(
        channelName: ChannelName,
        eventName: RawEvent,
        cb: ListenerCallback<T>
    ) {
        const channelStore = subscribe(channelName)

        channelStore.instance.listen(eventFormatted(eventName), cb)

        channelStore.events.push(eventName)
    }

    function unlisten(channelName: ChannelName, eventName: RawEvent) {
        const channelStore = subscribe(channelName)

        channelStore.instance.stopListening(eventFormatted(eventName))

        channelStore.events = channelStore.events.filter(
            (event) => event !== eventName
        )
    }

    function eventFormatted(event: EventType): DottedEvent {
        return `.${event}`
    }

    function leaveChannel(channelName: ChannelName) {
        echo.leaveChannel(channelName)
        subscribedChannels.value.delete(channelName)
    }

    function listenWhisper<T = unknown>(
        channelName: ChannelName,
        whisperEventName: WhisperEventName,
        cb: ListenerCallback<T>
    ) {
        const channelStore = subscribe(channelName)

        channelStore.instance.listenForWhisper(whisperEventName, cb)
        channelStore.whisperEvents.push(whisperEventName)
    }

    function stopListeningToWhisper(
        channelName: ChannelName,
        whisperEventName: WhisperEventName
    ) {
        const channelStore = subscribe(channelName)

        channelStore.instance.stopListeningForWhisper(whisperEventName)

        channelStore.whisperEvents = channelStore.whisperEvents.filter(
            (event) => event !== whisperEventName
        )
    }

    function whisper<T = any>(
        channel: ChannelName,
        event: WhisperEventName,
        payload?: T
    ) {
        // @ts-ignore: echo package seems to be missing whisper method
        echo.private(channel).whisper(event, payload)
    }

    function leaveAllChannels() {
        subscribedChannels.value.forEach((channel, channelName) => {
            leaveChannel(channelName)
        })
    }

    function stopListeningToAllEvents(channelName: ChannelName) {
        const channelStore = subscribe(channelName)

        // @ts-ignore: Method not documented in echo package
        channelStore.instance.stopListeningToAll()

        channelStore.events = []
    }

    function isListeningToEvent(
        channelName: ChannelName,
        eventName: RawEvent
    ): boolean {
        return (
            subscribedChannels.value
                .get(channelName)
                ?.events.includes(eventName) === true
        )
    }

    /**
     * Listen to all events on a channel.
     *
     * WARNING: This will listen to all events on the channel but not track them in the store. Do not use this method if you need to unlisten to events.
     */
    function listenToAllEvents<T = unknown>(
        channelString: ChannelName,
        cb: ListenerCallback<T>
    ) {
        const channelStore = subscribe(channelString)

        // @ts-ignore: Method not documented in echo package
        channelStore.instance.listenToAll(cb)

        channelStore.events = ["*"]
    }

    return {
        echo,
        subscribedChannels,
        subscribe,
        listen,
        unlisten,
        leaveChannel,
        listenWhisper,
        stopListeningToWhisper,
        whisper,
        leaveAllChannels,
        stopListeningToAllEvents,
        isListeningToEvent,
        listenToAllEvents,
    }
})
