<template>
    <GFTable
        :data="alerts"
        :cols="[['type', 'Type'], ['message', 'Message'], 'actions']"
        no-row-style
        data-testid="alerts-table"
    >
        <template #type="{ row: alert }">
            <div class="ml-6">
                <GFTag :label="alert.type" :color="alert.color" />
            </div>
        </template>
        <template #message="{ fieldData: message }">
            <div
                class="flex max-w-3xl flex-wrap overflow-hidden py-4 pl-4 pr-3 text-sm text-gray-500"
            >
                {{ message }}
            </div>
        </template>
        <template #actions="{ row: alert }">
            <div class="flex items-center space-x-2">
                <GFButtonIcon
                    icon="trash"
                    data-testid="delete-alert-button"
                    danger
                    rem-size="0.8"
                    :loading="alertBeingRemoved === alert.id"
                    class="h-6 w-6"
                    @click="removeAlert(alert as Alert)"
                />
            </div>
        </template>
    </GFTable>
</template>
<script setup lang="ts">
import type { PropType } from "vue"
import GFTag from "../base/GFTag.vue"
import GFTable from "../base/GFTable.vue"
import type { Alert } from "@/models/Alert"
import GFButtonIcon from "../base/GFButtonIcon.vue"

const emit = defineEmits<{
    (e: "remove-alert", alert: Alert): void
}>()

defineProps({
    alerts: {
        type: Array as PropType<Alert[]>,
        required: true,
    },
})

const alertBeingRemoved = ref<Alert | null>(null)

function removeAlert(alert: Alert) {
    alertBeingRemoved.value = alert
    emit("remove-alert", alert)
}
</script>
