<template>
    <div>
        <Combobox
            v-model="selectedInput"
            as="div"
            :nullable="nullable"
            :by="by"
        >
            <ComboboxLabel
                v-if="label"
                :for="name"
                class="flex justify-between text-sm font-medium text-gray-700"
            >
                <slot>{{ label }}</slot>

                <span
                    v-if="showOptional"
                    class="ml-full mr-0.5 font-normal italic text-gray-400"
                >
                    Optional
                </span>
            </ComboboxLabel>
            <div class="relative mt-1">
                <GFSpinner
                    v-if="loading"
                    class="absolute top-1/4 right-[2.5%] z-20 h-5 w-5 text-gray-400"
                />
                <ComboboxInput
                    class="block w-full border focus:outline-none sm:text-sm"
                    :class="[
                        !hasErrors &&
                            'relative appearance-none rounded-none rounded-t-md rounded-b-md border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-400 focus:z-10 focus:border-primary focus:ring-primary',
                        hasErrors && 'form-errors',
                    ]"
                    data-testid="combobox-input"
                    :placeholder="placeholder"
                    :disabled="disabled"
                    :display-value="(displayValue as any)"
                    @change="$nextTick(() => (query = $event.target.value))"
                />
                <ComboboxButton
                    v-show="!loading"
                    class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
                >
                    <ChevronUpDownIcon
                        class="h-5 w-5"
                        :class="[
                            disabled && 'text-gray-200',
                            !disabled && 'text-gray-400',
                        ]"
                        aria-hidden="true"
                    />
                </ComboboxButton>

                <ComboboxOptions
                    v-if="filteredList.length > 0"
                    data-testid="combobox-options"
                    class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                    <ComboboxOption
                        v-for="(item, i) in filteredList"
                        v-slot="{ active, selected }"
                        :key="i"
                        :value="item"
                        as="template"
                    >
                        <li
                            :class="[
                                'relative cursor-default select-none py-2 pl-8 pr-4',
                                active
                                    ? 'bg-primary text-primary-bw'
                                    : 'text-gray-900',
                            ]"
                        >
                            <slot
                                name="option"
                                :item="item"
                                :selected="selected"
                                :active="active"
                            >
                                <span
                                    :class="[
                                        'block truncate',
                                        selected && 'font-semibold',
                                    ]"
                                >
                                    {{
                                        displayOption
                                            ? displayOption(item)
                                            : item
                                    }}
                                </span>
                            </slot>
                            <span
                                v-if="selected"
                                :class="[
                                    'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                    active ? 'text-primary-bw' : 'text-primary',
                                ]"
                            >
                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                        </li>
                    </ComboboxOption>
                </ComboboxOptions>
            </div>
        </Combobox>

        <p v-if="hasErrors" id="email-error" class="mt-2 text-sm text-red-600">
            This field is required
        </p>
    </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid"
import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
} from "@headlessui/vue"
import type GFFormErrors from "@/helpers/GFFormErrors"
import GFSpinner from "./GFSpinner.vue"

type ListItem = Record<string, any>

const emit = defineEmits<{
    (event: "update:model-value", modelValue: ListItem | null): void
}>()

const props = withDefaults(
    defineProps<{
        modelValue?: ListItem | null
        list: ListItem[]
        by: string
        displayValue: (item: ListItem | null) => string
        displayOption?: (item: ListItem) => string
        name: string
        label?: string
        placeholder?: string
        nullable?: boolean
        formErrors?: GFFormErrors
        loading?: boolean
        disabled?: boolean
        showOptional?: boolean
    }>(),
    {
        displayOption: undefined,
        nullable: false,
        loading: false,
        disabled: false,
        showOptional: false,
        placeholder: undefined,
        formErrors: undefined,
        label: undefined,
        modelValue: null,
    }
)

const query = ref("")

const selectedInput = computed<any>({
    get() {
        return props.modelValue ?? null
    },
    set(value: ListItem | null) {
        emit("update:model-value", value)
    },
})

const filteredList = computed(() => {
    return query.value === ""
        ? props.list
        : props.list.filter((item) => {
              return item[props.by]
                  .toLowerCase()
                  .includes(query.value.toLowerCase())
          })
})

const hasErrors = computed(
    () => props.formErrors && props.formErrors.has(props.name)
)

defineExpose({ query, selectedInput })
</script>
<style scoped>
.form-errors {
    @apply rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500;
}
</style>
