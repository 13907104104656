import useAxios from "@/composables/useAxios"
import type Invitation from "@/models/Invitation"
import type Organization from "@/models/Organization"
import { useMutation, useQueryClient } from "@tanstack/vue-query"
import { orgInvitationBaseKey } from "./useOrgInvitationsQuery"

export function useOrgInvitationResend() {
    const queryClient = useQueryClient()

    return useMutation(
        ({
            orgId,
            inviteId,
        }: {
            orgId: Organization["id"]
            inviteId: Invitation["id"]
        }) =>
            useAxios().axios.post(
                `/api/organizations/${orgId}/invites/${inviteId}/resend`
            ),
        {
            onSettled() {
                // Need this to keep the sent timestamp updated
                queryClient.invalidateQueries([orgInvitationBaseKey])
            },
        }
    )
}
