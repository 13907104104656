import type { ApiSubscriptionPlanPriceTierData } from "types/api"

export class SubscriptionPlanPriceTier {
    flat_amount: null
    flat_amount_decimal: null
    /** In cents */
    unit_amount: number | string
    unit_amount_decimal: string
    up_to: number | null

    constructor(data: ApiSubscriptionPlanPriceTierData) {
        this.flat_amount = data.flat_amount
        this.flat_amount_decimal = data.flat_amount_decimal
        this.unit_amount = data.unit_amount
        this.unit_amount_decimal = data.unit_amount_decimal
        this.up_to = data.up_to
    }

    get unitCostInDollars() {
        if (typeof this.unit_amount === "string") return this.unit_amount
        return (this.unit_amount / 100).toFixed(2)
    }

    get unitCostInDollarsDiv12() {
        if (typeof this.unit_amount === "string") return this.unit_amount
        return (this.unit_amount / 100 / 12).toFixed(2)
    }

    get upToUsers(): number {
        if (this.up_to === null) return Infinity
        return this.up_to
    }

    /**
     * Returns the tier that the given number of users falls into.
     */
    totalCostInDollars(users: number): string {
        // If the price is a string, it means it is a custom price and we should just return it.
        if (typeof this.unit_amount === "string") return this.unit_amount

        return ((this.unit_amount * users) / 100).toFixed(2)
    }
}
