import type { Ref } from "vue"
import Invitation from "@/models/Invitation"
import type { ApiInvitationData, ApiPaginatedGETResponse } from "types/api"
import type Organization from "@/models/Organization"
import useAxios from "@/composables/useAxios"
import { useInfiniteQuery } from "@tanstack/vue-query"

// TODO: Get rid of this
export const orgInvitationBaseKey = "org-invitation"

// export function useOrgInvitationsQuery(orgId: Ref<Organization["id"] | null>) {
//     const queryBuilder = new QueryBuilder(
//         computed(() => ["org-invitation", `organization:${orgId.value}`]),
//         computed(() => `/api/organizations/${orgId.value}/invites`)
//     )

//     return new Query<ApiInvitationData, Invitation>(queryBuilder).getAllModels({
//         dependsOn: [orgId],
//         select: (data) => new Invitation(data),
//     })
// }

export function useOrgInvitationsInfiniteQuery(
    orgId: Ref<Organization["id"] | null>,
    config?: { enabled: Ref<boolean> }
) {
    const { axios } = useAxios()

    const total = ref<number | undefined>(undefined)

    const query = useInfiniteQuery(
        ["org-invitation", computed(() => `organization:${orgId.value}`)],
        ({ pageParam = 1 }) => {
            return axios
                .get<ApiPaginatedGETResponse<ApiInvitationData>>(
                    `/api/organizations/${orgId.value}/invites`,
                    {
                        params: {
                            "page[number]": pageParam,
                            "page[size]": 100,
                        },
                    }
                )
                .then((resp) => {
                    total.value = resp.data.total
                    return resp.data
                })
        },
        {
            enabled: computed(() => config?.enabled.value ?? true),

            select({ pageParams, pages }) {
                total.value = pages[0]?.total
                const transformedPages = pages.map((page) => {
                    return page.data.map(
                        (invitationData) => new Invitation(invitationData)
                    )
                })
                return { pages: transformedPages, pageParams }
            },

            getNextPageParam: (lastPage, _pages) =>
                lastPage.next_page_url ? lastPage.current_page + 1 : undefined,

            // staleTime: configFile.staleTime.long,
        }
    )

    const invites = computed(() => query.data.value?.pages.flat())

    return { ...query, invites, total }
}

export function useOrgInvitationsAllQuery(
    orgId: Ref<Organization["id"] | null>,
    config?: { enabled: Ref<boolean> }
) {
    const isLoadingLocal = ref(true)
    const results = ref<Invitation[]>([])

    const { invites, hasNextPage, fetchNextPage, isRefetching, total } =
        useOrgInvitationsInfiniteQuery(orgId, config)

    watch(
        [hasNextPage, computed(() => invites.value?.length), isRefetching],
        () => {
            if (hasNextPage?.value && !isRefetching?.value) {
                fetchNextPage()
            }
            if (!hasNextPage?.value && !isRefetching?.value) {
                isLoadingLocal.value = false
                results.value = invites.value ?? []
            }
        }
    )

    return {
        data: results,
        isLoading: isLoadingLocal,
        isRefetching,
        total,
    }
}
