import useAxios from "@/composables/useAxios"
import type { SubscriptionPlan } from "@/models/SubscriptionPlan"
import { useMutation, useQueryClient } from "@tanstack/vue-query"
import { subscriptionPlanBaseKey } from "./useSubscriptionPlansQuery"

export function useSubscriptionPlansReorder() {
    const queryClient = useQueryClient()
    return useMutation(
        (order: SubscriptionPlan["id"][]) =>
            useAxios().axios.put(`/api/billing/subscription-plans/order`, {
                order,
            }),
        {
            // onMutate(order) {
            //     queryClient.setQueryData<SubscriptionPlan[]>(
            //         [subscriptionPlanBaseKey],
            //         (previous) => {
            //             if (!previous) throw new Error("No previous data")
            //             const newOrder = order.map((id, i) => {
            //                 const item = previous.find((plan) => plan.id === id)
            //                 if (!item) throw new Error("Invalid order")
            //                 item.order = i + 1
            //                 return item
            //             })
            //             return newOrder
            //         }
            //     )
            // },
            onSuccess() {
                queryClient.invalidateQueries([subscriptionPlanBaseKey])
            },
        }
    )
}
