import type {
    ApiGlobalId,
    ApiIntegrationData,
    IntegrationProvider,
} from "types/api"
import type User from "./User"

export default class Integration {
    id: ApiGlobalId
    user_id: User["id"]
    created_at: string
    updated_at: string
    user_name: string | null
    user_email: string | null
    user_avatar: null | string
    provider: IntegrationProvider

    constructor(data: ApiIntegrationData) {
        this.id = data.id
        this.user_id = data.user_id
        this.provider = data.provider
        this.user_name = data.user_name
        this.user_email = data.user_email
        this.user_avatar = data.user_avatar
        this.created_at = data.created_at
        this.updated_at = data.updated_at
    }
}
