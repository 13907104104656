<template>
    <div class="relative inline-block min-w-max">
        <img
            :src="src"
            class="max-w-full border-none object-cover align-middle"
            :class="[
                size === 'sm' && 'h-6 w-6',
                size === 'md' && 'h-8 w-8',
                size === 'lg' && 'h-10 w-10',
                size === '2xl' && 'h-14 w-auto',
                !shadowless && 'shadow',
                round && 'rounded-full',
            ]"
        />
        <span
            v-if="showStatus && color"
            class="absolute top-0 right-0 block h-2 w-2 rounded-full ring-2 ring-white"
            :class="status"
        />
    </div>
</template>
<script lang="ts" setup>
import type { PropType } from "vue"
import type { AvatarStatusColor } from "types/types"

defineComponent({
    name: "GFAvatar",
})

const props = defineProps({
    src: {
        type: String,
        required: true,
    },

    showStatus: {
        type: Boolean,
        default: false,
    },

    color: {
        type: String as PropType<AvatarStatusColor>,
        default: "gray",
    },

    size: {
        type: String as PropType<"sm" | "md" | "lg" | "2xl">,
        default: "md",
    },
    shadowless: {
        type: Boolean,
        default: false,
    },
    round: {
        type: Boolean,
        default: true,
    },
})

const status = computed(() => {
    if (props.color === "green") return `bg-green-400`
    if (props.color === "red") return `bg-red-400`
    if (props.color === "gray") return `bg-gray-400`
    if (props.color === "yellow") return `bg-yellow-400`
})
</script>
