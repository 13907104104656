import { NotificationType } from "@/enums/NotificationType"
import { useOrganizationStore } from "@/stores/organization"
import type {
    ApiGlobalId,
    ApiNotifiableActionResourceType,
    ApiNotifiableType,
    ApiNotificationData,
    ApiNotificationType,
} from "types/api"
import type { IconColor } from "types/types"
import type { RouteLocationRaw } from "vue-router"

interface NotificationAction {
    goto: RouteLocationRaw
}

export class Notification {
    id: ApiGlobalId
    type: ApiNotificationType
    notifiable_type: ApiNotifiableType
    notifiable_id: ApiGlobalId
    data: {
        title: string
        action: {
            resource_type: ApiNotifiableActionResourceType
            resource_id: ApiGlobalId
            organization_id: ApiGlobalId
        }
        type: NotificationType
    }
    read_at: null | string
    created_at: string
    updated_at: string
    is_read: boolean
    human_readable_updated_at: string
    human_readable_created_at: string

    constructor(data: ApiNotificationData) {
        this.id = data.id
        this.type = data.type
        this.notifiable_type = data.notifiable_type
        this.notifiable_id = data.notifiable_id
        this.data = data.data
        this.read_at = data.read_at
        this.created_at = data.created_at
        this.updated_at = data.updated_at
        this.is_read = data.is_read
        this.human_readable_updated_at = data.human_readable_updated_at
        this.human_readable_created_at = data.human_readable_created_at
    }

    get isRead() {
        return this.is_read
    }

    get iconStyle(): null | { icon: string; color: IconColor } {
        switch (this.data.type) {
            case NotificationType.SUCCESS:
                return { icon: "check", color: "green" }
            case NotificationType.DANGER:
                return { icon: "xmark", color: "red" }
            case NotificationType.DEFAULT:
                return { icon: "pen", color: "blue" }
            case NotificationType.INFO:
                return { icon: "exclamation", color: "yellow" }
            case NotificationType.WARNING:
                return { icon: "triangle-exclamation", color: "orange" }
        }
    }

    /**
     * Returns an action for each notification type.
     */
    async action(): Promise<NotificationAction | null> {
        const organizationStore = useOrganizationStore()

        const { organizations } = storeToRefs(organizationStore)

        if (!this.data.action) return null

        const orgId = this.data.action.organization_id

        if (orgId) {
            const org = organizations.value.find(
                (org) => org.id === this.data.action.organization_id
            )
            if (org) organizationStore.changeOrganization(org)
            else organizationStore.changeOrganization(null)
        } else organizationStore.changeOrganization(null)

        await nextTick()

        let goto: RouteLocationRaw

        switch (this.type) {
            case "App\\Notifications\\PdfCompletedNotification":
                goto = {
                    name: "signing",
                    query: {
                        active: this.data.action.resource_id,
                        tab: "completed",
                        t: Date.now(),
                    },
                }

                break

            case "App\\Notifications\\PdfDeclinedNotification":
                goto = {
                    name: "signing",
                    query: {
                        active: this.data.action.resource_id,
                        tab: "declined",
                        t: Date.now(),
                    },
                }
                break

            case "App\\Notifications\\ReviewAndSignNotification":
                goto = {
                    name: "signing",
                    query: {
                        active: this.data.action.resource_id,
                        tab: "pending",
                        t: Date.now(),
                    },
                }
                break

            case "App\\Notifications\\OrganizationInviteNotification":
                goto = {
                    path: "/home",
                    query: { confirm_invite_id: this.data.action.resource_id },
                }
                break

            case "App\\Notifications\\OrganizationInviteRejectedNotification":
                goto = {
                    path: "/settings/organizations",
                    hash: "#invite-users",
                }
                break

            case "App\\Notifications\\OrganizationInviteAcceptedNotification":
                goto = {
                    path: "/settings/organizations",
                    hash: "#organization-users",
                }
                break

            default:
                return null
        }

        return { goto }
    }
}
