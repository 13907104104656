import type { TagColor } from "types/types"
import type { AlertType, ApiAlertData, ApiGlobalId } from "types/api"

export class Alert {
    id: ApiGlobalId
    type: AlertType
    message: string

    constructor(data: ApiAlertData) {
        this.id = data.id
        this.type = data.type
        this.message = data.message
    }

    get color(): TagColor {
        switch (this.type) {
            case "success":
                return "green"
            case "danger":
                return "red"
            case "warning":
                return "yellow"
            case "info":
                return "blue"
            default:
                return "gray"
        }
    }
}
