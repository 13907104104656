import type {
    ApiSubscriptionPlanPriceData,
    SubscriptionPlanPriceInterval,
} from "types/api"
import { SubscriptionPlanPriceTier } from "./SubscriptionPlanPriceTier"

export class SubscriptionPlanPrice {
    id: string
    interval: SubscriptionPlanPriceInterval
    price_cents: number | null
    price_dollars: string
    pricing_model: string | null
    is_metered: boolean
    tiers: SubscriptionPlanPriceTier[]
    metadata: []

    constructor(data: ApiSubscriptionPlanPriceData) {
        this.id = data.id
        this.interval = data.interval
        this.price_cents = data.price_cents
        this.price_dollars = data.price_dollars
        this.pricing_model = data.pricing_model
        this.is_metered = data.is_metered
        this.tiers = data.tiers.map(
            (tier) => new SubscriptionPlanPriceTier(tier)
        )
        this.metadata = data.metadata
    }

    tierForUsers(users: number): SubscriptionPlanPriceTier | undefined {
        const tier = this.tiers.find((tier) => tier.upToUsers >= users)
        return tier
    }
}
