<template>
    <VPerfectSignature
        v-if="ready"
        ref="signaturePad"
        :key="ready.toString()"
        data-testid="signature-draw-pad"
        v-bind="{ backgroundColor, penColor, strokeOptions }"
        @on-begin="$emit('begin')"
        @on-end="$emit('end')"
    />
</template>
<script setup lang="ts">
import { ref, type PropType } from "vue"
import type { StrokeOptions } from "perfect-freehand"

const VPerfectSignature = defineAsyncComponent(
    () => import("v-perfect-signature")
)

defineComponent({
    name: "UserSignatureDraw",
})

defineEmits(["begin", "end"])

defineProps({
    /**
     * Canvas background color
     */
    backgroundColor: {
        type: String,
        default: "rgba(0,0,0,0)",
    },

    /**
     * Canvas pen color
     */
    penColor: {
        type: String,
        default: "#000",
    },

    strokeOptions: {
        type: Object as PropType<StrokeOptions>,
        default: () => ({
            size: 16,
            thinning: 0.75,
            smoothing: 0.5,
            streamline: 0.5,
        }),
    },
})

const signaturePad = ref()
const ready = ref(false)

// For some reason, this helps the component hit 100% width and height
setTimeout(() => {
    ready.value = true
}, 300)

/**
 * Returns a base 24 encoded URL
 */
function toDataBase46(): string {
    return signaturePad.value?.toDataURL()
}

/**
 * Clears the canvas
 */
function clear() {
    return signaturePad.value?.clear()
}

/**
 * Returns true if canvas is empty
 */
function isEmpty() {
    return signaturePad.value?.isEmpty()
}

defineExpose({ toDataBase46, clear, isEmpty })
</script>
