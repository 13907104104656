import type { Ref } from "vue"

type BaseKey =
    | "notifications"
    | "signees"
    | "payment-methods"
    | "invoices"
    | "upcoming-invoice"
    | "access-code"
    | "model-subscriptions"
    | "user-payment-method-intent"
    | "user-signatures"
    | "user-initials"
    | "stubs"
    | "assigned-stubs"
    | "assigned-stubs-expected-payload"
    | "page"
    | "pdf-editor"
    | "signee-fields"

export class QueryKey<T extends Record<string, Ref>> {
    constructor(public base: BaseKey, public rest: T) {
        this.base = base
    }

    get key(): [BaseKey, T] {
        return [this.base, this.rest]
    }

    static base(key: BaseKey): BaseKey {
        return key
    }

    static baseKey(key: BaseKey): [BaseKey] {
        return [key]
    }
}
