import type {
    CreatePagePayload,
    ApiCreateLinePayload,
    ApiCreateTextPayload,
    ApiCreateImagePayload,
    ApiCreateInputPayload,
    ApiCreateRadioPayload,
    ApiCreateSelectPayload,
    ApiCreateCheckboxPayload,
    ApiCreateRectanglePayload,
    ApiCreateSignaturePayload,
    ApiCreateInitialPayload,
} from "types/api"

const config = {
    versionRefreshInterval: 10000,

    supportEmail: "support@galaxyforms.com",

    /********************************
     * API
     *******************************/

    /**
     * All the names of different models, as defined by API.
     */
    modelNames: [
        "text",
        "images",
        "signatures",
        "initials",
        "inputs",
        "rectangles",
        "lines",
        "checkboxes",
        "selects",
        "radios",
    ],

    radioGroupModelName: "radio_groups",

    fieldTypes: [
        "signature",
        "initial",
        "input",
        "select",
        "checkbox",
        "radio-group", // api supports this format
    ],

    /**
     * These models are not rendered on pages in edit mode,
     * they are left to the splash screen to render.
     */
    modelsNotRenderedInEditMode: ["rectangles", "lines"],

    /**
     * Organization user roles.
     */
    organizationUserRoles: ["admin", "standard", "signee"],

    /**
     * Organization user roles.
     */
    invitationUserRoles: ["admin", "standard"],

    /**
     * Engine modes as defined by API.
     */
    pdfEngineMode: [
        "processing",
        "copying",
        "ready",
        "error",
        "error_encrypted",
        "error_max_pages",
        "error_max_size",
    ],

    pdfMode: ["edit", "signing", "template"],

    pdfOrigin: ["upload", "url", "blueprint", "snapshot"],

    pdfSigningMode: ["draft", "pending", "declined", "completed"],

    folderCategories: ["documents", "templates"],

    pageModes: ["edit", "full_edit", "append"],

    integrations: ["companycam"],

    socialLoginProviders: ["google", "facebook", "linkedin"],

    invitationStatuses: ["pending", "rejected", "not sent"],

    minimumModelSizes: {
        text: 5,
        images: 5,
        rectangles: 2,
        lines: 5,
        signatures: 15,
        initials: 15,
        inputs: 12,
        checkboxes: 7,
        selects: 12,
        radios: 7,
    },

    inputTypes: [
        "tel",
        "url",
        "time",
        "text",
        "date",
        "email",
        "number",
        "datetime-local",
    ],

    webhookEvents: [
        "webhooks.test",
        "pdfs.used",
        "pdfs.sent",
        "pdfs.signed",
        "pdfs.copied",
        "pdfs.created",
        "pdfs.updated",
        "pdfs.deleted",
        "pdfs.declined",
        "pdfs.completed",
    ],

    metricKeys: [
        "folder_usage",
        "storage_usage",
        "active_templates",
        "monthly_quick_signs",
    ],

    /********************************
     * Editor
     *******************************/

    unit: "px", // warning: changing this will break the editor, only px is supported currently

    /**
     * The different types of views (zooms) that the editor supports.
     */
    editorScaleViews: ["full", "scale"],

    /** Default scale view */
    defaultEditorScaleView: "scale",
    /** Default scale value */
    defaultEditorScaleValue: 1.6,
    /** Default value for showing whether guidelines are showing */
    defaultEditorIsShowingGuidelines: false,

    visualAppearanceSortEnabled: true,

    textAlignment: ["center", "right", "left", "justify", "fit"],

    /**
     * Prevents node selection, which includes node drag updating.
     * The `translate` effect is still active, but the movement is
     * not saved to the API.
     */
    enableSelection: true,
    /**
     * Prevents the `translate` effect as well as snap to grid movement
     */
    enableMovement: true,
    enableResizeControls: true,
    enableActiveNodeBorder: true,
    enableHoverNodeBorder: true,
    enableFloatingMenu: true,
    enableGridOverlay: true,
    enableGuidelines: true,

    /** The z-index for the floating menu */
    floatingMenuZIndex: 999999,

    /** The z-index for the active node. */
    activeNodeLayer: 999999,

    /** The highest layer value allowed */
    layerMax: 99999,

    /** The lowest layer value allowed */
    layerMin: -99999,

    /**
     * A base number is used to represent 0 for the node z-index.
     * This is to avoid negative numbers when calculating the z-index for a node.
     * The z-index is calculated as `zIndexBase + layer`.
     */
    zIndexBase: 100000,

    /**
     * When copying a model, offset the copied model by this amount.
     */
    copyOffset: 20,

    globalAdjustments: {
        /** Global adjustments for the x/y coordinates, for text only */
        text: {
            x: 0,
            y: -0.5,
        },
    },

    textSpecs: {
        size: {
            /** Minimum allowed size for text */
            min: 3,
            /** Maximum allowed size for text */
            max: 999,
        },
    },

    pageModeDescriptions: {
        append: {
            title: "Append mode",
            description:
                "Append mode allows you to add new content to the page and fill out form fields, but does not allow for editing existing content. Original page contents will be preserved as accurately as possible.",
        },
        edit: {
            title: "Edit mode",
            description:
                "Edit mode allows you to modify existing text, images, and form fields. Original page contents will be preserved as accurately as possible, but may become malformed.",
        },
        fullEdit: {
            title: "Full edit mode",
            description:
                "Full edit mode allows to modify everything on the page, including lines, shapes, and dynamic graphics. Original page contents will be preserved as accurately as possible, but have a higher chance to becoming malformed.",
        },
    },

    /**
     * ['alias', 'field_name']
     */
    editModelStyleFormFields: [
        ["color", "color"],
        ["layer", "layer"],
        ["textSize", "size"],
        ["inputType", "type"],
        ["fieldName", "name"],
        ["textValue", "value"],
        ["checkValue", "value"],
        ["radioValue", "value"],
        ["selectValue", "value"],
        ["fontId", "pdf_font_id"],
        ["selectOptions", "options"],
        ["textAlignment", "alignment"],
        ["gridLocked", "is_grid_locked"],
        ["radioGroup", "pdf_radio_group_id"],
        ["inputIsMultiline", "is_multiline"],
    ],

    persistentRightPanel: false,
    centerAlignPages: true,
    hideGroupHighlighter: false,
    hideRadioGroupHighlighter: false,

    gridSizeMin: 2,
    gridSizeMax: 10,
    gridSizeStep: 2,

    /**
     * Used to join the model key parts.
     */
    modelKeyJoiner: "|",

    /**
     * Used to join the page key parts.
     */
    pageKeyJoiner: "|",

    // textNodeComponent: import("@/components/editor/editor-html/TextNode.vue"),
    // imageNode: import("@/components/editor/editor-html/ImageNode.vue"),
    // lineNode: import("@/components/editor/editor-html/LineNode.vue"),
    // signatureNode: import("@/components/editor/editor-html/SignatureNode.vue"),
    // initialNode: import("@/components/editor/editor-html/InitialNode.vue"),
    // rectangleNode: import("@/components/editor/editor-html/RectangleNode.vue"),
    // inputNode: import("@/components/editor/editor-html/InputNode.vue"),
    // checkboxNode: import("@/components/editor/editor-html/CheckboxNode.vue"),
    // radioNode: import("@/components/editor/editor-html/RadioNode.vue"),
    // selectNode: import("@/components/editor/editor-html/SelectNode.vue"),
    // modelObjectNode: import("@/components/editor/ModelObject.vue"),

    /********************************
     * Timeline
     *******************************/

    userTimelineEvents: ["signed in", "signed out"],

    pdfTimelineEvents: [
        "used",
        "sent",
        "copied",
        "uploaded",
        "finished signing",
        "document completed",
        "created a template from",
        "archived",
    ],

    genericTimelineEvents: [
        "viewed",
        "created",
        "updated",
        "deleted",
        "restored",
    ],

    /********************************
     * User
     *******************************/

    userStatus: ["online", "offline", "away"],

    /********************************
     * Styles
     *******************************/

    /**
     * Different colors for the GFTag component
     */
    tagColors: [
        "primary",
        "secondary",
        "red",
        "blue",
        "green",
        "yellow",
        "gray",
        "white",
    ],

    /********************************
     * Gallery
     *******************************/

    galleryTypes: ["pdf", "folder"],

    galleryDefaultSort: "-updated_at",

    gallerySortOptions: [
        "name",
        "-name",

        // Removed because this sort-text is being used for both folders and pdfs, and folders don't have a size.
        // "size",
        // "-size",

        "updated_at",
        "-updated_at",

        "created_at",
        "-created_at",

        "deleted_at",
        "-deleted_at",

        "archived_at",
        "-archived_at",
    ],

    gallerySortDirectionOptions: ["asc", "desc"],

    pdfEnergizingErrorTitle: "Whoops! There was an error.",
    pdfEnergizingErrorMessage:
        "We couldn't process your PDF, but we have a copy of it and will let you know once it's resolved.",

    pdfEnergizingErrorMaxPagesTitle: "Too many pages.",
    pdfEnergizingErrorMaxPagesMessage:
        "We couldn't process your PDF because it has more than 75 pages.",

    pdfEnergizingErrorMaxSizeTitle: "File too large.",
    pdfEnergizingErrorMaxSizeMessage:
        "We couldn't process your PDF because it is larger than 50 MB.",

    pdfEnergizingErrorEncryptedTitle: "PDF is encrypted.",
    pdfEnergizingErrorEncryptedMessage:
        "We couldn't process your PDF because it is password protected.",

    /********************************
     * Billing and Subscriptions
     *******************************/

    /**
     * If a plan does not have a specific usage feature,
     * it is assumed to be either enabled or disabled by default.
     * If this is set to true, the feature will be enabled by default.
     * If this is set to false, the feature will be disabled by default.
     */
    usageFeatureIsEnabledDefault: true,

    /**
     * For all billable models, this is the default value for the is_billable field.
     * This should probably be false, if it's true the subscription query will fire before checking if the model is billable,
     * but it's configurable just in case.
     */
    defaultIsBillable: false,

    invoiceStatus: ["open", "paid", "uncollectible", "void", "draft"],

    subscriptionPlanIntervals: ["monthly", "yearly"],

    /********************************
     * Notifications
     *******************************/

    notificationTypes: [
        "App\\Notifications\\PdfCompletedNotification",
        "App\\Notifications\\PdfDeclinedNotification",
        "App\\Notifications\\ReviewAndSignNotification",
        "App\\Notifications\\SystemNotification",
        "App\\Notifications\\OrganizationInviteNotification",
        "App\\Notifications\\OrganizationInviteRejectedNotification",
        "App\\Notifications\\OrganizationInviteAcceptedNotification",
    ],

    notifiableType: ["App\\Models\\User", "App\\Models\\OrganizationInvite"],

    notificationActionResourceType: ["App\\Models\\Pdf\\Pdf"],

    /********************************
     * Cache
     *******************************/

    /**
     * The amount of time to cache a query result.
     */
    staleTime: {
        /** 5 seconds */
        short: 5000,
        /** 10 seconds */
        medium: 10000,
        /** 30 seconds */
        long: 30000,
    },

    /********************************
     * Stubs
     * ******************************/

    isStubDroppable: [
        "inputs",
        "checkboxes",
        "selects",
        "images",
        "radio_groups",
        "radios",
    ],

    /********************************
     * Error Handling
     * ******************************/

    /**
     * These paths and status codes are ignored by the error handling system.
     * Most likely, they will be handled in a different way by a specific component.
     */
    errorIgnorePaths: [
        {
            path: "/signee-login",
            status: 403,
        },
    ],

    /********************************
     * Welcome Wizard
     * ******************************/

    disableMyStuffWelcomeModal: true,
} as const

/********************************
 * Editor Defaults
 *******************************/

const page: CreatePagePayload = {
    height: 841,
    width: 595,
    order: 1,
}

const text: Omit<ApiCreateTextPayload, "pdf_font_id"> = {
    color: "#000000",
    width: 200,
    alignment: "left",
    height: 15,
    size: 12,
    value: "Write your text",
    x_position: 100,
    y_position: 100,
}

const images: ApiCreateImagePayload = {
    width: 69.42,
    height: 69.42,
    x_position: 50,
    y_position: 120.001,
}

const rectangles: ApiCreateRectanglePayload = {
    color: "#000000",
    height: 50,
    width: 100,
    x_position: 100,
    y_position: 100,
}

/** An extra value to simplify line creation. Use this value when creating a line without a box to define width and height. */
const defaultLineLength = 100 as const
const lines: ApiCreateLinePayload = {
    color: "#000000",
    width: 1.5,
    x1_position: 100,
    y1_position: 100,
    x2_position: 100 + defaultLineLength,
    y2_position: 100,
}

const signatures: ApiCreateSignaturePayload = {
    height: 30,
    width: 120,
    x_position: 100,
    y_position: 100,
}

const initials: ApiCreateInitialPayload = {
    height: 30,
    width: 120,
    x_position: 100,
    y_position: 100,
}

const inputs: ApiCreateInputPayload = {
    value: null,
    height: 30,
    width: 120,
    x_position: 100,
    y_position: 100,
    type: "text",
}

const selects: ApiCreateSelectPayload = {
    value: null,
    options: ["option_1", "option_2"],
    height: 30,
    width: 120,
    x_position: 100,
    y_position: 100,
}

const checkboxes: ApiCreateCheckboxPayload = {
    value: false,
    height: 20,
    width: 20,
    x_position: 100,
    y_position: 100,
}

const radios: Omit<ApiCreateRadioPayload, "pdf_radio_group_id" | "group"> = {
    value: false,
    height: 20,
    width: 20,
    x_position: 100,
    y_position: 100,
}

const editorDefaults = {
    text,
    page,
    lines,
    radios,
    inputs,
    images,
    selects,
    checkboxes,
    rectangles,
    signatures,
    initials,
    defaultLineLength,
}
export default { ...config, ...{ editorDefaults } }
