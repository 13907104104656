<template>
    <GFModalSimple
        v-model:show="isActive"
        @closed="$emit('update:orgUser', null)"
    >
        <div class="">
            Are you sure you want to remove
            <span class="font-bold"
                >{{ orgUser?.user?.first_name }}
                {{ orgUser?.user?.last_name }}</span
            >
            from {{ org.name }}?
        </div>
        <template #buttons>
            <div class="flex">
                <GFButtonSimple @click="isActive = false">
                    Cancel
                </GFButtonSimple>
                <GFButton
                    class="ml-3"
                    :loading="isLoading"
                    @click="
                        () => {
                            orgUser && onDeleteUser(org.id, orgUser.user_id)
                        }
                    "
                >
                    Confirm
                </GFButton>
            </div>
        </template>
    </GFModalSimple>
</template>
<script setup lang="ts">
import type { PropType } from "vue"
import GFButton from "../base/GFButton.vue"
import type Organization from "@/models/Organization"
import GFButtonSimple from "../base/GFButtonSimple.vue"
import type OrganizationUser from "@/models/OrganizationUser"
import GFModalSimple from "@/components/base/GFModalSimple.vue"
import { useOrganizationUsersDelete } from "@/queries/organizations/useOrganizationUsersDelete"
import type User from "@/models/User"

const emit = defineEmits<{
    (e: "update:show", show: boolean): void
    (e: "update:orgUser", id: number | null): void
}>()

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    orgUser: {
        type: Object as PropType<null | OrganizationUser>,
        default: null,
    },
    org: {
        type: Object as PropType<Organization>,
        required: true,
    },
})

const isActive = computed({
    get() {
        return props.show
    },
    set(value: boolean) {
        emit("update:show", value)
    },
})

const { mutate: deleteUser, isLoading } = useOrganizationUsersDelete()

function onDeleteUser(organizationId: Organization["id"], userId: User["id"]) {
    deleteUser(
        {
            organizationId,
            userId,
        },
        {
            onSuccess() {
                isActive.value = false
            },
        }
    )
}
</script>
