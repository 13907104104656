import type {
    ApiGlobalId,
    ApiOrganizationUserData,
    OrganizationUserRole,
} from "types/api"
import type Organization from "./Organization"
import User from "./User"

export default class OrganizationUser {
    id: ApiGlobalId
    organization_id: Organization["id"]
    user_id: User["id"]
    role: OrganizationUserRole
    created_at: string
    updated_at: string
    user?: User
    user_avatar_base64?: string

    constructor(data: ApiOrganizationUserData) {
        this.id = data.id
        this.organization_id = data.organization_id
        this.user_id = data.user_id
        this.role = data.role
        this.created_at = data.created_at
        this.updated_at = data.updated_at
        this.user_avatar_base64 = data.user_avatar_base64
        this.user = data.user && new User(data.user)
    }

    get fullName() {
        return this.user?.full_name
    }
}
