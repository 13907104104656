<template>
    <VTooltip data-testid="toolbar-delete-tooltip" class="flex">
        <div class="group flex items-center p-0.5">
            <GFIcon
                icon="question-circle"
                :class="[colorClass]"
                :rem-size="sizeMap.get(size)"
            />
        </div>
        <template #popper>
            <div class="max-w-md">
                <slot>Help text here...</slot>
            </div>
        </template>
    </VTooltip>
</template>
<script setup lang="ts">
import GFIcon from "./GFIcon.vue"
import type { PropType } from "vue"

defineProps({
    size: {
        type: String as PropType<"small" | "medium" | "large">,
        default: "medium",
    },
    colorClass: {
        type: String as PropType<string>,
        default: "text-blue-400 group-hover:text-blue-600",
    },
})

const sizeMap = new Map()
sizeMap.set("small", ".8")
// sizeMap.set("medium", "1.2")
// sizeMap.set("large", "1.4")
</script>
