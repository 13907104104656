import type User from "@/models/User"
import type UserSignature from "@/models/UserSignature"
import type {
    ApiUpdateUserInitial,
    ApiError,
    ApiUserInitialData,
} from "types/api"
import type { AxiosError } from "axios"
import useAxios from "@/composables/useAxios"
import UserInitial from "@/models/UserInitial"
import {
    useMutation,
    useQueryClient,
    type UseMutationOptions,
} from "@tanstack/vue-query"
import { QueryKey } from "../QueryKey"

export function useUserInitialUpdate(
    config?: UseMutationOptions<
        UserInitial,
        AxiosError<ApiError>,
        {
            payload: ApiUpdateUserInitial
            userId: User["id"] | "me"
            userSigId: UserSignature["id"]
        },
        unknown
    >
) {
    const queryClient = useQueryClient()
    const { axios } = useAxios()

    return useMutation(
        ({ payload, userId, userSigId }) =>
            axios
                .put<ApiUserInitialData>(
                    `/api/users/${userId}/initials/${userSigId}`,
                    payload
                )
                .then((resp) => new UserInitial(resp.data)),
        {
            onSettled() {
                queryClient.invalidateQueries(QueryKey.baseKey("user-initials"))
            },
            ...config,
        }
    )
}
