<template>
    <TransitionRoot
        as="template"
        :show="show"
        @before-enter="onOpen"
        @after-leave="$emit('closed')"
    >
        <Dialog
            as="div"
            class="fixed inset-0 z-[9999] max-h-screen overflow-y-auto"
            :initial-focus="modalRef"
            @close="onClose"
        >
            <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div
                    class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                />
            </TransitionChild>

            <div class="fixed inset-0 z-10 overflow-y-auto md:mx-2">
                <div
                    class="flex min-h-full items-center justify-center p-4 text-center sm:p-0"
                >
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <DialogPanel
                            class="relative w-full transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8"
                            :class="[
                                size === 'normal' && 'sm:max-w-sm',
                                size === 'large' && 'sm:max-w-xl',
                                size === 'x-large' && 'sm:max-w-4xl',
                                size === '2x-large' && 'sm:max-w-6xl',
                                size === 'full' && 'max-w-screen-2xl md:mx-4',
                                size === 'fit' && 'w-fit',
                            ]"
                        >
                            <div
                                class="rounded-t-lg bg-white px-4 pt-5 pb-4 sm:p-6"
                                :class="{
                                    'sm:pb-4': !hideButtons,
                                    'rounded-b-lg': hideButtons,
                                }"
                            >
                                <div
                                    ref="modalRef"
                                    class="flex items-center justify-between"
                                >
                                    <slot name="title">
                                        <div
                                            v-if="title"
                                            class="mb-3 md:flex-auto"
                                        >
                                            <DialogTitle
                                                v-if="title"
                                                as="h3"
                                                class="text-lg font-medium leading-6 text-gray-900"
                                            >
                                                {{ title }}
                                            </DialogTitle>
                                        </div>
                                    </slot>

                                    <div
                                        v-if="!hideActions"
                                        class="mt-4 md:mt-0 md:ml-16 md:flex-none"
                                    >
                                        <slot name="actions"></slot>
                                    </div>
                                </div>
                                <div class="sm:items-start md:flex">
                                    <slot></slot>
                                </div>
                            </div>
                            <div
                                v-if="!hideButtons"
                                class="flex flex-row-reverse rounded-b-lg bg-gray-50 px-4 py-3 sm:px-6"
                            >
                                <slot
                                    name="buttons"
                                    :close="() => $emit('update:show', false)"
                                />
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup lang="ts">
import {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionRoot,
    TransitionChild,
} from "@headlessui/vue"
import type { PropType } from "vue"

const emit = defineEmits<{
    (e: "update:show", show: boolean): void
    (e: "closed"): void
}>()

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    hideButtons: {
        type: Boolean,
        default: false,
    },
    /** Prevents closing the modal except via 'show' prop; disables click-outside functionality */
    locked: {
        type: Boolean,
        default: false,
    },
    title: {
        type: String,
        default: null,
    },
    size: {
        type: String as PropType<
            "normal" | "fit" | "full" | "large" | "x-large" | "2x-large"
        >,
        default: "normal",
    },
    hideActions: {
        type: Boolean,
        default: false,
    },
})

const modalRef = ref<HTMLDivElement | null>(null)

function onClose() {
    preventScrollOnBody(false)
    !props.locked && emit("update:show", false)
}

function onOpen() {
    preventScrollOnBody(true)
}

function preventScrollOnBody(prevent: boolean) {
    const body = document.querySelector("body")
    if (body) {
        body.style.overflow = prevent ? "hidden" : "auto"
    }
}
</script>
