import type { ApiStubData, ApiGlobalId } from "types/api"

export default class Stub {
    id: ApiGlobalId
    organization_id: null | ApiGlobalId
    key: string
    name: string | null
    description: string | null
    service_name: string | null
    created_at: string
    updated_at: string

    constructor(data: ApiStubData) {
        this.id = data.id
        this.organization_id = data.organization_id
        this.key = data.key
        this.name = data.name
        this.description = data.description
        this.service_name = data.service_name
        this.created_at = data.created_at
        this.updated_at = data.updated_at
    }

    get isOrganization() {
        return this.organization_id !== null
    }

    get isUser() {
        return this.organization_id === null
    }

    get modelType(): "users" | "organizations" {
        return this.isOrganization ? "organizations" : "users"
    }

    get label() {
        return this.name || this.key
    }
}
