<template>
    <GFTable
        :data="cards"
        :cols="[['friendly_name', 'Name'], ['is_default', ''], 'actions']"
        empty-message="No cards on file"
        :init-loading="isLoading"
    >
        <template #friendly_name="{ row: card }">
            <div class="flex">
                <GFIcon icon="credit-card" rem-size="2.2" class="mr-3" />
                <div>
                    <div class="flex">
                        <p class="text-sm font-medium text-gray-900">
                            {{ card.friendly_name }}
                        </p>
                        <div
                            v-if="card.is_default"
                            class="ml-2 flex flex-shrink-0"
                        >
                            <p
                                class="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800"
                            >
                                Default
                            </p>
                        </div>
                    </div>
                    <p class="text-sm text-gray-500">
                        Credit card ending in &bull;&bull;&bull;&bull;
                        {{ card.card.last4 }}
                    </p>
                </div>
            </div>
        </template>
        <template #is_default="{ fieldData: isDefault, row: card }">
            <GFTag v-if="isDefault" class="float-right">Default</GFTag>
            <GFButtonSimple
                v-else
                size="small"
                class="float-right"
                :loading="cardBeingUpdated === card.id"
                @click="updateCard(card.id)"
            >
                Set as default
            </GFButtonSimple>
        </template>
        <template #actions="{ row: card }">
            <div class="float-right h-6">
                <GFButtonIcon
                    v-if="!card.is_default"
                    icon="trash"
                    danger
                    :loading="cardBeingRemoved === card.id"
                    class="h-6 w-6"
                    @click="removeCard(card.id)"
                />
            </div>
        </template>
    </GFTable>
</template>
<script setup lang="ts">
import type { PropType } from "vue"
import GFTag from "../base/GFTag.vue"
import GFIcon from "../base/GFIcon.vue"
import GFTable from "../base/GFTable.vue"
import { useUserStore } from "@/stores/user"
import GFButtonIcon from "../base/GFButtonIcon.vue"
import GFButtonSimple from "../base/GFButtonSimple.vue"
import type { PaymentMethod } from "@/models/PaymentMethod"
import { useOrganizationStore } from "@/stores/organization"
import { usePaymentMethodDelete } from "@/queries/payment-methods/usePaymentMethodDelete"
import { usePaymentMethodUpdate } from "@/queries/payment-methods/usePaymentMethodUpdate"

defineProps({
    cards: {
        type: Array as PropType<PaymentMethod[]>,
        required: true,
    },
    isLoading: {
        type: Boolean,
        required: true,
    },
})

const { orgId } = storeToRefs(useOrganizationStore())
const { user } = storeToRefs(useUserStore())
const isOrg = computed(() => orgId.value !== "private")

const modelId = computed(() => (isOrg.value ? orgId.value : user.value?.id))
const modelType = computed(() => (isOrg.value ? "organizations" : "users"))

const { mutate: deleteCardMutation } = usePaymentMethodDelete()
const { mutate: updateCardMutation } = usePaymentMethodUpdate()

const cardBeingUpdated = ref("")
const cardBeingRemoved = ref("")

/**
 * Sets the given payment method as the user's default payment method.
 */
async function updateCard(userPaymentMethod: string) {
    if (!modelId.value) return

    cardBeingUpdated.value = userPaymentMethod
    updateCardMutation(
        {
            paymentMethodId: userPaymentMethod,
            modelId: modelId.value,
            modelType: modelType.value,
        },
        {
            onSettled: () => {
                cardBeingUpdated.value = ""
            },
        }
    )
}

/**
 * Removes a card from the user's list of payment methods.
 */
async function removeCard(userPaymentMethod: string) {
    if (!modelId.value) return

    cardBeingRemoved.value = userPaymentMethod
    deleteCardMutation(
        {
            paymentMethodId: userPaymentMethod,
            modelId: modelId.value,
            modelType: modelType.value,
        },
        {
            onSettled: () => {
                cardBeingRemoved.value = ""
            },
        }
    )
}
</script>
