import type { ApiPromoCodeData } from "types/api"

export default class PromoCode {
    id: string
    amount_off: number | null
    code: string
    coupon_id: string
    is_active: boolean
    is_valid: boolean
    name: string
    percent_off: number | null

    constructor(data: ApiPromoCodeData) {
        this.id = data.id
        this.amount_off = data.amount_off
        this.code = data.code
        this.coupon_id = data.coupon_id
        this.is_active = data.is_active
        this.is_valid = data.is_valid
        this.name = data.name
        this.percent_off = data.percent_off
    }
}
