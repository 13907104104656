<template>
    <GFModalSimple v-model:show="showInput" hide-actions :size="size">
        <div class="md:flex md:items-start">
            <div
                class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-50 md:mx-0 md:h-10 md:w-10"
            >
                <slot name="icon">
                    <GFIcon
                        class="fa-lg h-6 w-6"
                        :class="[danger ? 'text-red-400' : iconColorClass]"
                        :icon="icon"
                    />
                </slot>
            </div>

            <div class="mt-3 text-center md:mt-0 md:ml-4 md:text-left">
                <DialogTitle
                    as="h3"
                    class="text-lg font-medium leading-6 text-gray-900"
                >
                    {{ title }}
                </DialogTitle>

                <div class="mt-2">
                    <slot>
                        <div v-if="typeof message === 'string'">
                            <p class="text-sm text-gray-500">
                                {{ message }}
                            </p>
                        </div>

                        <div
                            v-if="Array.isArray(message)"
                            class="flex flex-col space-y-4"
                        >
                            <p
                                v-for="(line, i) in message"
                                :key="i"
                                class="text-sm text-gray-500"
                            >
                                {{ line }}
                            </p>
                        </div>
                    </slot>
                </div>
            </div>
        </div>

        <template #buttons="{ close }">
            <slot name="buttons" :close="close" />
        </template>
    </GFModalSimple>
</template>

<script setup lang="ts">
import GFIcon from "./GFIcon.vue"
import type { PropType } from "vue"
import { DialogTitle } from "@headlessui/vue"
import GFModalSimple from "./GFModalSimple.vue"

const emit = defineEmits<{
    (e: "update:show", show: boolean): void
    (e: "closed"): void
}>()

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    message: {
        type: [String, Array, null] as PropType<string | string[] | null>,
        default: null,
    },
    title: {
        type: String,
        default: null,
    },
    icon: {
        type: String,
        default: "exclamation-circle",
    },
    danger: {
        type: Boolean,
        default: false,
    },
    iconColorClass: {
        type: String,
        default: "text-gray-400",
    },
    size: {
        type: String as PropType<
            "normal" | "fit" | "full" | "large" | "x-large" | "2x-large"
        >,
        default: "normal",
    },
})

const showInput = computed({
    get() {
        return props.show
    },
    set(value) {
        emit("update:show", value)
    },
})
</script>
