import { useUserStore } from "@/stores/user"
import { ref } from "vue"
import useAxios from "./useAxios"
import { useStatelessAuth } from "./useStatelessAuth"
import { useSanctumQuery } from "@/queries/auth/useSanctumQuery"

/**
 * Is true while pending auth api request.
 */
const authLoading = ref(false)

export default function useAuth() {
    const userStore = useUserStore()
    const { axios, setAxiosToken, isAxiosError } = useAxios()
    const { isStateless, token } = useStatelessAuth()

    async function login(
        email: string | null,
        password: string | null,
        remember?: boolean
    ) {
        authLoading.value = true

        if (isStateless.value) {
            try {
                token.value = await axios
                    .post<{ token: string }>("/api/tokens", {
                        email: email,
                        password: password,
                    })
                    .then((resp) => resp.data.token)

                if (token.value) {
                    localStorage.setItem("gf-token", token.value)
                    setAxiosToken(token.value)
                }

                const data = await userStore.getMe(token.value)
                userStore.user = data
                userStore.isAuthenticated = true
            } catch (error) {
                if (isAxiosError(error)) {
                    userStore.isAuthenticated = false
                    return error
                }
            } finally {
                authLoading.value = false
            }
            return
        }

        /**
         * Stateful
         */
        try {
            await useSanctumQuery()
            await axios.post("/login", {
                email,
                password,
                ...(remember && { remember }),
            })
            const data = await userStore.getMe()
            userStore.isAuthenticated = true
            userStore.user = data
        } catch (error) {
            if (error instanceof Error) console.error(error.message)
            userStore.isAuthenticated = false
        } finally {
            authLoading.value = false
        }
    }

    async function logout() {
        /**
         * Stateless
         */
        if (isStateless.value) {
            await axios.delete("/api/tokens")
            localStorage.removeItem("gf-token")
            userStore.user = null
            userStore.isAuthenticated = false
            return
        }

        /**
         * Stateful
         */
        try {
            await axios.post("/logout")
            userStore.user = null
            userStore.isAuthenticated = false
        } catch (error) {
            if (error instanceof Error) console.error(error.message)
        }
    }

    async function getMe() {
        authLoading.value = true

        if (isStateless.value) {
            token.value = localStorage.getItem("gf-token")
            if (!token.value) {
                userStore.isAuthenticated = false
                userStore.user = null

                authLoading.value = false
                return
            }
        }

        try {
            const data = await userStore.getMe(token.value)
            userStore.isAuthenticated = true
            userStore.user = data
        } catch (error) {
            userStore.isAuthenticated = false
            userStore.user = null
        }

        authLoading.value = false
    }

    return {
        authLoading,
        login,
        getMe,
        logout,
    }
}
