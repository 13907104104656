<template>
    <div class="space-y-8" data-testid="settings-signature-view">
        <SectionLayout title="Signatures" description="Manage your signatures.">
            <template #actions>
                <GFButton
                    icon="plus"
                    label="Create new signature"
                    @click="initCreateNewSig"
                />
            </template>

            <SectionLayoutForm>
                <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                    <UserSignatureCard
                        v-for="(signature, index) in signatures"
                        :key="'signature-' + index"
                        :signature="signature"
                        :user-id="userId"
                        @update-default="updateDefaultSignature"
                        @delete="deleteSignature"
                    />
                </div>

                <template #footer>
                    <div class="flex w-full">
                        <GFButtonSimple
                            label="Clear default"
                            @click="clearDefaultSignature"
                        />
                    </div>
                </template>
            </SectionLayoutForm>
        </SectionLayout>

        <SectionLayout title="Initials" description="Manage your initials.">
            <template #actions>
                <GFButton
                    icon="plus"
                    label="Create new initials"
                    @click="initCreateNewInt"
                />
            </template>

            <SectionLayoutForm>
                <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                    <UserSignatureCard
                        v-for="(initial, index) in initials"
                        :key="'initial-' + index"
                        data-testid="user-initial-card"
                        type="initial"
                        :signature="initial"
                        :user-id="userId"
                        @update-default="updateDefaultInitials"
                        @delete="deleteInitial"
                    />
                </div>

                <template #footer>
                    <div class="flex w-full">
                        <GFButtonSimple
                            label="Clear default"
                            @click="clearDefaultInitials"
                        />
                    </div>
                </template>
            </SectionLayoutForm>
        </SectionLayout>

        <NewUserSignatureModal v-model="showNewSigModal" />

        <NewUserSignatureModal v-model="showNewInitialsModal" type="initial" />
    </div>
</template>
<script setup lang="ts">
import type User from "@/models/User"
import GFButton from "../base/GFButton.vue"
import { useUserStore } from "@/stores/user"
import { QueryKey } from "@/queries/QueryKey"
import type UserInitial from "@/models/UserInitial"
import { useQueryClient } from "@tanstack/vue-query"
import SectionLayout from "../layout/SectionLayout.vue"
import GFButtonSimple from "../base/GFButtonSimple.vue"
import type UserSignature from "@/models/UserSignature"
import UserSignatureCard from "../base/UserSignatureCard.vue"
import SectionLayoutForm from "../layout/SectionLayoutForm.vue"
import NewUserSignatureModal from "../modals/NewUserSignatureModal.vue"
import { useUserInitialQuery } from "@/queries/user-initials/useUserInitialQuery"
import { useUserInitialUpdate } from "@/queries/user-initials/useUserInitialUpdate"
import { useUserInitialDelete } from "@/queries/user-initials/useUserInitialDelete"
import { useUserSignatureQuery } from "@/queries/user-signatures/useUserSignatureQuery"
import { useUserSignatureUpdate } from "@/queries/user-signatures/useUserSignatureUpdate"
import { useUserSignatureDelete } from "@/queries/user-signatures/useUserSignatureDelete"

const queryClient = useQueryClient()
const userStore = useUserStore()
const userId = (userStore.user as User).id
const showNewSigModal = ref(false)
const showNewInitialsModal = ref(false)

const { data: signatures } = useUserSignatureQuery(computed(() => "me"))
const { mutate: updateInitial } = useUserInitialUpdate()
const { mutate: updateSignature } = useUserSignatureUpdate()
const { data: initials } = useUserInitialQuery(computed(() => "me"))
const { mutate: deleteSignatureMutation } = useUserSignatureDelete()
const { mutate: deleteInitialMutation } = useUserInitialDelete()

function initCreateNewSig() {
    showNewSigModal.value = true
}

function initCreateNewInt() {
    showNewInitialsModal.value = true
}

function clearDefaultSignature() {
    const defaultSignature = queryClient
        .getQueryData<UserSignature[]>([
            QueryKey.base("user-signatures"),
            "me",
            null,
        ])
        ?.find((signature) => signature.is_default)

    if (!defaultSignature) return

    updateSignature({
        payload: { is_default: false },
        userSigId: defaultSignature.id,
        userId,
    })
}

function clearDefaultInitials() {
    const defaultInitial = queryClient
        .getQueryData<UserInitial[]>([
            QueryKey.base("user-initials"),
            "me",
            null,
        ])
        ?.find((initial) => initial.is_default)

    if (!defaultInitial) return

    updateInitial({
        payload: { is_default: false },
        userSigId: defaultInitial.id,
        userId,
    })
}

function updateDefaultSignature(id: UserSignature["id"], to: boolean) {
    updateSignature({
        payload: { is_default: to },
        userSigId: id,
        userId,
    })
}

function updateDefaultInitials(id: UserSignature["id"], to: boolean) {
    updateInitial({
        payload: { is_default: to },
        userSigId: id,
        userId,
    })
}

function deleteSignature(id: UserSignature["id"]) {
    deleteSignatureMutation({
        userSigId: id,
        userId,
    })
}

function deleteInitial(id: UserSignature["id"]) {
    deleteInitialMutation({
        userSigId: id,
        userId,
    })
}
</script>
