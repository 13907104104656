import type { ApiGlobalId, ApiUserInitialData } from "types/api"
import type User from "./User"

export default class UserInitial {
    id: ApiGlobalId
    is_default: boolean
    user_id: User["id"]
    content: string
    created_at: string
    updated_at: string

    constructor(data: ApiUserInitialData) {
        this.is_default = data.is_default
        this.id = data.id
        this.user_id = data.user_id
        this.content = data.content
        this.created_at = data.created_at
        this.updated_at = data.updated_at
    }
}
