import { useRoute } from "vue-router"
import type { Webhook } from "@/models/Webhook"
import type Invitation from "@/models/Invitation"
import type { GalleryRouteQueryTabs } from "types/types"
import type { SubscriptionPlan } from "@/models/SubscriptionPlan"
import type Folder from "@/models/Folder"
import type { PDFMode } from "types/api"
import type PDF from "@/models/PDF"
import type Stub from "@/models/Stub"
import type Signee from "@/models/Signee"

export function useRouteQuery() {
    const route = useRoute()

    const folderRouteQuery = computed<Folder["id"] | null>(() =>
        typeof route.query.folder === "string"
            ? (route.query.folder as Folder["id"])
            : null
    )

    const editorModeRouteQuery = computed<PDFMode | null>(() =>
        typeof route.params.mode === "string"
            ? (route.params.mode as PDFMode)
            : null
    )

    const activePDFRouteQuery = computed<PDF["id"] | null>(() =>
        typeof route.query.active === "string"
            ? (route.query.active as PDF["id"])
            : null
    )

    const pdfRouteParam = computed<PDF["id"] | null>(() =>
        typeof route.params.pdfId === "string"
            ? (route.params.pdfId as PDF["id"])
            : null
    )

    const documentsTabQuery = computed<GalleryRouteQueryTabs | null>(() =>
        typeof route.query.tab === "string"
            ? (route.query.tab as GalleryRouteQueryTabs)
            : null
    )

    const confirmInviteId = computed<Invitation["id"] | null>(() =>
        typeof route.query.confirm_invite_id === "string"
            ? (route.query.confirm_invite_id as Invitation["id"])
            : null
    )

    const subscriptionPlanId = computed<SubscriptionPlan["id"] | null>(() =>
        typeof route.params.subscriptionPlanId === "string"
            ? (route.params.subscriptionPlanId as SubscriptionPlan["id"])
            : null
    )

    const stubId = computed<Stub["id"] | null>(() =>
        typeof route.params.stubId === "string"
            ? (route.params.stubId as Stub["id"])
            : null
    )

    const webhookId = computed<Webhook["id"] | null>(() =>
        typeof route.params.webhookId === "string"
            ? (route.params.webhookId as Webhook["id"])
            : null
    )

    const singingAsId = computed<Signee["id"] | null>(() =>
        typeof route.query.signing_as === "string"
            ? (route.query.signing_as as Signee["id"])
            : null
    )

    const triggerNewOrganization = computed<boolean>(
        () => route.query.trigger_new_org === "true"
    )

    /**
     * Used for cache busting.
     * Specifically useful with the active pdf route query.
     */
    const routerTimestamp = computed<string | null>(() =>
        typeof route.query.t === "string" ? route.query.t : null
    )

    return {
        folderRouteQuery,
        pdfRouteParam,
        documentsTabQuery,
        confirmInviteId,
        activePDFRouteQuery,
        editorModeRouteQuery,
        subscriptionPlanId,
        webhookId,
        routerTimestamp,
        stubId,
        singingAsId,
        triggerNewOrganization,
    }
}
