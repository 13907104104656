import config from "@/config"
import User from "@/models/User"
import { defineStore } from "pinia"
import useEnv from "@/composables/useEnv"
import useAxios from "@/composables/useAxios"
import { useWebsocketsStore } from "./websockets"
import { useQueryClient } from "@tanstack/vue-query"
import { useOrganizationStore } from "./organization"
import type { ApiUserData, OrganizationUserRole } from "types/api"
import { refreshNotifications } from "@/queries/notifications/useNotificationsQuery"

export const useUserStore = defineStore("user", () => {
    const queryClient = useQueryClient()
    const wsStore = useWebsocketsStore()
    const organizationStore = useOrganizationStore()

    /**
     * Is true when a user has been authenticated.
     * Is false when the user is not authenticated.
     *
     * Initial value is null indicating that the user has not been confirmed as neither authenticated or not.
     * This is useful to insure the user isn't redirected until we have confirmed auth status.
     */
    const isAuthenticated = ref<boolean | null>(null)

    /**
     * User data
     */
    const user = ref<User | null>(null)

    /**
     * The organization user role for the currently authenticated user.
     */
    const userOrgRole = ref<OrganizationUserRole | undefined>(undefined)

    const userEmail = computed<string | undefined>(() => user.value?.email)

    const userIsBillable = computed<boolean>(
        () => user.value?.is_billable ?? config.defaultIsBillable
    )

    const showEmailVerificationModal = ref(false)

    watch(user, (user) => {
        if (user) {
            initNotificationListener(user)
        } else {
            wsStore.leaveAllChannels()
        }
    })

    /**
     * Fetches the user data and returns the user data.
     */
    async function getMe(token?: string | null) {
        const { axios } = useAxios()
        const data = await axios
            .get<ApiUserData>("/api/users/me", {
                ...(token && {
                    headers: { Authorization: "Bearer " + token },
                }),
                params: { append: ["avatar_base64", "is_billable"] },
            })
            .then((resp) => new User(resp.data))
        return data
    }

    function setUserRole(role: OrganizationUserRole) {
        userOrgRole.value = role
    }
    function checkForVerifiedEmail(): boolean {
        if (!user.value) return false
        if (user.value.verifiedEmail) return true

        showEmailVerificationModal.value = true

        return false
    }

    function initNotificationListener(user: User) {
        if (useEnv().websocketsDisabled) return

        wsStore.listenToAllEvents(`users.${user.id}.notifications`, () =>
            refreshNotifications(queryClient)
        )
    }

    /**
     * Runs initialization tasks for the user store.
     */
    function init() {
        const hasVerifiedEmail = checkForVerifiedEmail()

        if (hasVerifiedEmail) {
            organizationStore.checkForPendingInvites()
        }
    }

    return {
        userOrgRole,
        user,
        isAuthenticated,
        userEmail,
        userIsBillable,
        getMe,
        setUserRole,
        checkForVerifiedEmail,
        initNotificationListener,
        showEmailVerificationModal,
        init,
    }
})
