<template>
    <div class="">
        <label
            :for="name"
            class="flex justify-between text-sm font-medium text-gray-700"
        >
            <slot>{{ label }}</slot>

            <!-- <span
                v-if="showOptional"
                class="text-gray-400 font-normal ml-auto italic"
            >
                Optional
            </span> -->
        </label>
        <div
            class="custom-number-input h-[2.35rem]"
            :class="[
                size === 'sm' && 'w-32',
                size === 'md' && 'w-40',
                size === 'lg' && 'w-48',
                size === 'full' && 'w-full',
                label && 'mt-1',
            ]"
        >
            <div
                class="relative flex h-[2.35rem] w-full flex-row flex-nowrap rounded-lg bg-transparent shadow-sm"
            >
                <button
                    class="h-full w-20 cursor-pointer rounded-l border border-gray-300 bg-gray-100 text-gray-700 outline-none hover:bg-gray-50 hover:text-gray-700"
                    @click="decrement"
                >
                    <span
                        class="mb-0.5 flex items-center justify-center font-medium"
                    >
                        −
                    </span>
                </button>

                <input
                    v-model="numberInput"
                    v-bind="{ ...(max && { max }), ...(min && { min }), name }"
                    type="number"
                    :placeholder="placeholder"
                    class="text-md relative -mx-px block w-full cursor-default appearance-none items-center rounded-none border border-gray-300 text-center font-semibold text-gray-900 placeholder-gray-500 outline-none hover:text-black focus:z-10 focus:border-primary focus:text-black focus:outline-none focus:ring-primary sm:text-sm md:text-base"
                    @wheel.prevent
                    @touchmove.prevent
                    @scroll.prevent
                />

                <button
                    class="h-full w-20 cursor-pointer rounded-r border border-gray-300 bg-gray-100 text-gray-700 outline-none hover:bg-gray-50 hover:text-gray-700"
                    @click="increment"
                >
                    <span
                        class="mb-0.5 flex items-center justify-center font-medium"
                    >
                        +
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { PropType } from "vue"

const emits = defineEmits<{
    (e: "update:modelValue", val: number): void
}>()

const props = defineProps({
    modelValue: {
        type: Number as PropType<number | null>,
        default: null,
    },
    label: {
        type: String,
        default: null,
    },
    placeholder: {
        type: String,
        default: null,
    },
    base: {
        type: Number,
        default: 1,
    },
    max: {
        type: Number as PropType<number | null>,
        default: null,
    },
    min: {
        type: Number,
        default: null,
    },
    name: {
        type: String,
        default: null,
    },
    size: {
        type: String as PropType<"sm" | "md" | "lg" | "full">,
        default: "md",
    },
    increment: {
        type: Number,
        default: 1,
    },
})

const numberInput = computed({
    get() {
        return props.modelValue
    },
    set(val) {
        if (typeof val === "number") {
            const safeValue =
                props.max !== null
                    ? // If the value is greater than the maximum value, set it to the maximum value
                      Math.min(val, props.max)
                    : // If the value is less than the minimum value, set it to the minimum value
                    props.min !== null
                    ? Math.max(val, props.min)
                    : // Otherwise, set it to the value
                      val

            emits("update:modelValue", safeValue)
        }
    },
})

function decrement() {
    if (props.modelValue === null)
        // If the value is null, set it to the base value
        numberInput.value = props.base
    else
        numberInput.value =
            props.min !== null
                ? // If the value is less than the minimum value, set it to the minimum value
                  Math.max(props.modelValue - props.increment, props.min)
                : // Otherwise, decrement the value by increment value
                  props.modelValue - props.increment
}

function increment() {
    if (props.modelValue === null)
        // If the value is null, set it to the base value
        numberInput.value = props.base
    else
        numberInput.value =
            props.max !== null
                ? // If the value is greater than the maximum value, set it to the maximum value
                  Math.min(props.modelValue + props.increment, props.max)
                : // Otherwise, increment the value by increment value
                  props.modelValue + props.increment
}

const decrementButtons = document.querySelectorAll(
    `button[data-action="decrement"]`
)

const incrementButtons = document.querySelectorAll(
    `button[data-action="increment"]`
)

decrementButtons.forEach((btn) => {
    btn.addEventListener("click", decrement)
})

incrementButtons.forEach((btn) => {
    btn.addEventListener("click", increment)
})
</script>

<style>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.custom-number-input input:focus {
    outline: none !important;
}

.custom-number-input button:focus {
    outline: none !important;
}
</style>
