import type { ApiPaymentMethodIntentData } from "types/api"

export class PaymentMethodIntent {
    stripe_public_key: string
    client_secret: string

    constructor(data: ApiPaymentMethodIntentData) {
        this.stripe_public_key = data.stripe_public_key
        this.client_secret = data.intent.client_secret
    }
}
