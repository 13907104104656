import type { ApiCreateUserInitial, ApiUserInitialData } from "types/api"
import useAxios from "@/composables/useAxios"
import UserInitial from "@/models/UserInitial"
import { useMutation, useQueryClient } from "@tanstack/vue-query"
import type User from "@/models/User"
import { QueryKey } from "../QueryKey"

export function useUserInitialCreate() {
    const { axios } = useAxios()
    const queryClient = useQueryClient()
    return useMutation(
        ({
            payload,
            userId,
        }: {
            payload: ApiCreateUserInitial
            userId: User["id"] | "me"
        }) =>
            axios
                .post<ApiUserInitialData>(
                    `/api/users/${userId}/initials`,
                    payload
                )
                .then((resp) => new UserInitial(resp.data)),
        {
            onSettled: () => {
                queryClient.invalidateQueries(QueryKey.baseKey("user-initials"))
            },
        }
    )
}
