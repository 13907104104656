<template>
    <RadioGroup v-model="selectedInput" :disabled="disabled">
        <RadioGroupLabel class="sr-only"> Pricing plans </RadioGroupLabel>
        <div
            class="relative -space-y-px rounded-md bg-white"
            :class="[disabled && 'opacity-50']"
        >
            <RadioGroupOption
                v-for="(item, i) in items"
                :key="i"
                v-slot="{ checked, active }"
                as="template"
                :value="item"
            >
                <div
                    :class="[
                        'relative flex flex-col border p-4 focus:outline-none md:grid md:pl-4 md:pr-6',
                        i === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                        i === items.length - 1
                            ? 'rounded-bl-md rounded-br-md'
                            : '',
                        checked
                            ? 'z-10 border-gray-200 bg-gray-50'
                            : 'border-gray-200',
                        gridCols,
                        !disabled && 'cursor-pointer',
                        disabled && 'cursor-default',
                    ]"
                >
                    <span
                        class="flex items-center text-sm"
                        :class="[`cols-span-${labelSpan}`]"
                    >
                        <span
                            :class="[
                                checked
                                    ? 'border-transparent bg-primary'
                                    : 'border-gray-300 bg-white',
                                active
                                    ? 'ring-2 ring-gray-900 ring-offset-2'
                                    : '',
                                'flex h-4 w-4 items-center justify-center rounded-full border',
                            ]"
                            aria-hidden="true"
                        >
                            <span class="h-1.5 w-1.5 rounded-full bg-white" />
                        </span>
                        <RadioGroupLabel
                            as="span"
                            class="ml-3 font-medium text-gray-900"
                        >
                            <slot name="label" :row="item"></slot>
                        </RadioGroupLabel>
                    </span>
                    <slot :row="item"></slot>
                </div>
            </RadioGroupOption>
        </div>
    </RadioGroup>
</template>
<script setup lang="ts">
import type { PropType } from "vue"
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue"

export type RadioOption = Record<any, any>

const emit = defineEmits<{
    (e: "update:selected", option: RadioOption | null): void
}>()

const props = defineProps({
    items: {
        type: Array as PropType<RadioOption[]>,
        required: true,
    },
    selected: {
        type: Object as PropType<RadioOption | null>,
        default: null,
    },
    cols: {
        type: Number,
        default: 3,
    },
    labelSpan: {
        type: Number,
        default: 1,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
})

const selectedInput = computed({
    get: () => props.selected ?? undefined,
    set: (value) => emit("update:selected", value ?? null),
})

const gridCols = computed(() => {
    return `grid-cols-${props.cols}`
})
</script>
