import { isObjectLike } from "lodash"
import type {
    ApiGlobalId,
    ApiSubscriptionPlanData,
    BillingModelType,
    SubscriptionPlanPriceInterval,
} from "types/api"
import { SubscriptionPlanFeature } from "./SubscriptionPlanFeature"
import { SubscriptionPlanPrice } from "./SubscriptionPlanPrice"

export type FlattenedData = [
    IndentLvl: number,
    KeyOrIndex: string | number,
    Value: string | number | object
]

export class SubscriptionPlan {
    id: ApiGlobalId
    name: string
    is_stripe_plan: boolean
    description: string
    order: number
    is_published: boolean
    stripe_product_id: string | null
    stripe_monthly_price_id: string | null
    stripe_yearly_price_id: string | null
    stripe_contract_price_id: string | null
    monthly_price: SubscriptionPlanPrice | null
    yearly_price: SubscriptionPlanPrice | null
    contract_price: SubscriptionPlanPrice | null
    fallback_name: string | null
    fallback_description: string | null
    fallback_monthly_price: string | null
    fallback_yearly_price: string | null
    created_at: string
    updated_at: string
    button_txt: null | string
    button_url: null | string
    features?: SubscriptionPlanFeature[]

    static labelMap = new Map<string, string>([
        ["id", "ID"],
        ["order", "Order"],
        ["is_published", "Is published"],
        ["stripe_product_id", "Stripe product ID"],
        ["stripe_monthly_price_id", "Stripe monthly price ID"],
        ["stripe_yearly_price_id", "Stripe yearly price ID"],
        ["stripe_contract_price_id", "Stripe contract price ID"],
        ["name", "Name"],
        ["description", "Description"],
        ["monthly_price", "Monthly price"],
        ["yearly_price", "Yearly price"],
        ["contract_price", "Contract price"],
        ["created_at", "Created at"],
        ["updated_at", "Updated at"],
        ["features", "Features"],

        // ApiSubscriptionPlanPriceData fields
        ["interval", "Interval"],
        ["price_cents", "Price (cents)"],
        ["price_dollars", "Price (dollars)"],
        ["pricing_model", "Pricing model"],
        ["is_metered", "Is metered"],
        ["tiers", "Tiers"],
        ["metadata", "Metadata"],

        // ApiSubscriptionPlanFeatureData fields
        ["subscription_plan_id", "Subscription plan ID"],
        ["text", "Text"],

        // ApiSubscriptionPlanPriceTierData fields
        ["flat_amount", "Flat amount"],
        ["flat_amount_decimal", "Flat amount decimal"],
        ["unit_amount", "Unit amount"],
        ["unit_amount_decimal", "Unit amount decimal"],
        ["up_to", "Up to"],
    ])

    constructor(data: ApiSubscriptionPlanData) {
        this.id = data.id
        this.order = data.order
        this.is_published = data.is_published
        this.stripe_product_id = data.stripe_product_id
        this.stripe_monthly_price_id = data.stripe_monthly_price_id
        this.stripe_yearly_price_id = data.stripe_yearly_price_id
        this.stripe_contract_price_id = data.stripe_contract_price_id
        this.fallback_name = data.fallback_name
        this.fallback_description = data.fallback_description
        this.fallback_monthly_price = data.fallback_monthly_price
        this.fallback_yearly_price = data.fallback_yearly_price
        this.is_stripe_plan = data.is_stripe_plan
        this.name = data.name
        this.description = data.description
        this.created_at = data.created_at
        this.updated_at = data.updated_at
        this.button_txt = data.button_txt
        this.button_url = data.button_url
        this.features = data.features?.map(
            (feature) => new SubscriptionPlanFeature(feature)
        )

        this.monthly_price = data.monthly_price
            ? new SubscriptionPlanPrice(data.monthly_price)
            : data.monthly_price

        this.yearly_price = data.yearly_price
            ? new SubscriptionPlanPrice(data.yearly_price)
            : data.yearly_price

        this.contract_price = data.contract_price
            ? new SubscriptionPlanPrice(data.contract_price)
            : data.contract_price
    }

    get flattenForDisplay(): FlattenedData[] {
        return this.flattenObject(this)
    }

    get priceMonthly(): SubscriptionPlanPrice | null {
        return this.monthly_price || null
    }

    get priceYearly(): SubscriptionPlanPrice | null {
        return this.yearly_price || null
    }

    get priceContract(): SubscriptionPlanPrice | null {
        return this.contract_price || null
    }

    get priceMonthlyString(): string {
        return this.monthly_price?.price_dollars || ""
    }

    get priceYearlyString(): string {
        return this.yearly_price?.price_dollars || ""
    }

    get priceContractString(): string {
        return this.contract_price?.price_dollars || ""
    }

    get stripeProductId() {
        return this.stripe_product_id
    }

    get isPublished() {
        return this.is_published
    }

    get isStripePlan() {
        return this.is_stripe_plan
    }

    get fallbackName() {
        return this.fallback_name
    }

    get fallbackDescription() {
        return this.fallback_description
    }

    get fallbackMonthlyPrice() {
        return this.fallback_monthly_price
    }

    get fallbackYearlyPrice() {
        return this.fallback_yearly_price
    }

    get stripeMonthlyPriceId() {
        return this.stripe_monthly_price_id
    }

    get stripeYearlyPriceId() {
        return this.stripe_yearly_price_id
    }

    get buttonText() {
        return this.button_txt
    }

    get buttonUrl() {
        return this.button_url
    }

    static getLabel(key: string): string {
        return this.labelMap.get(key) || key
    }

    /**
     * Flatten object to array of key-value pairs with indentation
     */
    flattenObject(obj: Record<any, any>, indent = 0): FlattenedData[] {
        /**
         * The key could be a string with a number (which would be an index number),
         * So we need to convert it to a number if it is a number and set the length.
         * Otherwise we just return the key as a string.
         */
        const handleKey = (key: string) =>
            isNaN(+key) ? key : parseInt(key) + 1

        return Object.entries(obj).reduce<FlattenedData[]>(
            (acc, [key, value]) => {
                // If value is an array
                if (Array.isArray(value)) {
                    return [
                        ...acc,
                        [indent, handleKey(key), value],
                        ...this.flattenObject(value, indent + 1),
                    ]
                }

                // If value is an object
                if (isObjectLike(value)) {
                    return [
                        ...acc,
                        [indent, handleKey(key), value],
                        ...this.flattenObject(value, indent + 1),
                    ]
                }

                // If value is a primitive
                return [...acc, [indent, key, value]]
            },
            []
        )
    }

    getPriceFromInterval(interval: SubscriptionPlanPriceInterval) {
        switch (interval) {
            case "monthly":
                return this.priceMonthly
            case "yearly":
                return this.priceYearly
            default:
                throw new Error("Invalid interval")
        }
    }

    costStringMonthlyComparison(
        modelType: BillingModelType,
        interval: SubscriptionPlanPriceInterval,
        users: number
    ): string | null {
        const price = this.getPriceFromInterval(interval)
        if (!price) return null

        const perUser = modelType === "organizations"
        const intervalString = "mo"

        const tierForUsers = price.tierForUsers(users)

        if (!tierForUsers) return null

        const dollars =
            interval === "monthly"
                ? tierForUsers.unitCostInDollars
                : tierForUsers.unitCostInDollarsDiv12

        // If the price is a string, it means it is a custom price and we should just return it.
        if (!this.isStripePlan)
            return interval === "monthly"
                ? this.fallbackMonthlyPrice
                : this.fallbackYearlyPrice

        return `$${dollars} / ${intervalString}${perUser ? " / user" : ""}`
    }

    costString(
        interval: SubscriptionPlanPriceInterval,
        users: number
    ): string | null {
        const price = this.getPriceFromInterval(interval)
        if (!price) return null

        const tierForUsers = price.tierForUsers(users)

        if (!tierForUsers) return null

        const dollars = tierForUsers.unitCostInDollars

        return `$${dollars}`
    }

    costStringTotal(
        interval: SubscriptionPlanPriceInterval,
        users: number
    ): string | null {
        const price = this.getPriceFromInterval(interval)
        if (!price) return null

        const tierForUsers = price.tierForUsers(users)

        if (!tierForUsers) return null

        const dollars = tierForUsers.totalCostInDollars(users)

        return `$${dollars}`
    }
}
