<template>
    <SectionLayoutForm :loading="isLoadingOrganizationSettings">
        <div class="flex flex-grow flex-col">
            <GFInput
                v-model="emailInput"
                name="billing_email"
                label="Billing email"
                :form-errors="formErrors"
                class="w-full"
                @enter="updateEmail"
            />
        </div>

        <template #footer>
            <div class="flex justify-end">
                <GFButton
                    label="Update"
                    :disabled="!canUpdate"
                    :loading="isLoading"
                    @click="updateEmail"
                />
            </div>
        </template>
    </SectionLayoutForm>
</template>
<script setup lang="ts">
import GFInput from "../base/GFInput.vue"
import GFButton from "../base/GFButton.vue"
import GFFormErrors from "@/helpers/GFFormErrors"
import { useOrganizationStore } from "@/stores/organization"
import SectionLayoutForm from "../layout/SectionLayoutForm.vue"
import { useOrganizationSettingsQuery } from "@/queries/organization-settings/useOrganizationSettingsQuery"
import { useOrganizationSettingsUpdate } from "@/queries/organization-settings/useOrganizationSettingsUpdate"

const organizationStore = useOrganizationStore()
const formErrors = ref<GFFormErrors>(new GFFormErrors())
const emailInput = ref<null | string>(null)
const email = ref<null | string>(null)

const canUpdate = computed(
    () =>
        emailInput.value &&
        emailInput.value.length &&
        emailInput.value !== email.value
)

const { data: organizationSettings, isLoading: isLoadingOrganizationSettings } =
    useOrganizationSettingsQuery(computed(() => organizationStore.orgIdString))
const { mutate, isLoading } = useOrganizationSettingsUpdate()

watch(organizationSettings, (settings) => {
    if (settings) {
        reset()
        email.value = settings.billing_email
        emailInput.value = settings.billing_email
    }
})

function updateEmail() {
    if (!organizationStore.orgIdString) return

    mutate(
        {
            orgId: organizationStore.orgIdString,
            setting: "billing_email",
            payload: {
                value: emailInput.value,
            },
        },
        {
            onError(error: any) {
                formErrors.value = new GFFormErrors(error.response?.data)
            },
        }
    )
}

function reset() {
    formErrors.value = new GFFormErrors()
}
</script>
