<template>
    <div class="px-4 py-4">
        <div class="flex items-start">
            <div class="flex-shrink-0">
                <GFIcon
                    v-if="type === 'success'"
                    class="fa-lg h-6 w-6 text-green-400"
                    icon="check-circle"
                />

                <GFIcon
                    v-if="type === 'error'"
                    class="fa-lg h-6 w-6 text-red-400"
                    icon="times-circle"
                />

                <GFIcon
                    v-if="type === 'info'"
                    class="fa-lg h-6 w-6 text-blue-400"
                    icon="info-circle"
                />

                <GFIcon
                    v-if="type === 'forbidden'"
                    class="fa-lg h-6 w-6 text-red-400"
                    icon="ban"
                />

                <GFIcon
                    v-if="type === 'warning' || type === 'danger'"
                    class="fa-lg h-6 w-6 text-orange-400"
                    icon="exclamation-circle"
                />
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">
                    {{ title }}
                </p>
                <p class="mt-1 text-sm text-gray-500">
                    {{ message }}
                </p>
                <div class="mt-3 flex space-x-7">
                    <GFButtonText
                        v-for="(action, idx) in actions"
                        :key="idx"
                        button-style="button"
                        size="sm"
                        :class="[
                            'font-medium',
                            // Nothing being done with these at the moment
                            action.type === 'primary' && '',
                            action.type === 'secondary' && '',
                        ]"
                        :label="action.text"
                        @click="() => action.click({ close })"
                    />
                </div>
            </div>
            <div v-if="!hideCloseButton" class="ml-4 flex flex-shrink-0">
                <GFButtonIcon
                    icon="times"
                    class="h-6 w-6"
                    @click="$emit('close-toast')"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { PropType } from "vue"
import GFIcon from "../base/GFIcon.vue"
import GFButtonIcon from "../base/GFButtonIcon.vue"
import GFButtonText from "../base/GFButtonText.vue"
import type { Action, NotificationType } from "@/composables/useNotification"

const emit = defineEmits(["close-toast"])

defineProps({
    title: {
        type: String,
        default: null,
    },
    message: {
        type: String,
        default: null,
    },
    type: {
        type: String as PropType<NotificationType>,
        default: null,
    },
    actions: {
        type: Array as PropType<Action[]>,
        default: null,
    },
    hideCloseButton: {
        type: Boolean,
        default: false,
    },
})

function close() {
    emit("close-toast")
}
</script>

<style>
.gf-notification .Vue-Toastification__progress-bar {
    background-color: var(--secondary-color);
}

.gf-notification-container.Vue-Toastification__container {
    z-index: 999999;
}
</style>
