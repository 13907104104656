import { useDate } from "@/composables/useDate"
import type {
    ApiInvoiceData,
    ApiInvoiceLineItemData,
    ApiUpcomingInvoiceData,
    InvoiceOwner,
    InvoiceStatus,
} from "types/api"

const { dayjs } = useDate()

export class Invoice {
    id: string | null
    number: string | null
    status: InvoiceStatus
    is_paid: boolean
    paid_at: string
    human_readable_paid_at: string
    due_date: string | null
    raw_tax: number
    raw_total: number
    raw_subtotal: number
    raw_amount_paid: number
    raw_amount_remaining: number
    raw_total_excluding_tax: number
    tax: string
    total: string
    subtotal: string
    amount_paid: string
    amount_remaining: string
    total_excluding_tax: string
    owner: InvoiceOwner
    line_items: ApiInvoiceLineItemData[]

    constructor(data: ApiInvoiceData | ApiUpcomingInvoiceData) {
        this.id = data.id
        this.number = data.number
        this.status = data.status
        this.is_paid = data.is_paid
        this.paid_at = data.paid_at
        this.human_readable_paid_at = data.human_readable_paid_at
        this.due_date = data.due_date
        this.raw_tax = data.raw_tax
        this.raw_total = data.raw_total
        this.raw_subtotal = data.raw_subtotal
        this.raw_amount_paid = data.raw_amount_paid
        this.raw_amount_remaining = data.raw_amount_remaining
        this.raw_total_excluding_tax = data.raw_total_excluding_tax
        this.tax = data.tax
        this.total = data.total
        this.subtotal = data.subtotal
        this.amount_paid = data.amount_paid
        this.amount_remaining = data.amount_remaining
        this.total_excluding_tax = data.total_excluding_tax
        this.owner = data.owner
        this.line_items = data.line_items
    }

    get displayAmount() {
        return this.total
    }

    get displayDueDate() {
        return this.due_date
    }

    get isUpcoming() {
        return this.id === null
    }

    get readableDueDate() {
        return this.due_date ? dayjs(this.due_date).format("MMMM D, YYYY") : "-"
    }

    get readableCreatedAt() {
        return this.paid_at ? dayjs(this.paid_at).format("MMMM D, YYYY") : "-"
    }
}
