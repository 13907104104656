import { useUserStore } from "@/stores/user"
import type { App, InjectionKey } from "vue"
import type { PrivilegedRole } from "types/types"
import type { OrganizationUserRole } from "types/api"

export type CanFunction = (privilegedRoles?: PrivilegedRole) => boolean

declare module "@vue/runtime-core" {
    interface ComponentCustomProperties {
        /**
         * Uses the uses organization role to determine privileges.
         * @privilegedRoles A string or array of roles that should be privileged.
         *
         * Can also be a boolean, which will be passed right through as the result.
         *
         * "all" can also be provided, which is essentially an alias of `true` and
         * has been provided for readability.
         * @default 'admin'
         */
        $can: CanFunction
    }
}

export const can = (
    privilegedRoles: PrivilegedRole = ["admin"],
    roleToTest?: OrganizationUserRole
) => {
    const { userOrgRole } = storeToRefs(useUserStore())

    const userRole = roleToTest ?? userOrgRole.value

    /**
     * Pass boolean straight through as the result.
     */
    if (typeof privilegedRoles === "boolean") return privilegedRoles

    /**
     * Normalize to array
     */
    let roles: (OrganizationUserRole | "all")[]
    if (Array.isArray(privilegedRoles)) {
        roles = privilegedRoles
    } else {
        roles = [privilegedRoles] as (OrganizationUserRole | "all")[]
    }

    return roles.some(
        (allowedRole) => userRole === allowedRole || allowedRole === "all"
    )
}

export const canKey = Symbol() as InjectionKey<CanFunction>

export default {
    install: (app: App) => {
        app.config.globalProperties.$can = can
        app.provide(canKey, can)
    },
}
