import { useOrganizationStore } from "@/stores/organization"
import { useUserStore } from "@/stores/user"
import type { BillingModelType } from "types/api"

export function useBillingModelType() {
    const { user } = storeToRefs(useUserStore())
    const { orgId, orgIdString } = storeToRefs(useOrganizationStore())

    const isOrg = computed(() => orgId.value !== "private")
    const modelType = computed<BillingModelType>(() =>
        isOrg.value ? "organizations" : "users"
    )
    const modelId = computed(() =>
        isOrg.value ? orgIdString.value : user.value?.id
    )
    return { modelId, modelType }
}
