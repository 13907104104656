import type { Router } from "vue-router"
import { can } from "@/plugins/gf-privileged"
import type { PrivilegedRole } from "types/types"
import type { OrganizationUserRole } from "types/api"

/**
 * Provides role-based access control.
 */
export function usePrivileges() {
    /**
     * Checks if the current user has the specified privilege
     * to access the visited route.
     * Redirects accordingly.
     */
    async function handleAuthorizedRoutes(
        role: OrganizationUserRole,
        redirect: string,
        ctx: { router: Router }
    ) {
        return new Promise<void>((resolve) => {
            const authorizedRoles = ctx.router.currentRoute.value.meta
                ?.authorizedRoles as PrivilegedRole | undefined

            const isRouteProtected = authorizedRoles !== undefined
            const canAccessRoute = can(authorizedRoles, role)

            if (!isRouteProtected) return resolve()
            if (canAccessRoute) return resolve()

            ctx.router.push(redirect).then(() => resolve())
        })
    }

    return { can, handleAuthorizedRoutes }
}
