<template>
    <GFModalSimple
        ref="modal"
        v-model:show="isActive"
        :size="currentTab.value === 'draw' ? 'full' : 'large'"
        data-testid="create-user-signature-modal"
        :title="isInitials ? 'Create Initials' : 'Create Signature'"
        @closed="reset"
    >
        <div class="flex w-full flex-col md:flex-row">
            <div class="mr-4 h-full w-32">
                <GFSelect
                    v-model:current="currentTab"
                    :items="tabs"
                    class="mb-3 md:hidden"
                />

                <GFVerticalTabs
                    v-model:selected="currentTab"
                    class="hidden md:block"
                    :tabs="tabs"
                    data-testid="image-upload-modal-tabs"
                >
                    <template #snapshot="{ tab }">
                        <GFVerticalTab
                            :tab="tab"
                            :selected="currentTab.value === tab.value"
                        >
                            <template #icon>
                                <img
                                    class="-ml-1 mr-3 h-6 w-6 flex-shrink-0"
                                    src="@/assets/images/galaxy-forms-favicon.png"
                                />
                            </template>
                        </GFVerticalTab>
                    </template>
                </GFVerticalTabs>
            </div>
            <div
                v-if="currentTab.value === 'draw'"
                class="relative flex max-w-[150rem] flex-grow sm:h-[20rem] md:h-[28rem] xl:h-[35rem]"
            >
                <div
                    v-if="!hidePlaceholder"
                    class="pointer-events-none absolute flex h-full w-full items-center justify-center text-9xl font-semibold text-gray-100"
                >
                    Draw
                </div>

                <UserSignatureDraw
                    ref="sigPad"
                    class="rounded border border-gray-400"
                    @begin="hidePlaceholder = true"
                />
            </div>
            <div
                v-if="currentTab.value === 'type'"
                class="flex flex-grow flex-col space-y-2"
            >
                <GFInput
                    v-model="typeName"
                    autofocus
                    :label="`Type your ${
                        isInitials ? 'initials' : 'name'
                    } here`"
                    class="w-full"
                    @enter="save"
                />
                <div>
                    <div
                        class="my-4 ml-2 flex h-20 w-full items-center text-lg"
                    >
                        <span v-if="!typeName" class="italic text-gray-500">
                            Type to see preview
                        </span>

                        <span class="font-[better-grade] text-[72px]">
                            {{ typeName }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <template #buttons>
            <div class="flex w-full justify-between">
                <GFButtonSimple
                    v-if="currentTab.value === 'draw'"
                    label="Clear"
                    :disabled="isEmpty"
                    @click="onClear"
                />

                <div class="flex flex-grow justify-end space-x-2">
                    <GFButtonSimple label="Cancel" @click="isActive = false" />

                    <GFButton
                        :loading="sigIsLoading || initialIsLoading"
                        :disabled="isEmpty"
                        label="Save"
                        @click="save"
                    />
                </div>
            </div>
        </template>
    </GFModalSimple>
</template>
<script lang="ts" setup>
import type { PropType } from "vue"
import GFInput from "../base/GFInput.vue"
import GFButton from "../base/GFButton.vue"
import GFSelect from "../base/GFSelect.vue"
import { useUserStore } from "@/stores/user"
import GFModalSimple from "../base/GFModalSimple.vue"
import GFVerticalTab from "../base/GFVerticalTab.vue"
import GFButtonSimple from "../base/GFButtonSimple.vue"
import GFVerticalTabs from "../base/GFVerticalTabs.vue"
import UserSignatureDraw from "../base/UserSignatureDraw.vue"
import { useUserInitialCreate } from "@/queries/user-initials/useUserInitialCreate"
import { useUserSignatureCreate } from "@/queries/user-signatures/useUserSignatureCreate"

const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false,
    },
    type: {
        type: String as PropType<"signature" | "initial">,
        default: "signature",
    },
})

const isInitials = computed(() => props.type === "initial")
const { user } = storeToRefs(useUserStore())
const { mutate: createSig, isLoading: sigIsLoading } = useUserSignatureCreate()
const { mutate: createInitial, isLoading: initialIsLoading } =
    useUserInitialCreate()

const tabs = computed(() => [
    { icon: "pencil", label: "Draw", value: "draw" },
    { icon: "keyboard", label: "Type", value: "type" },
])

const currentTab = ref(tabs.value[0])
const typeName = ref("")

const isEmpty = computed(() => {
    if (currentTab.value.value === "draw") return sigPad.value?.isEmpty()
    return !typeName.value
})

const emit = defineEmits(["update:modelValue"])
const sigPad = ref<any>()

const isActive = computed({
    get() {
        return props.modelValue
    },
    set(value: boolean) {
        emit("update:modelValue", value)
    },
})

const hidePlaceholder = ref(false)

watch(isActive, () => {
    if (isActive.value) hidePlaceholder.value = false
})

const createFunc = computed(() => {
    if (isInitials.value) return createInitial
    return createSig
})

function save() {
    if (!user.value) return

    if (currentTab.value.value === "draw") {
        if (sigPad.value) {
            createFunc.value(
                {
                    payload: {
                        content: sigPad.value.toDataBase46(),
                        is_default: false,
                    },
                    userId: "me",
                },
                {
                    onSuccess() {
                        isActive.value = false
                    },
                }
            )
        }
    }

    if (currentTab.value.value === "type") {
        createFunc.value(
            {
                payload: {
                    text: typeName.value,
                    is_default: false,
                },
                userId: "me",
            },
            {
                onSuccess() {
                    isActive.value = false
                },
            }
        )
    }
}

function onClear() {
    sigPad.value.clear()
}

function reset() {
    typeName.value = ""
    currentTab.value = tabs.value[0]
}
</script>
