import type {
    ApiError,
    ApiOrganizationUserData,
    ApiUpdateOrganizationUserPayload,
} from "types/api"
import type User from "@/models/User"
import type { AxiosError } from "axios"
import useAxios from "@/composables/useAxios"
import type Organization from "@/models/Organization"
import { useMutation, useQueryClient } from "@tanstack/vue-query"
import { organizationsBaseKey } from "./useOrganizationsQuery"

export function useOrganizationUsersUpdate() {
    const queryClient = useQueryClient()
    return useMutation<
        ApiOrganizationUserData,
        AxiosError<ApiError>,
        {
            organizationId: Organization["id"]
            userId: User["id"]
            payload: ApiUpdateOrganizationUserPayload
        }
    >(
        ({ organizationId, userId, payload }) =>
            useAxios().axios.put(
                `/api/organizations/${organizationId}/users/${userId}`,
                payload
            ),
        {
            onSettled() {
                queryClient.invalidateQueries([organizationsBaseKey])
            },
        }
    )
}
