import useAxios from "@/composables/useAxios"
import { useMutation, useQueryClient } from "@tanstack/vue-query"
import useNotification from "@/composables/useNotification"
import type { PaymentMethod } from "@/models/PaymentMethod"
import type { BillingModelId, BillingModelType } from "types/api"
import { QueryKey } from "../QueryKey"

export function usePaymentMethodUpdate() {
    const queryClient = useQueryClient()
    const notification = useNotification()

    return useMutation(
        (payload: {
            modelType: BillingModelType
            modelId: BillingModelId
            paymentMethodId: PaymentMethod["id"]
        }) =>
            useAxios().axios.put(
                `/api/${payload.modelType}/${payload.modelId}/billing/payment-methods/` +
                    payload.paymentMethodId
            ),
        {
            onSuccess() {
                notification.open({
                    type: "success",
                    title: "Payment method updated",
                    message:
                        "Your default payment method has been successfully updated.",
                })
                queryClient.invalidateQueries(
                    QueryKey.baseKey("payment-methods")
                )
            },
        }
    )
}
