import type { ApiGlobalId, ApiUserTokenData } from "types/api"
import { useDate } from "@/composables/useDate"

export class UserToken {
    id: ApiGlobalId
    abilities: string[]
    created_at: string
    last_used_at: string
    name: string
    tokenable_id: ApiGlobalId
    token?: string
    tokenable_type: string
    updated_at: string

    constructor(data: ApiUserTokenData) {
        this.id = data.id
        this.name = data.name
        this.token = data.token
        this.abilities = data.abilities
        this.created_at = data.created_at
        this.updated_at = data.updated_at
        this.last_used_at = data.last_used_at
        this.tokenable_id = data.tokenable_id
        this.tokenable_type = data.tokenable_type
    }

    get lastUsedAt() {
        if (!this.last_used_at) return null

        const { dayjs } = useDate()
        return dayjs(this.last_used_at).fromNow()
    }

    get createdAt() {
        if (!this.created_at) return null

        const { dayjs } = useDate()
        return dayjs(this.created_at).fromNow()
    }
}
