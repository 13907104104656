import printJS from "print-js"
import useAxios from "./useAxios"
import type PDF from "@/models/PDF"
import fileDownload from "js-file-download"
import type { Invoice } from "@/models/Invoice"
import type { Snapshot } from "@/models/Snapshot"
import type { BillingModelId, BillingModelType } from "types/api"

export function usePDFDownload() {
    const isDownloadingPDF = ref(false)
    const isDownloadingCertificate = ref(false)
    const { axios } = useAxios()

    function downloadPDF(id: PDF["id"]) {
        isDownloadingPDF.value = true
        axios
            .get(`/api/documents/pdfs/${id}/download`, {
                responseType: "blob",
            })
            .then((res) => {
                const reg = new RegExp(/(filename=)(?<name>[\w\W.\w\W]*)/)

                if (res.headers["content-disposition"]) {
                    const matches = res.headers["content-disposition"]
                        .replaceAll('"', "")
                        .match(reg)

                    if (matches?.groups)
                        fileDownload(res.data, matches.groups.name)
                }
            })
            .finally(() => (isDownloadingPDF.value = false))
    }

    function downloadCertificate(id: PDF["id"]) {
        isDownloadingCertificate.value = true
        axios
            .get(`/api/documents/pdfs/${id}/download-certificate`, {
                responseType: "blob",
            })
            .then((res) => {
                const reg = new RegExp(/(filename=)(?<name>[\w\W.\w\W]*)/)

                if (res.headers["content-disposition"]) {
                    const matches = res.headers["content-disposition"]
                        .replaceAll('"', "")
                        .match(reg)

                    if (matches?.groups)
                        fileDownload(res.data, matches.groups.name)
                }
            })
            .finally(() => (isDownloadingCertificate.value = false))
    }

    function viewPDF(url: string) {
        window.open(url, "_blank")
    }

    function printPDF(url: string) {
        printJS(url, "pdf")
    }

    return {
        downloadPDF,
        isDownloadingPDF,
        downloadCertificate,
        isDownloadingCertificate,
        viewPDF,
        printPDF,
    }
}

export function useURLOpen() {
    const isDownloadingUrl = ref(false)

    function openURL(url: string, name: string) {
        isDownloadingUrl.value = true
        const link = document.createElement("a")
        link.download = name
        link.href = url
        link.target = "_blank"
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        link.remove()
    }

    return { openURL, isDownloadingUrl }
}

export function useSnapshotDownload() {
    const isDownloadingSnapshot = ref(false)
    const { axios } = useAxios()

    function downloadSnapshot(pdfId: PDF["id"], snapshotId: Snapshot["id"]) {
        isDownloadingSnapshot.value = true
        axios
            .get(
                `/api/documents/pdfs/${pdfId}/snapshots/${snapshotId}/download`,
                {
                    responseType: "blob",
                }
            )
            .then((res) => {
                const reg = new RegExp(/(filename=)(?<name>[\w\W.\w\W]*)/)

                if (res.headers["content-disposition"]) {
                    const matches = res.headers["content-disposition"]
                        .replaceAll('"', "")
                        .match(reg)
                    if (matches?.groups)
                        fileDownload(res.data, matches.groups.name)
                }
            })
            .finally(() => (isDownloadingSnapshot.value = false))
    }
    return { downloadSnapshot, isDownloadingSnapshot }
}

export function useInvoiceDownload() {
    const isDownloadingInvoice = ref<null | Invoice["id"]>(null)
    const { axios } = useAxios()

    function downloadInvoice(
        modelType: BillingModelType,
        modelId: BillingModelId | undefined,
        id: Invoice["id"]
    ) {
        isDownloadingInvoice.value = id
        axios
            .get(
                `/api/${modelType}/${modelId}/billing/invoices/${id}/download`,
                {
                    responseType: "blob",
                }
            )
            .then((res) => {
                const reg = new RegExp(/(filename=)(?<name>[\w\W.\w\W]*)/)
                if (res.headers["content-disposition"]) {
                    const matches = res.headers["content-disposition"]
                        .replaceAll('"', "")
                        .match(reg)
                    if (matches?.groups)
                        fileDownload(res.data, matches.groups.name)
                }
            })
            .finally(() => (isDownloadingInvoice.value = null))
    }
    return { downloadInvoice, isDownloadingInvoice }
}
