<template>
    <div :key="orgId" class="flex flex-col space-y-8">
        <SectionLayout
            title="Create stub"
            description="Create a new stub to use within your documents."
        >
            <StubForm v-model:editing-stub="isEditingStub" />
        </SectionLayout>

        <SectionLayout
            title="Stubs"
            description="Manage your stubs here."
            is-table
        >
            <StubsTable @edit="handleEditStart" />
        </SectionLayout>
    </div>
</template>
<script lang="ts" setup>
import { useOrganizationStore } from "@/stores/organization"
import SectionLayout from "../layout/SectionLayout.vue"
import StubForm from "../stubs/StubForm.vue"
import StubsTable from "../stubs/StubsTable.vue"
import type Stub from "@/models/Stub"

const { orgId } = storeToRefs(useOrganizationStore())

const isEditingStub = ref<Stub | null>(null)

const handleEditStart = (stub: Stub) => {
    isEditingStub.value = stub
}
</script>
