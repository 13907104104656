<template>
    <div
        class="relative flex h-fit items-center rounded-lg border border-gray-300 bg-gray-200"
        data-testid="gf-toggle"
    >
        <o-button
            type="button"
            override
            data-testid="toggle-left"
            :disabled="disabled"
            class="rounded-lg p-1.5 leading-none text-gray-400 hover:text-gray-500"
            :class="[
                leftSelected && 'mr-0.5 shadow-sm',
                disabled && 'opacity-40 hover:text-gray-400',
            ]"
            @click="() => (selected = leftValue)"
        >
            <slot name="left">{{ left }}</slot>
        </o-button>

        <o-button
            override
            type="button"
            data-testid="toggle-right"
            :disabled="disabled"
            class="rounded-lg p-1.5 leading-none text-gray-400 hover:text-gray-500"
            :class="[
                rightSelected && 'ml-0.5 shadow-sm',
                disabled && 'opacity-40 hover:text-gray-400',
            ]"
            @click="() => (selected = rightValue)"
        >
            <slot name="right">{{ right }}</slot>
        </o-button>

        <div
            v-if="useSlider"
            class="pointer-events-none absolute h-full w-full rounded-lg"
        >
            <div class="relative h-full w-full">
                <div
                    ref="slider"
                    :class="[
                        'absolute w-fit whitespace-nowrap rounded-lg p-1.5 leading-none focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500',
                        rightSelected && 'right-slide right-0',
                        leftSelected && 'left-slide',
                        usePrimary && 'bg-primary text-primary-bw',
                        preload && 'preload',
                        disabled && 'opacity-40',
                    ]"
                >
                    <slot v-if="rightSelected">{{ right }}</slot>
                    <slot v-if="leftSelected">{{ left }}</slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
const props = defineProps({
    modelValue: {
        type: String,
        required: true,
    },
    left: {
        type: String,
        default: null,
    },
    right: {
        type: String,
        default: null,
    },
    leftValue: {
        type: String,
        required: true,
    },
    rightValue: {
        type: String,
        required: true,
    },
    usePrimary: {
        type: Boolean,
        default: false,
    },
    useSlider: {
        type: Boolean,
        default: true,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
})
const emit = defineEmits(["update:modelValue"])

const selected = computed({
    get() {
        return props.modelValue
    },
    set(selected) {
        if (selected !== props.modelValue) emit("update:modelValue", selected)
    },
})

const rightSelected = computed(() => selected.value === props.rightValue)
const leftSelected = computed(() => selected.value === props.leftValue)

const slider = ref<null | HTMLDivElement>(null)

const preload = ref(true)

onMounted(() => {
    setTimeout(() => {
        preload.value = false
    }, 250)
})
</script>
<style>
.left-slide {
    animation: 0.2s ease-out slideLeft;
}

@keyframes slideLeft {
    to {
        transform: translateX(0);
        left: 0;
    }
    from {
        left: 100%;
        transform: translateX(-100%);
    }
}

.right-slide {
    animation: 0.2s ease-out slideRight;
}

@keyframes slideRight {
    to {
        left: 100%;
        transform: translateX(-100%);
    }
    from {
        transform: translateX(0);
        left: 0;
    }
}

/* 
    Remove animation duration to prevent animation on page load
    https://stackoverflow.com/a/38296629/10044909
 */
.preload {
    animation-duration: 0s !important;
    -webkit-animation-duration: 0s !important;
    transition: background-color 0s, opacity 0s, color 0s, width 0s, height 0s,
        padding 0s, margin 0s !important;
}
</style>
