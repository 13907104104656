import type { ApiGlobalId, ApiSubscriptionPlanFeatureData } from "types/api"
import type { SubscriptionPlan } from "./SubscriptionPlan"

export class SubscriptionPlanFeature {
    id: ApiGlobalId
    subscription_plan_id: SubscriptionPlan["id"]
    order: number
    text: string

    constructor(data: ApiSubscriptionPlanFeatureData) {
        this.id = data.id
        this.subscription_plan_id = data.subscription_plan_id
        this.order = data.order
        this.text = data.text
    }
}
