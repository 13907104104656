<template>
    <div
        data-testid="user-signature-card"
        :class="[
            isDefault ? 'border-primary ring-2 ring-primary' : '',
            'group relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none',
        ]"
        @click="isDefault = !isDefault"
    >
        <CheckCircleIcon
            :class="[
                !isDefault ? 'invisible' : '',
                'absolute top-2 left-2 h-5 w-5 text-primary',
            ]"
            aria-hidden="true"
        />

        <GFButtonIcon
            data-testid="user-signature-card-delete"
            class="absolute top-2 right-2 z-20 hidden h-5 w-5 rounded-full hover:bg-gray-100 hover:text-red-500 group-hover:flex"
            icon="times"
            @click.stop="onDelete"
        />

        <span class="flex flex-1">
            <span class="flex flex-col items-center justify-center">
                <img class="m-4" :src="signature.content" />
            </span>
        </span>
    </div>
</template>
<script setup lang="ts">
import type { PropType } from "vue"
import type User from "@/models/User"
import GFButtonIcon from "./GFButtonIcon.vue"
import type UserInitial from "@/models/UserInitial"
import type UserSignature from "@/models/UserSignature"
import { CheckCircleIcon } from "@heroicons/vue/20/solid"

const emit = defineEmits<{
    (e: "update-default", id: UserSignature["id"], value: boolean): void
    (e: "delete", id: UserSignature["id"]): void
}>()

const props = defineProps({
    signature: {
        type: Object as PropType<UserSignature | UserInitial>,
        required: true,
    },
    userId: {
        type: String as PropType<User["id"]>,
        required: true,
    },
    type: {
        type: String as PropType<"signature" | "initial">,
        default: "signature",
    },
})

const isDefault = computed<boolean>({
    get() {
        return props.signature.is_default
    },
    set(value) {
        onDefault(value)
    },
})

function onDelete() {
    emit("delete", props.signature.id)
}

function onDefault(isDefault: boolean) {
    emit("update-default", props.signature.id, isDefault)
}
</script>
