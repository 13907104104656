<template>
    <div class="flex flex-col space-y-8">
        <SectionLayout
            title="Alert system"
            description="Manage Galaxy Forms alerts."
            is-table
        >
            <template #actions>
                <div class="flex items-center space-x-4">
                    <GFButton
                        v-if="alerts?.length"
                        label="Delete all"
                        danger
                        data-testid="delete-all-alerts-button"
                        :loading="isDeletingAllAlert"
                        @click="onDeleteAll"
                    />
                    <GFButton
                        label="Create alert"
                        data-testid="create-alert-button"
                        @click="onCreate"
                    />
                </div>
            </template>
            <AlertTable
                :alerts="alerts || []"
                :loading="loadingAlerts"
                @remove-alert="removeAlert"
            />
        </SectionLayout>

        <GFModalSimple
            v-model:show="creatingNewAlert"
            data-testid="create-alert-modal"
            @closed="onClosed"
        >
            <div class="flex flex-grow flex-col space-y-4">
                <GFInput v-model="newMessage" class="w-full" label="Message" />
                <GFSelect
                    v-model:current="newType"
                    :items="options"
                    data-testid="alert-type-select"
                    class="w-full"
                    label="Type"
                />
            </div>
            <template #buttons="{ close }">
                <div class="flex items-center space-x-2">
                    <GFButtonSimple label="Cancel" @click="close" />
                    <GFButton
                        label="Create"
                        :disabled="disableCreate"
                        @click="onCreateConfirm"
                    />
                </div>
            </template>
        </GFModalSimple>

        <GFModalSimple
            v-model:show="showDeleteAllModal"
            title="Delete all alerts?"
            data-testid="delete-all-alerts-modal"
        >
            <div class="flex flex-grow flex-col space-y-4">
                Are you sure you want to delete all alerts?
            </div>
            <template #buttons="{ close }">
                <div class="flex items-center space-x-2">
                    <GFButtonSimple label="Cancel" @click="close" />
                    <GFButton
                        label="Delete all"
                        danger
                        @click="onConfirmDeleteAll"
                    />
                </div>
            </template>
        </GFModalSimple>
    </div>
</template>
<script setup lang="ts">
import AlertTable from "./AlertTable.vue"
import GFInput from "../base/GFInput.vue"
import type { AlertType } from "types/api"
import type { Alert } from "@/models/Alert"
import GFButton from "../base/GFButton.vue"
import GFSelect from "../base/GFSelect.vue"
import GFModalSimple from "../base/GFModalSimple.vue"
import SectionLayout from "../layout/SectionLayout.vue"
import GFButtonSimple from "../base/GFButtonSimple.vue"
import { useAlertQuery } from "@/queries/alerts/useAlertQuery"
import { useAlertDelete } from "@/queries/alerts/useAlertDelete"
import { useAlertCreate } from "@/queries/alerts/useAlertCreate"
import { useAlertDeleteAll } from "@/queries/alerts/useAlertDeleteAll"

const { data: alerts, isLoading: loadingAlerts } = useAlertQuery()
const { mutate: deleteAlert } = useAlertDelete()
const { mutate: deleteAllAlerts, isLoading: isDeletingAllAlert } =
    useAlertDeleteAll()
const { mutate: createAlert } = useAlertCreate()

const newMessage = ref("")
const newType = ref<typeof options[number] | null>(null)

const showDeleteAllModal = ref(false)
const creatingNewAlert = ref(false)

const options: { label: string; value: AlertType }[] = [
    { label: "Success", value: "success" },
    { label: "Danger", value: "danger" },
    { label: "Warning", value: "warning" },
    { label: "Info", value: "info" },
]

const disableCreate = computed(() => !newMessage.value.length || !newType.value)

function removeAlert(alert: Alert) {
    deleteAlert(alert.id)
}

function onDeleteAll() {
    showDeleteAllModal.value = true
}

function reset() {
    newMessage.value = ""
    newType.value = null
}

function onClosed() {
    reset()
}

function onConfirmDeleteAll() {
    deleteAllAlerts()
    showDeleteAllModal.value = false
}

function onCreate() {
    reset()
    creatingNewAlert.value = true
}

function onCreateConfirm() {
    if (!newType.value) return

    createAlert(
        {
            message: newMessage.value,
            type: newType.value.value,
        },
        {
            onSuccess: () => {
                creatingNewAlert.value = false
            },
        }
    )
}
</script>
