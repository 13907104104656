import type { Ref } from "vue"
import { useQuery } from "@tanstack/vue-query"
import useAxios from "@/composables/useAxios"
import type Organization from "@/models/Organization"
import type { ApiOrganizationSettingsData } from "types/api"
import OrganizationSetting from "@/models/OrganizationSetting"

export const organizationSettingBaseKey = "organization-settings"

export function useOrganizationSettingsQuery(
    orgId: Ref<Organization["id"] | null>
) {
    return useQuery(
        [organizationSettingBaseKey, orgId],
        () =>
            useAxios()
                .axios.get<ApiOrganizationSettingsData>(
                    `/api/organizations/${orgId.value}/settings`
                )
                .then((resp) => new OrganizationSetting(resp.data)),
        {
            enabled: computed(() => !!orgId.value),
        }
    )
}
