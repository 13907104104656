<template>
    <div class="group relative flex items-start">
        <div class="flex h-5 w-4 items-center">
            <input
                v-if="!loading"
                :id="`input-checkbox-${name}`"
                ref="checkbox"
                v-model="inputValue"
                :name="name"
                :disabled="disabled"
                type="checkbox"
                class="h-4 w-4 rounded border-gray-300 text-primary"
                :class="{
                    'cursor-pointer focus:ring-primary group-hover:ring':
                        !disabled,
                    'border-gray-200': disabled,
                }"
            />
            <div
                v-if="loading"
                class="flex items-center justify-center rounded border-gray-300 text-primary focus:ring-primary"
            >
                <GFIcon
                    v-model="inputValue"
                    icon="spinner"
                    class="animate-spin"
                />
            </div>
        </div>
        <slot>
            <div v-if="label" class="pl-3 text-left text-sm">
                <label
                    :for="`input-checkbox-${name}`"
                    class="font-medium"
                    :class="{
                        ' cursor-pointer text-gray-700': !disabled,
                        'text-gray-400': disabled,
                    }"
                >
                    {{ label }}
                </label>
                <span v-if="labelDescription" class="text-gray-500">
                    {{ labelDescription }}
                </span>
            </div>
        </slot>
    </div>
</template>
<script lang="ts" setup>
import type { PropType } from "vue"
import GFIcon from "@/components/base/GFIcon.vue"

const emit = defineEmits(["update:modelValue", "input", "checked", "unchecked"])

const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false,
    },
    checked: {
        type: Boolean as PropType<boolean | null>,
        default: null,
    },
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        default: null,
    },
    labelDescription: {
        type: String,
        default: null,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    /**
     * Indeterminate values override the model value.
     * A model value is still required, even when using indeterminate.
     */
    indeterminate: {
        type: Boolean,
        default: false,
    },
})

const inputValue = computed({
    get(): boolean {
        return props.checked === null ? props.modelValue : props.checked
    },
    set(val: boolean) {
        emit("input", val)
        emit("update:modelValue", val)
        if (val) {
            emit("checked")
        } else {
            emit("unchecked")
        }
    },
})

const checkbox = ref<HTMLInputElement>()

watch(
    () => props.indeterminate,
    (val) => {
        if (!checkbox.value) return
        checkbox.value.indeterminate = val
    }
)

onMounted(() => {
    if (!checkbox.value) return
    checkbox.value.indeterminate = props.indeterminate
})
</script>
