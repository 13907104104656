<template>
    <GFInput
        v-model="buttonTextInput"
        class="w-full"
        show-optional
        placeholder="Set to default value"
    >
        <div class="flex items-center">
            <span class="mr-1">Button text</span>

            <GFInfo size="small">
                What text should be displayed on the button? The default value
                is "Get PLAN_NAME"
            </GFInfo>
        </div>
    </GFInput>

    <GFInput
        v-model="buttonUrlInput"
        show-optional
        class="w-full"
        placeholder="Set to default value"
    >
        <div class="flex items-center">
            <span class="mr-1">Button URL</span>
            <GFInfo size="small">
                <div class="flex flex-col space-y-4">
                    <div>
                        What URL should the user be redirected to when clicking
                        the button? URLs should start with 'https://',
                        'http://', or '/' (the link may not behave correctly
                        otherwise).
                    </div>

                    <div>
                        If left empty, the user will be redirected to the
                        '/sign-up' page of the web app. This is the default
                        behavior.
                    </div>

                    <div>
                        <div>
                            If the URL starts with '/', it will be appended to
                            the current domain.
                        </div>
                        <div>
                            Example: "/#contact" would take the user to the
                            contact section of the current page.
                        </div>
                    </div>
                </div>
            </GFInfo>
        </div>
    </GFInput>
</template>
<script setup lang="ts">
import type { PropType } from "vue"
import GFInput from "../base/GFInput.vue"
import GFInfo from "../base/GFInfo.vue"

const emit = defineEmits(["update:button-text", "update:button-url"])

const props = defineProps({
    buttonText: {
        type: String as PropType<string | null>,
        default: null,
    },
    buttonUrl: {
        type: String as PropType<string | null>,
        default: null,
    },
})

const buttonTextInput = computed({
    get() {
        return props.buttonText
    },
    set(value) {
        emit("update:button-text", value)
    },
})

const buttonUrlInput = computed({
    get() {
        return props.buttonUrl
    },
    set(value) {
        emit("update:button-url", value)
    },
})
</script>
