<template>
    <component :is="component" v-model="colors" :disable-alpha="true" />
</template>
<script lang="ts" setup>
import { Sketch, Compact, Chrome } from "@ckpack/vue-color"
import { computed, type PropType } from "vue"

const emit = defineEmits(["update:modelValue"])

const props = defineProps({
    modelValue: {
        type: String,
        required: true,
    },
    type: {
        type: String as PropType<"sketch" | "compact" | "chrome">,
        default: "sketch",
    },
})
const component = computed(() => {
    switch (props.type) {
        case "sketch":
            return Sketch
        case "compact":
            return Compact
        case "chrome":
            return Chrome
        default:
            return Sketch
    }
})

const colors = computed({
    get() {
        return props.modelValue
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    set(val: any) {
        emit("update:modelValue", val.hex)
    },
})
</script>
<style>
.vc-sketch-field--single {
    display: none;
}
.vc-sketch-field input.vc-input__input {
    text-align: center;
    padding-left: 0;
    width: 100%;
}

.vc-chrome-toggle-icon {
    display: flex;
    justify-content: flex-end;
}
</style>
