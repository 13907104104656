<template>
    <div class="">
        <div class="flex items-center justify-between bg-white">
            <div class="mr-2 flex-1 rounded bg-gray-100 p-3">
                <div id="card-element"></div>
            </div>
        </div>

        <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div class="sm:col-span-6">
                <GFInput
                    v-model="address1Input"
                    :form-errors="formErrors"
                    label="Street address"
                    name="address1"
                ></GFInput>
            </div>

            <div class="sm:col-span-2">
                <GFInput
                    v-model="cityInput"
                    :form-errors="formErrors"
                    label="City"
                    name="city"
                ></GFInput>
            </div>

            <div class="sm:col-span-2">
                <GFInput
                    v-model="stateInput"
                    :form-errors="formErrors"
                    label="State / Province"
                    name="state"
                ></GFInput>
            </div>

            <div class="sm:col-span-2">
                <GFInput
                    v-model="zipInput"
                    :form-errors="formErrors"
                    label="ZIP / Postal code"
                    name="zip"
                ></GFInput>
            </div>

            <div class="sm:col-span-3">
                <GFSelectNative
                    v-model="countryInput"
                    :options="countryOptions"
                    :form-errors="formErrors"
                    label="Country"
                    name="country"
                ></GFSelectNative>
            </div>

            <div class="sm:col-span-3">
                <GFInput
                    v-model="phoneInput"
                    type="tel"
                    show-optional
                    :form-errors="formErrors"
                    label="Phone"
                    name="phone"
                ></GFInput>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import type { PropType } from "vue"
import GFInput from "../base/GFInput.vue"
import type GFFormErrors from "@/helpers/GFFormErrors"
import GFSelectNative from "../base/GFSelectNative.vue"
import type { GFSelectOption } from "../base/GFSelect.vue"
import type { Stripe, StripeCardElement } from "@stripe/stripe-js"
import type { PaymentMethodIntent } from "@/models/PaymentMethodIntent"

const emit = defineEmits<{
    (event: "update:address1", value: string | null): void
    (event: "update:city", value: string | null): void
    (event: "update:state", value: string | null): void
    (event: "update:zip", value: string | null): void
    (event: "update:country", value: string | null): void
    (event: "update:phone", value: string | null): void
}>()

const props = defineProps({
    countryOptions: {
        type: Array as PropType<GFSelectOption[]>,
        required: true,
    },
    formErrors: {
        type: Object as PropType<GFFormErrors>,
        required: true,
    },
    address1: {
        type: String,
        default: null,
    },
    city: {
        type: String,
        default: null,
    },
    state: {
        type: String,
        default: null,
    },
    zip: {
        type: String,
        default: null,
    },
    country: {
        type: String,
        default: null,
    },
    phone: {
        type: String,
        default: null,
    },
    intent: {
        type: Object as PropType<PaymentMethodIntent>,
        required: true,
    },
    stripe: {
        type: Object as PropType<Stripe>,
        required: true,
    },
})

const cardElement = ref<StripeCardElement>()

const address1Input = computed({
    get: () => props.address1,
    set: (value) => emit("update:address1", value),
})

const cityInput = computed({
    get: () => props.city,
    set: (value) => emit("update:city", value),
})

const stateInput = computed({
    get: () => props.state,
    set: (value) => emit("update:state", value),
})

const zipInput = computed({
    get: () => props.zip,
    set: (value) => emit("update:zip", value),
})

const countryInput = computed({
    get: () => props.country,
    set: (value) => emit("update:country", value),
})

const phoneInput = computed({
    get: () => props.phone,
    set: (value) => emit("update:phone", value),
})

onMounted(() => {
    nextTick(() => {
        setUpStripeElement()
        focusOnCardInput()
    })
})

function setUpStripeElement() {
    cardElement.value = props.stripe.elements().create("card")
    cardElement.value.mount("#card-element")
}

function focusOnCardInput() {
    setTimeout(() => cardElement.value?.focus(), 200)
}

// /**
//  * Gets the payment methods content block ready for the user.
//  */
// async function boot() {
//     await loadIntent()
//     // await setupStripe()

//     // reset()
// }

// /**
//  * Sends the card information to Stripe to validate it.
//  */
// async function verifyCard() {
//     const validated = validateAddressFields()
//     if (!validated) return

//     isAddingCard.value = true

//     if (!cardElement.value) {
//         return
//     }

//     const clientSecret = intent.value.intent.client_secret
//     const data = {
//         payment_method: {
//             card: cardElement.value,
//             billing_details: {
//                 name: userStore?.user?.full_name,
//                 email: userStore?.user?.email,
//                 address: {
//                     city: city.value,
//                     country: country.value,
//                     line1: address1.value,
//                     postal_code: zip.value,
//                     state: state.value,
//                 },
//                 phone: phone.value,
//             },
//         },
//     }

//     const setupIntentResult = await stripe.value?.confirmCardSetup(
//         clientSecret,
//         data
//     )

//     const error = setupIntentResult?.error
//     const PaymentMethod = setupIntentResult?.setupIntent?.payment_method

//     error
//         ? notifyCouldNotAddCard(error.message ?? "")
//         : await addCard(PaymentMethod ?? "")

//     isAddingCard.value = false
// }

defineExpose({ cardElement })
</script>
