<template>
    <div>
        <SectionLayout
            title="Integrations"
            description="You can connect with any of these third-party providers to leverage their data and additional functionality."
            no-body-style
        >
            <div class="md:px-8">
                <ul role="list" class="divide-y divide-gray-200">
                    <li
                        v-for="(integration, i) in integrations"
                        :key="integration.name"
                        class="bg-white shadow"
                        :class="{
                            'md:rounded-md': integrations.length === 1,
                            'md:rounded-t-md': i === 0,
                            'md:rounded-b-md': i === integrations.length - 1,
                        }"
                    >
                        <div class="flex px-4 py-4 sm:px-6">
                            <div
                                class="flex min-w-0 flex-1 flex-col sm:flex-row sm:items-center"
                            >
                                <div
                                    class="flex min-w-0 flex-1 items-center space-x-4"
                                >
                                    <div class="flex-shrink-0">
                                        <component
                                            :is="integration.img"
                                            class="h-12 w-12 rounded-xl"
                                        />
                                    </div>
                                    <div class="w-full">
                                        <p
                                            class="truncate text-lg font-medium text-gray-700"
                                        >
                                            {{ integration.name }}
                                        </p>

                                        <div
                                            v-if="integration.integration"
                                            class="flex items-center space-x-2"
                                        >
                                            <GFIcon
                                                icon="check-circle"
                                                class="text-green-500"
                                            ></GFIcon>
                                            <div class="text-sm font-medium">
                                                Connected
                                                <span class="sm:hidden">
                                                    as
                                                    {{
                                                        integration.integration
                                                            .user_email
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                        <p
                                            v-else
                                            class="flex items-center text-sm text-gray-500"
                                        >
                                            {{ integration.description }}
                                        </p>
                                    </div>
                                </div>
                                <div
                                    v-if="integration.integration"
                                    class="mr-3 hidden sm:flex"
                                >
                                    <div class="flex flex-col text-sm">
                                        <span class="font-medium text-gray-700">
                                            {{
                                                integration.integration
                                                    .user_name
                                            }}
                                        </span>
                                        <span class="text-gray-500">
                                            {{
                                                integration.integration
                                                    .user_email
                                            }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="ml-1 flex items-center">
                                <GFDropdown v-if="integration.integration">
                                    <template #trigger>
                                        <GFButtonIcon
                                            class="h-8 w-8"
                                            icon="gear"
                                        ></GFButtonIcon>
                                    </template>
                                    <template #items>
                                        <GFDropdownItem>
                                            <button
                                                type="button"
                                                :class="[
                                                    'flex px-4 py-2 text-sm text-red-500',
                                                ]"
                                                @click="
                                                    () =>
                                                        integration.integration &&
                                                        removeIntegration(
                                                            integration.integration
                                                        )
                                                "
                                            >
                                                Revoke
                                            </button>
                                        </GFDropdownItem>
                                    </template>
                                </GFDropdown>
                                <GFButtonSimple
                                    v-if="!integration.integration"
                                    :loading="!!authPopup"
                                    @click="integrateWith(integration.provider)"
                                >
                                    Connect
                                </GFButtonSimple>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </SectionLayout>
    </div>
</template>
<script lang="ts" setup>
import GFIcon from "../base/GFIcon.vue"
import useAxios from "@/composables/useAxios"
import GFDropdown from "../base/GFDropdown.vue"
import type { ComponentPublicInstance } from "vue"
import type Integration from "@/models/Integration"
import GFButtonIcon from "../base/GFButtonIcon.vue"
import type { IntegrationProvider } from "types/api"
import SectionLayout from "../layout/SectionLayout.vue"
import GFDropdownItem from "../base/GFDropdownItem.vue"
import GFButtonSimple from "../base/GFButtonSimple.vue"
import useNotification from "@/composables/useNotification"
import CompanyCamLogoSVG from "@/components/svg/CompanyCamLogoSVG.vue"
import { useIntegrations } from "@/queries/integrations/useIntegrations"
import { useIntegrationDelete } from "@/queries/integrations/useIntegrationDelete"

const integrations = ref<
    {
        name: string
        img: ComponentPublicInstance
        provider: IntegrationProvider
        description: string
        integration?: Integration
    }[]
>([])

const { data, refetch } = useIntegrations()
const { mutate: deleteIntegration } = useIntegrationDelete()

watch(data, (newData) => {
    if (newData) {
        integrations.value = integrations.value.map((localIntegration) => {
            const integration = newData.find(
                (integration) =>
                    integration.provider === localIntegration.provider
            )
            return { ...localIntegration, integration }
        })
    }
})

const provider = ref<IntegrationProvider | null>(null)
const authPopup = ref<Window | null>(null)

onMounted(() => {
    /**
     * Each integration should have base data.
     * This base data gets injected with the integration data if an integration exists.
     */
    integrations.value = [
        {
            name: "CompanyCam",
            img: markRaw(
                CompanyCamLogoSVG as ComponentPublicInstance<
                    typeof CompanyCamLogoSVG
                >
            ),
            provider: "companycam",
            description: "Connect your CompanyCam account to Galaxy Forms.",
        },
    ]
})

/**
 * Start the integration process with the given provider.
 */
async function integrateWith(thisProvider: IntegrationProvider) {
    provider.value = thisProvider
    authPopup.value = await openAuthPopup(thisProvider)

    checkPopup()

    if (authPopup.value === null) {
        useNotification().open({
            type: "error",
            title: "Error",
            message:
                "Please allow popups at the top of your browser to continue with the integration.",
        })
    }
}

/**
 * Opens up an OAuth flow popup window for the given provider.
 */
async function openAuthPopup(provider: IntegrationProvider) {
    const response = await getOAuthRedirect(provider)
    const url = response.data.redirect
    const width = 600,
        height = 800
    const left = window.innerWidth / 2 - width / 2
    const top = window.innerHeight / 2 - height / 2

    return window.open(
        url,
        "",
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`
    )
}

/**
 * Gets the redirect URL to use in the popup to authenticate with the given provider / service.
 */
async function getOAuthRedirect(provider: IntegrationProvider) {
    return await useAxios().axios.get<any, { data: { redirect: string } }>(
        `/api/integrations/${provider}/redirect`
    )
}

/**
 * Periodically checks the state of the popup for being closed.
 */
function checkPopup() {
    const check = setInterval(() => {
        if (
            !authPopup.value ||
            authPopup.value.closed ||
            authPopup.value.closed === undefined
        ) {
            clearInterval(check)
            authPopup.value = null
            provider.value = null
            loadIntegrations()
        }
    }, 750)
}

async function loadIntegrations() {
    refetch()
}

async function removeIntegration(integration: Integration) {
    deleteIntegration(
        { integrationId: integration.id },
        {
            onSuccess() {
                useNotification().open({
                    type: "success",
                    title: "Success",
                    message: "Integration successfully removed.",
                })
            },
        }
    )
}
</script>
