<template>
    <GFTable
        :data="invoices"
        empty-message="No invoices yet"
        :cols="[['readableCreatedAt', 'Date'], ['status', 'Status'], 'actions']"
        :init-loading="invoicesLoading"
    >
        <template #status="{ fieldData }">
            <GFTag v-if="fieldData === 'paid'" color="green" label="Paid" />
            <GFTag v-if="fieldData === 'open'" color="yellow" label="Unpaid" />
            <GFTag v-if="fieldData === 'draft'" color="gray" label="Void" />
        </template>
        <template #actions="{ row: invoice }">
            <GFButtonText
                size="sm"
                :loading="isDownloadingInvoice === invoice.id"
                label="Download"
                @click="onDownload(invoice as Invoice)"
            />
        </template>
    </GFTable>
</template>
<script setup lang="ts">
import GFTag from "../base/GFTag.vue"
import GFTable from "../base/GFTable.vue"
import { useUserStore } from "@/stores/user"
import type { Invoice } from "@/models/Invoice"
import GFButtonText from "../base/GFButtonText.vue"
import { useOrganizationStore } from "@/stores/organization"
import { useInvoiceDownload } from "@/composables/useDownload"
import { useInvoicesQuery } from "@/queries/invoices/useInvoicesQuery"

const { orgId } = storeToRefs(useOrganizationStore())
const { user } = storeToRefs(useUserStore())
const isOrg = computed(() => orgId.value !== "private")
const modelId = computed(() => (isOrg.value ? orgId.value : user.value?.id))
const modelType = computed(() => (isOrg.value ? "organizations" : "users"))

const { downloadInvoice, isDownloadingInvoice } = useInvoiceDownload()
const { data: invoices, isLoading: invoicesLoading } = useInvoicesQuery(
    modelType,
    modelId
)

function onDownload(invoice: Invoice) {
    downloadInvoice(modelType.value, modelId.value, invoice.id)
}
</script>
