<template>
    <GFModalSimple
        :title="previewData?.title"
        size="large"
        :show="isOpen"
        @closed="reset"
    >
        <div
            class="flex max-h-96 w-full flex-col space-y-1 overflow-y-auto overflow-x-hidden px-2 md:max-h-full"
        >
            <dl class="mt-5 space-y-6 text-sm font-medium text-gray-500">
                <div class="flex justify-between">
                    <dt>Plan</dt>

                    <dd class="text-gray-900">
                        <GFSpinner v-if="previewIsLoading" class="h-4 w-4" />
                        <span v-if="!previewIsLoading">
                            {{ previewData?.plan_name }}
                        </span>
                    </dd>
                </div>

                <div
                    v-for="(lineItem, i) in previewData?.line_items ?? []"
                    :key="i"
                    class="flex justify-between"
                >
                    <dt>{{ lineItem.description }}</dt>

                    <dd class="text-gray-900">
                        <GFSpinner v-if="previewIsLoading" class="h-4 w-4" />
                        <span v-if="!previewIsLoading">
                            {{ lineItem.amount }}
                        </span>
                    </dd>
                </div>

                <dl class="space-y-6 border-t border-gray-200 py-6">
                    <div class="flex items-center justify-between">
                        <dt class="text-sm">Subtotal</dt>

                        <dd class="text-sm font-medium text-gray-900">
                            <GFSpinner
                                v-if="previewIsLoading"
                                class="h-4 w-4"
                            />
                            <div
                                v-if="!previewIsLoading"
                                class="flex flex-col text-end"
                            >
                                <span>{{ previewData?.subtotal }}</span>
                            </div>
                        </dd>
                    </div>

                    <div class="flex items-center justify-between">
                        <dt class="text-sm">Tax</dt>

                        <dd class="text-sm font-medium text-gray-900">
                            <GFSpinner
                                v-if="previewIsLoading"
                                class="h-4 w-4"
                            />
                            <div
                                v-if="!previewIsLoading"
                                class="flex flex-col text-end"
                            >
                                <span>{{ previewData?.tax }}</span>
                            </div>
                        </dd>
                    </div>

                    <div v-if="showPromoButton" class="flex justify-between">
                        <dt class="flex">
                            <GFButtonText
                                label="Use promo code"
                                size="sm"
                                button-style="underline"
                                @click="showPromoInput = true"
                            />
                        </dt>
                    </div>

                    <div
                        v-if="showPromoInput && !promoCodeData"
                        class="flex max-w-xs flex-grow flex-col pl-0.5"
                    >
                        <label class="mb-1 text-sm font-medium text-gray-700">
                            Promo code
                        </label>

                        <div class="flex h-fit space-x-2">
                            <template v-if="!promoCodeData">
                                <GFInput
                                    v-model="promoCode"
                                    autofocus
                                    name="promoCode"
                                    :form-errors="formErrors"
                                    class="w-full"
                                    @enter="findPromoCode"
                                />

                                <GFButtonSimple
                                    label="Apply"
                                    class="h-fit"
                                    :loading="promoCodeLoading"
                                    @click="findPromoCode"
                                />
                            </template>
                        </div>
                    </div>

                    <div v-if="promoCodeData" class="flex justify-between">
                        <dt class="flex items-center">
                            Promo code
                            <GFTag
                                color="green"
                                class="ml-2 flex items-center space-x-2"
                            >
                                <span>{{ promoCodeData.code }} </span>
                                <GFButtonIcon
                                    icon="times-circle"
                                    class="bg-green-200 text-green-900 hover:text-red-500"
                                    @click="removePromoCode"
                                />
                            </GFTag>
                        </dt>
                    </div>

                    <div
                        class="flex flex-col space-y-3 border-t border-gray-200 pt-6 text-base font-medium text-gray-900"
                    >
                        <div
                            v-if="previewData?.is_starting_trial"
                            class="flex w-full items-center justify-between text-red-500"
                        >
                            <dt>14 DAY FREE TRIAL</dt>
                            <dd>
                                <GFSpinner
                                    v-if="previewIsLoading"
                                    class="h-4 w-4"
                                />
                                <div
                                    v-if="!previewIsLoading"
                                    class="flex flex-col text-end"
                                >
                                    <span>$0.00</span>
                                </div>
                            </dd>
                        </div>

                        <div
                            v-if="previewData"
                            class="flex w-full items-center justify-between"
                        >
                            <dt>Total due {{ previewData?.trial_ends_at }}</dt>

                            <dd class="text-gray-900">
                                <GFSpinner
                                    v-if="previewIsLoading"
                                    class="h-4 w-4"
                                />

                                <div
                                    v-if="!previewIsLoading"
                                    class="flex flex-col text-end"
                                >
                                    <span>{{ previewData?.total }}</span>

                                    <span class="text-xs text-gray-500">
                                        billed {{ previewData?.interval }}
                                    </span>
                                </div>
                            </dd>
                        </div>
                    </div>
                </dl>
            </dl>

            <div
                v-if="previewData"
                class="flex flex-col space-y-3 text-sm font-medium"
            >
                <div class="font-medium text-gray-900">
                    {{ previewData?.charge_description }}
                </div>

                <div class="text-gray-500">
                    {{ previewData?.interval_description }}
                </div>
            </div>
        </div>

        <template #buttons>
            <div class="flex w-full justify-end space-x-2">
                <GFButtonSimple
                    label="Cancel"
                    outline
                    @click="$emit('cancel')"
                />

                <GFButton
                    label="Confirm"
                    :loading="confirmIsLoading"
                    @click="onConfirm"
                />
            </div>
        </template>
    </GFModalSimple>
</template>
<script setup lang="ts">
import type {
    BillingModelType,
    SubscriptionPlanPriceInterval,
    ApiModelSubscriptionPreviewData,
} from "types/api"
import type { PropType } from "vue"
import GFTag from "../base/GFTag.vue"
import type User from "@/models/User"
import GFInput from "../base/GFInput.vue"
import GFButton from "../base/GFButton.vue"
import GFSpinner from "../base/GFSpinner.vue"
import GFFormErrors from "@/helpers/GFFormErrors"
import GFButtonIcon from "../base/GFButtonIcon.vue"
import GFButtonText from "../base/GFButtonText.vue"
import GFModalSimple from "../base/GFModalSimple.vue"
import type Organization from "@/models/Organization"
import GFButtonSimple from "../base/GFButtonSimple.vue"
import type { PaymentMethod } from "@/models/PaymentMethod"
import type { SubscriptionPlan } from "@/models/SubscriptionPlan"
import { usePromoCodeFindQuery } from "@/queries/promo-codes/usePromoCodeFindQuery"
import { useModelSubscriptionPreview } from "@/queries/subscription/useModelSubscriptionPreview"

export type ConfirmModalStatus = "new" | "update" | "resume"

const emit = defineEmits<{
    (e: "cancel"): void
    (e: "confirm", promoCode: string | null): void
    (e: "swap", promoCode: string | null): void
    (e: "update:show", open: boolean): void
}>()

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    plan: {
        type: Object as PropType<SubscriptionPlan | null>,
        default: null,
    },
    modelType: {
        type: String as PropType<BillingModelType>,
        required: true,
    },
    modelId: {
        type: String as PropType<User["id"] | Organization["id"]>,
        required: true,
    },
    interval: {
        type: String as PropType<SubscriptionPlanPriceInterval>,
        required: true,
    },
    userQuantity: {
        type: Number,
        required: true,
    },
    defaultPaymentMethod: {
        type: Object as PropType<PaymentMethod | null>,
        default: null,
    },
    confirmIsLoading: {
        type: Boolean,
        default: false,
    },
    status: {
        type: String as PropType<ConfirmModalStatus>,
        required: true,
    },
})

const isOpen = computed({
    get() {
        return props.show
    },
    set(val) {
        emit("update:show", val)
    },
})

const previewData = ref<ApiModelSubscriptionPreviewData | null>(null)
const formErrors = ref<GFFormErrors | null>(null)
const showPromoInput = ref(false)
const promoCode = ref("")
const promoCodeSearchable = ref<null | string>(null)

const {
    data: promoCodeData,
    isInitialLoading: promoCodeLoading,
    isError,
} = usePromoCodeFindQuery(
    computed(() => props.modelType),
    computed(() => props.modelId),
    promoCodeSearchable
)

const { mutateAsync: getPreviewMutation, isLoading: previewIsLoading } =
    useModelSubscriptionPreview()

const errorMessage = "Invalid promo code"

watch(isError, (val) => {
    if (val) {
        formErrors.value = new GFFormErrors({
            message: errorMessage,
            errors: { promoCode: [errorMessage] },
        })
    } else formErrors.value = null
})

watch([isOpen, promoCodeSearchable], ([open]) => {
    if (open) getPreview()
})

const showPromoButton = computed(
    () =>
        !showPromoInput.value &&
        previewData.value &&
        !previewData.value.is_subscribed
)

function reset() {
    promoCode.value = ""
    promoCodeSearchable.value = null
    showPromoInput.value = false
    formErrors.value = null
}

function findPromoCode() {
    if (promoCode.value) {
        promoCodeSearchable.value = promoCode.value
    }
}

function removePromoCode() {
    promoCodeSearchable.value = null
}

async function getPreview() {
    if (!props.plan) return

    previewData.value = await getPreviewMutation({
        modelId: props.modelId,
        modelType: props.modelType,
        payload: {
            subscription_plan_id: props.plan.id,
            interval: props.interval,
            users: props.userQuantity,
            promo_code: promoCodeSearchable.value ?? undefined,
        },
    })
}

function onConfirm() {
    const promoCodeValue = formErrors.value ? null : promoCodeSearchable.value

    if (props.status === "new") emit("confirm", promoCodeValue)
    if (props.status === "update") emit("swap", promoCodeValue)
}
</script>
