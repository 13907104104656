import type User from "@/models/User"
import useAxios from "@/composables/useAxios"
import type Organization from "@/models/Organization"
import { useMutation, useQueryClient } from "@tanstack/vue-query"
import useNotification from "@/composables/useNotification"
import type { ModelSubscription } from "@/models/ModelSubscription"
import { QueryKey } from "../QueryKey"

export function useModelSubscriptionCancel() {
    const queryClient = useQueryClient()

    return useMutation(
        (payload: {
            modelType: ModelSubscription["modelType"]
            modelId: User["id"] | Organization["id"]
        }) =>
            useAxios().axios.delete(
                `/api/${payload.modelType}/${payload.modelId}/billing/subscription`
            ),
        {
            onSuccess() {
                queryClient.invalidateQueries(
                    QueryKey.baseKey("model-subscriptions")
                )
                queryClient.invalidateQueries(QueryKey.baseKey("invoices"))

                useNotification().open({
                    type: "success",
                    title: "Subscription cancelled",
                    message:
                        "Your subscription has been cancelled. You can still use the app until your current billing period ends.",
                })
            },
        }
    )
}
