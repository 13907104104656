import { useUserStore } from "@/stores/user"
import { useOrganizationStore } from "@/stores/organization"
import { useOrganizationUser } from "@/queries/organizations/useOrganizationUser"
/**
 * Handles the authenticated users organization-user data.
 */
export function useOrganizationAuthUser() {
    const organizationStore = useOrganizationStore()
    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)
    const { orgId } = storeToRefs(organizationStore)

    const { userRole, userIsAdmin, userIsSignee, userIsStandard, isLoading } =
        useOrganizationUser(
            orgId,
            computed(() => user.value?.id)
        )

    const userRoleDisplay = computed(() => {
        if (userRole.value)
            return (
                userRole.value.charAt(0).toUpperCase() + userRole.value.slice(1)
            )
    })

    return {
        userRoleDisplay,
        userIsAdmin,
        userRole,
        isLoading,
        userIsSignee,
        userIsStandard,
    }
}
