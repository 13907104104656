import { useOrganizationStore } from "@/stores/organization"
import type { OrganizationUserRole } from "types/api"
import type { RouteLocationNormalizedLoaded, Router } from "vue-router"

export function afterOrganizationChange(
    _role: OrganizationUserRole | undefined,
    _previous: OrganizationUserRole | undefined,
    _ctx: {
        router: Router
        route: RouteLocationNormalizedLoaded
        isInitial: boolean
    }
) {
    useOrganizationStore().setOrgColors()
}
