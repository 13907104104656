import useAxios from "@/composables/useAxios"
import { useQuery } from "@tanstack/vue-query"
import type { ApiAlertData } from "types/api"
import { Alert } from "@/models/Alert"

export const alertBaseKey = "alert"

export function useAlertQuery() {
    return useQuery(
        [alertBaseKey],
        () =>
            useAxios()
                .axios.get<ApiAlertData[]>(`/api/system/alerts`)
                .then((resp) => resp.data),
        {
            select: (data) => data.map((alert) => new Alert(alert)),
        }
    )
}
