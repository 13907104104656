<template>
    <GFModalSimple
        v-model:show="showInput"
        title="Add new payment method"
        size="large"
        @closed="reset"
    >
        <div>
            <CreateNewPaymentMethodForm
                v-if="intent && stripe"
                ref="createNewPaymentMethodForm"
                v-model:address1="address1"
                v-model:city="city"
                v-model:state="state"
                v-model:zip="zip"
                v-model:country="country"
                v-model:phone="phone"
                :intent="intent"
                :stripe="stripe"
                :country-options="countryOptions"
                :form-errors="formErrors"
            />
        </div>
        <template #buttons>
            <div class="flex items-center space-x-2">
                <GFButtonSimple label="Cancel" @click="close" />

                <GFButton
                    label="Save"
                    :loading="isAddingCard"
                    @click="onSave"
                />
            </div>
        </template>
    </GFModalSimple>
</template>

<script setup lang="ts">
import { isEmpty } from "lodash"
import type { ApiError } from "types/api"
import GFButton from "../base/GFButton.vue"
import { useUserStore } from "@/stores/user"
import GFFormErrors from "@/helpers/GFFormErrors"
import GFModalSimple from "../base/GFModalSimple.vue"
import GFButtonSimple from "../base/GFButtonSimple.vue"
import type { GFSelectOption } from "../base/GFSelect.vue"
import { loadStripe, type Stripe } from "@stripe/stripe-js"
import useNotification from "@/composables/useNotification"
import { useOrganizationStore } from "@/stores/organization"
import type { PaymentMethodIntent } from "@/models/PaymentMethodIntent"
import CreateNewPaymentMethodForm from "../settings/CreateNewPaymentMethodForm.vue"
import { usePaymentMethodCreate } from "@/queries/payment-methods/usePaymentMethodCreate"
import { usePaymentMethodIntentQuery } from "@/queries/payment-methods/usePaymentMethodIntentQuery"

const emit = defineEmits<{
    (event: "update:show", show: boolean): void
    (event: "payment-method-added"): void
    (event: "closed"): void
}>()

const props = defineProps({
    show: {
        type: Boolean,
        required: true,
    },
})

const notification = useNotification()
const userStore = useUserStore()
const { orgId } = storeToRefs(useOrganizationStore())
const isOrg = computed(() => orgId.value !== "private")

const modelId = computed(() => (isOrg.value ? orgId.value : userStore.user?.id))
const modelType = computed(() => (isOrg.value ? "organizations" : "users"))

const createNewPaymentMethodForm =
    ref<InstanceType<typeof CreateNewPaymentMethodForm>>()

const showInput = computed({
    get: () => props.show,
    set: (value) => emit("update:show", value),
})
const isAddingCard = ref(false)
const formErrors = ref(new GFFormErrors())

const countryOptions = ref<GFSelectOption[]>([
    { label: "United States", value: "US" },
    { label: "Canada", value: "CA" },
    { label: "Mexico", value: "MX" },
])

const address1 = ref("")
const city = ref("")
const state = ref("")
const zip = ref("")
const country = ref(countryOptions.value[0].value)
const phone = ref("")

const stripe = ref<Stripe | null>(null)

const { data: intent, refetch: getNewIntent } = usePaymentMethodIntentQuery(
    modelType,
    modelId
)

const { mutateAsync: addCard } = usePaymentMethodCreate()

watch(intent, async (newIntent) => {
    if (newIntent) {
        await setupStripe(newIntent)
    }
})

async function onSave() {
    await verifyCard()
}

/**
 * Set up our Stripe card input component in order to collect credit card
 * info.
 */
async function setupStripe(intent: PaymentMethodIntent) {
    stripe.value = await loadStripe(intent.stripe_public_key)
}

/**
 * Sends the card information to Stripe to validate it.
 */
async function verifyCard() {
    const validated = validateAddressFields()
    if (!validated) return

    isAddingCard.value = true

    if (!createNewPaymentMethodForm.value?.cardElement) return
    if (!intent.value) return

    const clientSecret = intent.value.client_secret
    const data = {
        payment_method: {
            card: createNewPaymentMethodForm.value?.cardElement,
            billing_details: {
                name: userStore?.user?.full_name,
                email: userStore?.user?.email,
                address: {
                    city: city.value,
                    country: country.value,
                    line1: address1.value,
                    postal_code: zip.value,
                    state: state.value,
                },
                phone: phone.value,
            },
        },
    }

    const setupIntentResult = await stripe.value?.confirmCardSetup(
        clientSecret,
        data
    )

    const error = setupIntentResult?.error
    const userPaymentMethod = setupIntentResult?.setupIntent?.payment_method

    if (error) {
        close()
        isAddingCard.value = false

        await nextTick()
        notifyCouldNotAddCard(error.message ?? "")

        return
    }

    if (!modelId.value) return

    await addCard(
        {
            modelId: modelId.value,
            modelType: modelType.value,
            payload: {
                payment_method: userPaymentMethod ?? "",
            },
        },
        {
            onSettled() {
                getNewIntent()
                isAddingCard.value = false
                close()
            },
            onSuccess() {
                emit("payment-method-added")
            },
        }
    )
}

function validateAddressFields() {
    // If some field is blank
    if (
        [
            address1.value,
            zip.value,
            city.value,
            state.value,
            country.value,
        ].some((v) => isEmpty(v))
    ) {
        // get blank fields
        const blankFieldNames = [
            ["address1", address1.value],
            ["zip", zip.value],
            ["city", city.value],
            ["state", state.value],
            ["country", country.value],
        ]
            .filter((v) => isEmpty(v[1]))
            .map((v) => v[0])

        // set errors
        const errors: Record<string, string[]> = {}

        for (const key of blankFieldNames) {
            errors[key] = ["This field is required."]
        }

        const error: ApiError = {
            message: "Please fill out all address fields.",
            errors,
        }

        formErrors.value = new GFFormErrors(error)
        return false
    }
    // clear errors
    formErrors.value = new GFFormErrors()
    return true
}

/**
 * Notifies the user that there was an error with their card.
 */
function notifyCouldNotAddCard(message: string) {
    notification.open({
        type: "danger",
        title: "Whoops!",
        message: message,
    })
}

/**
 * Resets the form.
 */
function reset() {
    createNewPaymentMethodForm.value?.cardElement?.clear()
    isAddingCard.value = false

    address1.value = ""
    city.value = ""
    state.value = ""
    zip.value = ""
    phone.value = ""
    country.value = countryOptions.value[0].value

    // clear errors
    formErrors.value = new GFFormErrors()

    emit("closed")
}

/**
 * Closes the modal.
 */
function close() {
    showInput.value = false
}
</script>
