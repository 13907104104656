import { usePrivileges } from "@/composables/usePrivileges"
import type Organization from "@/models/Organization"
import { useUserStore } from "@/stores/user"
import type { RouteLocationNormalizedLoaded, Router } from "vue-router"

export async function beforeOrganizationChange(
    /**
     * The new organization to change to.
     * If null, the organization is the users private organization.
     * Should never be undefined.
     */
    organization: Organization | null,

    /**
     * The previous organization the user is changing from.
     * If null, the organization is the users private organization.
     * Could be undefined if the app is loading and there is no previous organization.
     */
    _previousOrganization: Organization | null | undefined,

    ctx: {
        router: Router
        route: RouteLocationNormalizedLoaded
        isInitial: boolean
    }
) {
    const { handleAuthorizedRoutes } = usePrivileges()
    const userStore = useUserStore()

    /** The new role the user is assuming by changing to this organization. */
    const newRole = organization?.authUserRole ?? "admin"

    await handleAuthorizedRoutes(newRole, "/", ctx)

    userStore.setUserRole(newRole ?? "admin")
}
