import type {
    ApiError,
    ApiPaginatedGETResponse,
    ApiOrganizationUserData,
} from "types/api"
import type { Ref } from "vue"
import { useInfiniteQuery, useQuery } from "@tanstack/vue-query"
import type { AxiosError } from "axios"
import useAxios from "@/composables/useAxios"
import type Organization from "@/models/Organization"
import OrganizationUser from "@/models/OrganizationUser"
import { organizationsBaseKey } from "./useOrganizationsQuery"

export function useOrganizationUsers(
    orgId: Ref<Organization["id"] | "private">
) {
    const isPrivateOrg = computed(() => orgId.value === "private")

    const query = useQuery<
        ApiPaginatedGETResponse<ApiOrganizationUserData>,
        AxiosError<ApiError>,
        OrganizationUser[]
    >(
        [organizationsBaseKey, orgId],
        () =>
            useAxios()
                .axios.get(`/api/organizations/${orgId.value}/users`, {
                    params: {
                        append: "user_avatar_base64",
                        include: "user",
                    },
                })
                .then((resp) => resp.data),
        {
            select: (data) =>
                data.data.map((apiData) => new OrganizationUser(apiData)),
            enabled: computed(() => !isPrivateOrg.value),
        }
    )

    return { ...query, isPrivateOrg }
}

export function useOrganizationUsersInfiniteQuery(
    orgId: Ref<Organization["id"] | null>,
    config?: { enabled: Ref<boolean> }
) {
    const { axios } = useAxios()

    const total = ref<number | undefined>(undefined)

    const query = useInfiniteQuery(
        [organizationsBaseKey, orgId],
        ({ pageParam = 1 }) => {
            return axios
                .get<ApiPaginatedGETResponse<ApiOrganizationUserData>>(
                    `/api/organizations/${orgId.value}/users`,
                    {
                        params: {
                            "page[number]": pageParam,
                            "page[size]": 100,
                            append: "user_avatar_base64",
                            include: "user",
                        },
                    }
                )
                .then((resp) => {
                    total.value = resp.data.total
                    return resp.data
                })
        },
        {
            enabled: computed(() => config?.enabled.value ?? true),

            select({ pageParams, pages }) {
                total.value = pages[0]?.total
                const transformedPages = pages.map((page) => {
                    return page.data.map(
                        (userData) => new OrganizationUser(userData)
                    )
                })
                return { pages: transformedPages, pageParams }
            },

            getNextPageParam: (lastPage, _pages) =>
                lastPage.next_page_url ? lastPage.current_page + 1 : undefined,

            // staleTime: configFile.staleTime.long,
        }
    )

    const users = computed(() => query.data.value?.pages.flat())

    return { ...query, users, total }
}

export function useOrganizationUsersAllQuery(
    orgId: Ref<Organization["id"] | null>,
    config?: { enabled: Ref<boolean> }
) {
    const isLoadingLocal = ref(true)
    const results = ref<OrganizationUser[]>([])

    const { users, hasNextPage, fetchNextPage, isRefetching, total } =
        useOrganizationUsersInfiniteQuery(orgId, config)

    watch(
        [hasNextPage, computed(() => users.value?.length), isRefetching],
        () => {
            if (hasNextPage?.value && !isRefetching?.value) {
                fetchNextPage()
            }
            if (!hasNextPage?.value && !isRefetching?.value) {
                isLoadingLocal.value = false
                results.value = users.value ?? []
            }
        }
    )

    return {
        data: results,
        isLoading: isLoadingLocal,
        isRefetching,
        total,
    }
}
