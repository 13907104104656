import {
    createRouter,
    createWebHistory,
    type RouteRecordRaw,
    type NavigationGuardNext,
    type RouteLocationNormalized,
} from "vue-router"
import type User from "@/models/User"
import useAuth from "@/composables/useAuth"
import { useUserStore } from "@/stores/user"
import { useDocumentStore } from "@/stores/documents"
import type { RouteMetaAuthorizedRoles, PrivilegedRole } from "types/types"
import SettingsStubsView from "@/components/settings/SettingsStubsView.vue"
import SettingsAccountView from "@/components/settings/SettingsAccountView.vue"
import SettingsBillingView from "@/components/settings/SettingsBillingView.vue"
import SettingsApiTokensView from "@/components/settings/SettingsApiTokensView.vue"
import BackOfficeAlertsView from "@/components/back-office/BackOfficeAlertsView.vue"
import SettingsSignaturesView from "@/components/settings/SettingsSignaturesView.vue"
import SettingsIntegrationsView from "@/components/settings/SettingsIntegrationsView.vue"
import SettingsOrganizationView from "@/components/settings/organizations/SettingsOrganizationView.vue"
import BackOfficeSubscriptionPlansView from "@/components/back-office/BackOfficeSubscriptionPlansView.vue"

/**
 * Just a helper function to provide type safety,
 */
const authorizedRoles = (
    privilegedRole: PrivilegedRole
): RouteMetaAuthorizedRoles => {
    return { authorizedRoles: privilegedRole }
}

/**
 * Application routes
 *
 * All public and private application routes are defined here.
 * Each route should define a meta.auth boolean field which
 * indicates if the route requires an authenticated user or not.
 */
const routes: RouteRecordRaw[] = [
    {
        path: "/signing-complete",
        component: () => import("../views/PostSigningLandingView.vue"),
        meta: { auth: true },
    },
    {
        path: "/login",
        component: () => import("../views/LoginView.vue"),
        meta: { auth: false },
    },
    {
        path: "/auth/:provider/callback",
        component: () => import("../views/AuthProviderCallbackView.vue"),
        meta: { auth: false },
    },
    {
        path: "/sign-up",
        component: () => import("../views/SignUpView.vue"),
        meta: { auth: false },
    },
    {
        path: "/verify-email",
        component: () => import("../views/VerifyEmailView.vue"),
        meta: { auth: true },
    },
    {
        path: "/forgot-password",
        component: () => import("../views/ForgotPasswordView.vue"),
        meta: { auth: false },
    },
    {
        path: "/password-reset",
        component: () => import("../views/ResetPasswordView.vue"),
        meta: { auth: false },
    },
    {
        path: "/signee-login",
        component: () => import("../views/SigneeLoginView.vue"),
        meta: { auth: false },
    },
    {
        path: "/integrations/:integrationId/callback",
        component: () => import("../views/IntegrationRedirectView.vue"),
    },
    {
        path: "/pdfs/:pdfId/present",
        component: () => import("../views/PresentationView.vue"),
        meta: { auth: true },
    },
    {
        path: "/",
        redirect: "/home",
        component: () => import("../components/TheWrapper.vue"),
        children: [
            {
                path: "/home",
                component: () => import("../views/HomeView.vue"),
                name: "home",
                meta: { auth: true },
            },
            {
                path: "/documents",
                name: "documents",
                component: () => import("../views/DocumentsView.vue"),
                meta: { auth: true, ...authorizedRoles(["admin"]) },
            },
            {
                path: "/templates",
                name: "templates",
                component: () => import("../views/TemplatesView.vue"),
                meta: { auth: true, ...authorizedRoles(["admin", "standard"]) },
            },
            {
                path: "/signing",
                name: "signing",
                component: () => import("../views/SigningView.vue"),
                meta: { auth: true },
            },
            {
                path: "/forms",
                name: "forms",
                component: () => import("../views/FormsView.vue"),
                meta: { auth: true },
            },
            {
                path: "/tools",
                name: "tools",
                component: () => import("../views/ToolsView.vue"),
                meta: { auth: true },
            },
            {
                path: "/pdfs/:pdfId",
                component: () => import("../views/EditorView.vue"),
                meta: { auth: true, hideHeader: true, isEditor: true },
            },
            {
                path: "/pdfs/:pdfId/:mode",
                component: () => import("../views/EditorView.vue"),
                meta: { auth: true, hideHeader: true, isEditor: true },
            },
            {
                path: "/settings",
                component: () => import("../views/SettingsView.vue"),
                meta: { auth: true },
                children: [
                    {
                        path: "",
                        redirect: "/settings/account",
                        meta: { auth: true },
                    },
                    {
                        path: "account",
                        component: SettingsAccountView,
                        name: "account",
                        meta: { auth: true },
                    },
                    {
                        path: "signatures",
                        component: SettingsSignaturesView,
                        name: "signatures",
                        meta: { auth: true },
                    },
                    {
                        path: "organizations",
                        component: SettingsOrganizationView,
                        name: "organizations",
                        meta: { auth: true, ...authorizedRoles(["admin"]) },
                    },
                    {
                        path: "api-tokens",
                        component: SettingsApiTokensView,
                        name: "api-tokens",
                        meta: { auth: true, ...authorizedRoles(["admin"]) },
                    },
                    {
                        path: "webhooks",
                        component: () =>
                            import(
                                "../components/settings/webhooks/SettingsWebhooksView.vue"
                            ),
                        name: "webhooks",
                        meta: { auth: true, ...authorizedRoles(["admin"]) },
                    },
                    {
                        path: "webhooks/:webhookId",
                        component: () =>
                            import(
                                "@/components/settings/webhooks/SettingsWebhookView.vue"
                            ),
                        name: "webhook",
                        meta: { auth: true, ...authorizedRoles(["admin"]) },
                    },
                    {
                        path: "billing",
                        component: SettingsBillingView,
                        name: "billing",
                        meta: { auth: true, ...authorizedRoles(["admin"]) },
                    },
                    {
                        path: "integrations",
                        component: SettingsIntegrationsView,
                        name: "integrations",
                        meta: { auth: true },
                    },
                    {
                        path: "stubs",
                        component: SettingsStubsView,
                        name: "stubs",
                        meta: { auth: true, ...authorizedRoles(["admin"]) },
                    },
                    {
                        path: "stubs/:stubId",
                        component: () =>
                            import(
                                "@/components/settings/SettingsStubView.vue"
                            ),
                        name: "stub",
                        meta: { auth: true, ...authorizedRoles(["admin"]) },
                    },
                ],
            },
            {
                path: "back-office",
                name: "back-office",
                component: () => import("@/views/BackOfficeView.vue"),
                meta: { auth: true, supportStaff: true },
                children: [
                    {
                        path: "",
                        redirect: "/back-office/subscription-plans",
                        name: "back-office-home",
                        meta: { auth: true, supportStaff: true },
                    },
                    {
                        path: "subscription-plans",
                        component: BackOfficeSubscriptionPlansView,
                        name: "subscription-plans",
                        meta: { auth: true, supportStaff: true },
                    },
                    {
                        path: "subscription-plans/:subscriptionPlanId",
                        component: () =>
                            import(
                                "@/components/back-office/BackOfficeSubscriptionPlanView.vue"
                            ),
                        name: "subscription-plan",
                        meta: { auth: true, supportStaff: true },
                    },
                    {
                        path: "alerts",
                        component: BackOfficeAlertsView,
                        name: "alerts",
                        meta: { auth: true, supportStaff: true },
                    },
                ],
            },
            {
                path: "developer",
                name: "developer",
                component: () => import("@/views/DeveloperView.vue"),
                meta: { auth: true, developer: true },
            },
        ],
    },

    {
        path: "/:pathMatch(.*)*",
        name: "404",
        component: () => import("@/views/404View.vue"),
    },
]

/**
 * Create a fresh instance of our router for all public and private routes.
 */
export const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [...routes],
    scrollBehavior(to, _from, _savedPosition) {
        if (to.hash) {
            // For some reason, the Vue docs didn't work for me, so I had to do this.
            // https://stackoverflow.com/a/67797577/10044909
            const el = window.location.href.split("#")[1]
            if (el.length) {
                document
                    .getElementById(el)
                    ?.scrollIntoView({ behavior: "smooth" })
            }
        }
    },
})

/**
 * Before the requesting user is allowed to access any route in our app,
 * let's run through some checks...
 */
router.beforeEach(async (to, from, next) => {
    const userStore = useUserStore()
    const documentStore = useDocumentStore()

    /**
     * Captures the pdf id from the url when leaving the editor.
     * Stores in state, used to scroll to document.
     */
    const pdfId = from.fullPath.match(/\/pdfs\/(?<pdfId>.*)\/.*/)?.groups?.pdfId
    documentStore.lastOpenPDF = pdfId ? pdfId : null

    /**
     * Determine if the route the user is trying to navigate to is a
     * public or private route.
     */
    const isPublicRoute = to?.meta?.auth === false
    const isPrivateRoute = to?.meta?.auth === true

    /**
     * If we have not performed an authentication check yet, let's do it
     * now.
     */
    if (userStore.isAuthenticated === null) {
        await useAuth().getMe()
    }

    /**
     * Handle both support staff and developer routes.
     */
    handleSupportStaff(userStore.user, to, next)
    handleDeveloper(userStore.user, to, next)

    /**
     * If we have an unauthenticated user trying to access a private route,
     * let's redirect them to the login screen.
     */
    if (!userStore.isAuthenticated && isPrivateRoute) {
        return next({
            path: "/login",
            query: { redirect: to.fullPath },
        })
    }

    /**
     * If we have an authenticated user trying to access a public route,
     * let's redirect them to their home page.
     *
     * The exception to this is the `/signee-login` route, which is used
     * to route users to the editor when they click a link in an email.
     */
    if (userStore.isAuthenticated && isPublicRoute) {
        if (to.path === "/signee-login") {
            return next()
        }
        return next("/home")
    }

    /**
     * This section handles persistent query params
     */

    function hasQueryParams(route: RouteLocationNormalized, param: string) {
        return !!route.query[param]
    }

    const persistParams = ["stateless", "shell"].filter((param) => {
        return !hasQueryParams(to, param) && hasQueryParams(from, param)
    })

    const needToPersist = ["stateless", "shell"].some(
        (query) => !hasQueryParams(to, query) && hasQueryParams(from, query)
    )

    // Values are hard coded right now as `stateless=true` and `shell=none`
    const persistentQueries = {
        ...(persistParams.includes("stateless") && {
            stateless: "true",
        }),
        ...(persistParams.includes("shell") && {
            shell: "none",
        }),
    }

    // Persists the stateless param on route changes.
    if (needToPersist) {
        return next({
            path: to.path,
            query: {
                ...persistentQueries,
                ...to.query, // to query params overwrite persistent ones
            },
        })
    }

    next()
})

export default router

/**
 * Handles developer route guarding.
 * Providing `developer` meta to a route will restrict access to only
 * users with the isDeveloper role.
 */
function handleDeveloper(
    user: User | null,
    to: RouteLocationNormalized,
    next: NavigationGuardNext
) {
    if (to?.meta?.developer === true) {
        if (user && user.isDeveloper) return
        return next({
            path: "/home",
        })
    }
}

/**
 * Handles support staff route guarding.
 * Providing `supportStaff` meta to a route will restrict access to only
 * users with the isSupportStaff role.
 */
function handleSupportStaff(
    user: User | null,
    to: RouteLocationNormalized,
    next: NavigationGuardNext
) {
    if (to?.meta?.supportStaff === true) {
        if (user && user.isSupportStaff) return
        return next({
            path: "/home",
        })
    }
}
