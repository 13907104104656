import User from "./User"
import Organization from "./Organization"
import type { ApiInvitationData } from "types/api"

export default class Invitation {
    id: ApiInvitationData["id"]
    organization_id: ApiInvitationData["organization_id"]
    email: ApiInvitationData["email"]
    sent_by: ApiInvitationData["sent_by"]
    sent_at: ApiInvitationData["sent_at"]
    rejected_at: ApiInvitationData["rejected_at"]
    role: ApiInvitationData["role"]
    invite_message: ApiInvitationData["invite_message"]
    reject_reason: ApiInvitationData["reject_reason"]
    created_at: ApiInvitationData["created_at"]
    updated_at: ApiInvitationData["updated_at"]
    status: ApiInvitationData["status"]
    is_viewed: ApiInvitationData["is_viewed"]
    human_readable_sent_at: ApiInvitationData["human_readable_sent_at"]
    organization?: Organization
    sender?: User

    constructor(data: ApiInvitationData) {
        this.id = data.id
        this.organization_id = data.organization_id
        this.email = data.email
        this.sent_by = data.sent_by
        this.sent_at = data.sent_at
        this.rejected_at = data.rejected_at
        this.role = data.role
        this.invite_message = data.invite_message
        this.reject_reason = data.reject_reason
        this.created_at = data.created_at
        this.updated_at = data.updated_at
        this.is_viewed = data.is_viewed
        this.status = data.status
        this.human_readable_sent_at = data.human_readable_sent_at

        if (data.organization)
            this.organization = new Organization(data.organization)
        if (data.sender) this.sender = new User(data.sender)
    }
}
