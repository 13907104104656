<template>
    <SectionLayout
        title="Your Organizations"
        description="Organizations allow you to collaborate on documents with others. Organizations that you belong to are listed here."
        is-table
    >
        <template #actions>
            <GFButton
                label="Create new organization"
                @click="triggerCreateNewForm"
            />
        </template>
        <GFTable
            :cols="[
                ['name', 'Organization'],
                ['authUserRole', 'Your role'],
                ['is_active', 'Status'],
                'actions',
            ]"
            :data="organizationStore.organizations"
            empty-message="No organizations yet..."
        >
            <template #name="{ row: organization }">
                <div class="flex items-center">
                    <GFAvatar
                        v-if="organization.logo_base64"
                        :src="organization.logo_base64"
                        size="md"
                        class="mr-2"
                        :round="true"
                    />
                    <span>{{ organization.name }}</span>
                </div>
            </template>
            <template #authUserRole="{ fieldData: role }">
                {{ startCase(role) }}
            </template>
            <template #is_active="{ row: organization }">
                <GFTag
                    :label="organization.is_active ? 'Active' : 'Inactive'"
                    :color="organization.is_active ? 'green' : 'red'"
                />
            </template>
            <template #actions="{ row: organization }">
                <div class="flex h-6 items-center justify-end space-x-4">
                    <GFButtonSimple
                        v-if="
                            organizationStore.organization?.id !==
                            organization.id
                        "
                        label="Go to"
                        size="small"
                        @click="switchOrg(organization as Organization)"
                    />
                    <div v-else class="italic text-gray-700">
                        Currently selected
                    </div>
                </div>
            </template>
        </GFTable>
    </SectionLayout>

    <CreateOrganizationModal v-model:show="showCreateOrganizationModal" />
</template>
<script setup lang="ts">
import { startCase } from "lodash"
import GFButton from "../../base/GFButton.vue"
import GFTag from "@/components/base/GFTag.vue"
import GFTable from "@/components/base/GFTable.vue"
import type Organization from "@/models/Organization"
import GFAvatar from "@/components/base/GFAvatar.vue"
import SectionLayout from "../../layout/SectionLayout.vue"
import { useRouteQuery } from "@/composables/useRouteQuery"
import { useOrganizationStore } from "@/stores/organization"
import GFButtonSimple from "@/components/base/GFButtonSimple.vue"
import CreateOrganizationModal from "@/components/modals/CreateOrganizationModal.vue"

const organizationStore = useOrganizationStore()

const { triggerNewOrganization } = useRouteQuery()

const showCreateOrganizationModal = ref(triggerNewOrganization.value)

function triggerCreateNewForm() {
    showCreateOrganizationModal.value = true
}

function switchOrg(org: Organization) {
    organizationStore.changeOrganization(org)
}
</script>
