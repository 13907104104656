export function useCopyText() {
    const copyIcon = ref("clipboard")

    async function copy(text: string) {
        try {
            await navigator.clipboard.writeText(text)
            copyIcon.value = "clipboard-check"
            setTimeout(() => {
                copyIcon.value = "clipboard"
            }, 3500)
        } catch ($e) {
            console.error(
                "Failed copying to clipboard. You must be on a secure site to use this feature."
            )
        }
    }

    return { copy, copyIcon }
}
