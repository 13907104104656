import axios, { AxiosError } from "axios"
import type { ApiError } from "types/api"
import { useAppStore } from "@/stores/app"
import useNotification from "@/composables/useNotification"
import router from "@/router"
import config from "@/config"

export const onError = (error: AxiosError<ApiError>) => {
    if (axios.isAxiosError(error)) {
        const { open } = useNotification()
        const { showMaintenanceModal } = storeToRefs(useAppStore())

        if (shouldIgnoreError(error)) return

        /**
         * 401 Forbidden
         */
        if (error.response && error.response.status === 401) {
            return
        }

        /**
         * 402 Payment Required
         */
        if (error.response && error.response.status === 402) {
            open({
                message:
                    (error.response?.data as ApiError).message ??
                    "Sorry, that is not available to you. Please upgrade your subscription plan or contact support.",
                title:
                    (error.response?.data as ApiError).title ??
                    "Subscription Upgrade Required",
                type: "warning",
                actions: [
                    {
                        click: () =>
                            router.push({
                                name: "billing",
                                hash: "#subscription-plan",
                            }),
                        text: "Upgrade",
                        type: "primary",
                    },
                ],
            })
            return
        }

        /**
         * 403 Forbidden
         */
        if (error.response && error.response.status === 403) {
            open({
                message:
                    (error.response?.data as ApiError).message ??
                    "This action is forbidden.",
                title: "Unauthorized",
                type: "forbidden",
            })
            return
        }

        /**
         * 404
         * Silencing this error, handled via the 404 pages.
         */
        if (error.response && error.response.status === 404) {
            return
        }

        /**
         * All other 400s
         */
        if (
            error.response &&
            error.response.status >= 400 &&
            error.response.status < 500
        ) {
            // Blob responses must be converted to json to read the message
            if (error.request.responseType === "blob") {
                const reader = new FileReader()
                reader.readAsText(error.response.data as any)

                reader.onload = () => {
                    const json = JSON.parse(reader.result as string)
                    open({
                        message:
                            json.message ?? "Sorry, something went wrong...",
                        title: "Error",
                        type: "warning",
                    })
                }
            } else {
                open({
                    message:
                        (error.response?.data as ApiError).message ??
                        "Sorry, something went wrong...",
                    title: "Error",
                    type: "warning",
                })
            }

            return
        }

        /**
         * 503
         */
        if (error.response?.status === 503) {
            showMaintenanceModal.value = true
            return
        }

        /**
         * 500 and greater
         */
        if (error.response && error.response.status >= 500) {
            open({
                message:
                    "Sorry, something went wrong on our end, please try again shortly.",
                title: "There was a problem...",
                type: "error",
            })
            return
        }
    }
}

/**
 * Check if the error should be ignored
 */
export function shouldIgnoreError(error: AxiosError<ApiError>) {
    return config.errorIgnorePaths.some(
        (ignore) =>
            error.request.responseURL.includes(ignore.path) &&
            error.response?.status === ignore.status
    )
}
