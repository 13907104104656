import { useQuery } from "@tanstack/vue-query"
import type { Ref } from "vue"
import useAxios from "@/composables/useAxios"
import type { ApiPaginatedGETResponse, ApiUserTokenData } from "types/api"
import { UserToken } from "@/models/UserToken"
import type User from "@/models/User"

export const userTokensBaseKey = "userTokens"

export function useTokensQuery(userId: Ref<User["id"] | undefined>) {
    return useQuery(
        [userTokensBaseKey, userId],
        () =>
            useAxios()
                .axios.get<ApiPaginatedGETResponse<ApiUserTokenData>>(
                    `/api/users/${userId.value}/tokens`
                )
                .then((res) => res.data.data),
        {
            enabled: computed(() => userId.value !== undefined),
            select(data) {
                return data.map((token) => new UserToken(token))
            },
        }
    )
}
