<template>
    <div class="flex w-full flex-col">
        <label
            v-if="label"
            for="about"
            class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
            {{ label }}
        </label>
        <div class="mt-1 sm:col-span-2 sm:mt-0">
            <textarea
                id="about"
                ref="input"
                :value="value ?? undefined"
                name="about"
                :autofocus="autofocus"
                v-bind="$attrs"
                type="textarea"
                :placeholder="placeholder"
                :rows="rows"
                class="block w-full rounded-md border shadow-sm focus:border-transparent sm:text-sm"
                :class="[
                    !hasErrors && 'border-gray-300 focus:ring-primary',
                    hasErrors &&
                        'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500',
                ]"
                @input="
                    $emit('update:modelValue', ($event.target as any).value)
                "
            />
        </div>
        <p v-if="hasErrors" id="email-error" class="mt-2 text-sm text-red-600">
            {{ formErrors?.first(name) }}
        </p>
    </div>
</template>
<script lang="ts" setup>
import type { PropType } from "vue"
import type GFFormErrors from "@/helpers/GFFormErrors"

const props = defineProps({
    modelValue: {
        type: String as PropType<string | null>,
        default: null,
    },
    label: {
        type: String,
        default: null,
    },
    autofocus: {
        type: Boolean,
        default: false,
    },
    size: {
        type: String as PropType<"sm" | "md" | "lg">,
        default: "sm",
    },
    placeholder: {
        type: String,
        default: null,
    },
    formErrors: {
        type: Object as PropType<GFFormErrors | null>,
        default: null,
    },
    name: {
        type: String,
        default: null,
    },
})
const emit = defineEmits(["update:modelValue"])

const value = computed({
    get() {
        return props.modelValue
    },
    set(val) {
        emit("update:modelValue", val)
    },
})

const rows = computed(() => {
    switch (props.size) {
        case "sm":
            return 3
        case "md":
            return 6
        case "lg":
            return 9

        default:
            return 3
    }
})

const hasErrors = computed(
    () => props.formErrors && props.formErrors.has(props.name)
)

const input = ref<HTMLInputElement>()

function focus() {
    nextTick(() => setTimeout(() => input.value?.focus()))
}

function select() {
    nextTick(() => setTimeout(() => input.value?.select()))
}

defineExpose({ focus, select })
</script>
