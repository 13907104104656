import config from "@/config"
import { defineStore } from "pinia"
import { useUserStore } from "./user"
import { useRouter } from "vue-router"
import { useColorsStore } from "./colors"
import useAxios from "@/composables/useAxios"
import Organization from "@/models/Organization"
import useNotification from "@/composables/useNotification"
import type { ApiPaginatedGETResponse, ApiOrganizationData } from "types/api"
import { afterOrganizationChange } from "@/middleware/afterOrganizationChange"
import { beforeOrganizationChange } from "@/middleware/beforeOrganizationChange"
import { useUserInvitations } from "@/queries/user-invitations/useUserInvitations"

export const useOrganizationStore = defineStore("organization", () => {
    const userStore = useUserStore()
    const router = useRouter()
    const route = useRoute()

    /**
     * Currently active organization
     */
    const organization = ref<Organization | null | undefined>(undefined)

    /**
     * List of this users organizations
     */
    const organizations = ref<Organization[]>([])

    const organizationIsBillable = computed<boolean>(
        () => organization.value?.is_billable ?? config.defaultIsBillable
    )

    /**
     * If set, the organization will be set to this organization (or null if it's not found) during the initialization process.
     * This gets cleared after the initialization process runs.
     */
    const initialOrgId = ref<Organization["id"] | null>(null)

    const orgId = computed(() => {
        return organization.value ? organization.value.id : "private"
    })

    const orgIdString = computed(() => {
        return organization.value ? organization.value.id : null
    })

    const isOrganization = computed(() => {
        return !!organization.value
    })

    const isMyStuff = computed(() => {
        return !organization.value
    })

    async function init() {
        await populateOrganizationList()

        /**
         * If there is an initial org id, set to that organization rather than the default.
         * Otherwise set to the default organization.
         */
        if (initialOrgId.value) {
            const initialOrg = findOrgById(initialOrgId.value)

            changeOrganization(initialOrg ? initialOrg : null)

            initialOrgId.value = null
        } else {
            await setToDefaultOrg()
        }
    }

    /**
     * Sets to the default organization.
     * Makes API call to retrieve the default org id.
     */
    async function setToDefaultOrg() {
        const {
            data: { default_organization_id },
        } = await useAxios().axios.get<{
            default_organization_id: Organization["id"]
        }>(`/api/users/${userStore.user?.id}/settings/default_organization_id`)

        const defaultOrg = findOrgById(default_organization_id)

        await changeOrganization(defaultOrg ? defaultOrg : null, {
            updateDefault: false, // to prevent endless loop
        })

        setOrgColors()
    }

    /**
     * Responsible for querying and populating the organizations list.
     * @param config.refreshCurrent - If true, the current organization will be refreshed with the latest data from the database.
     */
    async function populateOrganizationList(config?: {
        refreshCurrent: boolean
    }) {
        const orgList = await useAxios()
            .axios.get<ApiPaginatedGETResponse<ApiOrganizationData>>(
                `/api/organizations`,
                {
                    params: {
                        append: ["logo_base64", "is_billable"],
                        include: "authOrganizationUser",
                    },
                }
            )
            .then((resp) =>
                resp.data.data.map((orgData) => new Organization(orgData))
            )

        organizations.value = orgList

        if (config?.refreshCurrent) {
            const currentOrgId = organization.value?.id
            if (currentOrgId) organization.value = findOrgById(currentOrgId)
        }
    }

    /**
     * Changes the user to a new organization; this function should always be used when changing the users organization.
     * By default the users default_organization_id will be updated on the database, but this can be opted out of.
     * Also updates colors for new organization.
     */
    async function changeOrganization(
        org: Organization | null,
        { updateDefault } = { updateDefault: true }
    ) {
        if (updateDefault) await updateDefaultOrg(org)

        const isInitial = organization.value === undefined

        await beforeOrganizationChange(org, organization.value, {
            router,
            route,
            isInitial,
        })

        organization.value = org

        afterOrganizationChange(
            organization.value?.authUserRole,
            org?.authUserRole ?? "admin",
            { router, route, isInitial }
        )
    }

    /**
     * Updates the user default organization settings.
     */
    async function updateDefaultOrg(organization: Organization | null) {
        const userStore = useUserStore()

        await useAxios().axios.put(
            `/api/users/${userStore.user?.id}/settings/default_organization_id`,
            { value: organization ? organization.id : null }
        )
    }

    /**
     * Updates the apps colors to the current organizations colors.
     */
    function setOrgColors() {
        const colorsStore = useColorsStore()

        if (organization.value) {
            colorsStore.primary = organization.value.primary_color
                ? organization.value.primary_color
                : colorsStore.gfPrimary

            colorsStore.secondary = organization.value.secondary_color
                ? organization.value.secondary_color
                : colorsStore.gfSecondary

            colorsStore.setCSSVars()
        } else {
            colorsStore.primary = colorsStore.gfPrimary

            colorsStore.setCSSVars()
        }
    }

    function findOrgById(id: Organization["id"]) {
        return organizations.value.find((org) => org.id === id)
    }

    const orgIsBillable = computed(() => {
        return organization.value?.is_billable ?? config.defaultIsBillable
    })

    function checkForPendingInvites() {
        const { hasPendingInvite, hasNotViewedInvite, notViewedInvites } =
            useUserInvitations(computed(() => userStore.user?.id ?? null))

        watch(hasPendingInvite, () => {
            if (hasPendingInvite.value) {
                /**
                 * First check if there is a pending invite that hasn't been viewed yet.
                 */
                if (hasNotViewedInvite.value) {
                    router.push({
                        query: {
                            confirm_invite_id: notViewedInvites.value?.[0].id,
                        },
                    })
                } else {
                    /**
                     * If there is no pending invite that hasn't been viewed yet, then just open a notification.
                     */
                    useNotification().open({
                        title: "Pending Invite",
                        message:
                            "You have a pending invite to an organization.",
                        actions: [
                            {
                                text: "View",
                                type: "primary",
                                click({ close }) {
                                    close()
                                    router.push({
                                        name: "home",
                                    })
                                    // For some reason the hash doesn't work unless we wait a bit.
                                    setTimeout(() => {
                                        router.push({
                                            hash: "#invites",
                                        })
                                    }, 250)
                                },
                            },
                        ],
                    })
                }
            }
        })
    }

    return {
        organization,
        organizations,
        initialOrgId,
        orgId,
        organizationIsBillable,
        orgIdString,
        init,
        setToDefaultOrg,
        populateOrganizationList,
        changeOrganization,
        updateDefaultOrg,
        setOrgColors,
        findOrgById,
        orgIsBillable,
        isOrganization,
        isMyStuff,
        checkForPendingInvites,
    }
})
