<template>
    <div class="gf-input">
        <label :for="name" class="gf_input_component_label_main">
            <slot>{{ label }}</slot>

            <span v-if="showOptional" class="gf_input_component_label_optional">
                Optional
            </span>
        </label>

        <div :class="[label && 'mt-1', 'gf_input_component_input_wrapper']">
            <input
                :id="name"
                ref="input"
                :type="type"
                :name="name"
                v-bind="$attrs"
                :disabled="disabled"
                :value="modelValue"
                class="gf_input_component_input_main"
                :class="[
                    disabled && 'gf_input_component_input_main_disabled',
                    !disabled && 'gf_input_component_input_main_not_disabled',
                    !hasErrors && 'gf_input_component_input_main_no_errors',
                    hasErrors && 'gf_input_component_input_main_with_errors',
                ]"
                :placeholder="placeholder"
                @input="
                    $emit('update:modelValue', ($event.target as any).value)
                "
                @keyup.enter.stop="(e) => $emit('enter', e)"
            />

            <div
                v-if="hasErrors"
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
            >
                <GFIcon
                    class="h-5 w-5 text-red-500"
                    icon="circle-exclamation"
                />
            </div>
        </div>

        <p
            v-if="hasErrors"
            id="email-error"
            class="gf_input_component_input_error_text"
        >
            {{ formErrors?.first(name) }}
        </p>
    </div>
</template>

<script lang="ts" setup>
import GFIcon from "./GFIcon.vue"
import type { PropType } from "vue"
import type GFFormErrors from "@/helpers/GFFormErrors"

defineEmits<{
    (e: "update:modelValue", payload: string | number): void
    (e: "enter", event: KeyboardEvent): void
}>()

const props = defineProps({
    modelValue: {
        type: [String, Number] as PropType<string | number | null>,
        default: null,
    },
    name: {
        type: String,
        default: null,
    },
    label: {
        type: String,
        default: null,
    },
    placeholder: {
        type: String,
        default: null,
    },
    type: {
        type: String,
        default: "text",
    },
    formErrors: {
        type: Object as PropType<GFFormErrors | null>,
        default: null,
    },
    autofocus: {
        type: Boolean,
        default: false,
    },
    autoHighlight: {
        type: Boolean,
        default: false,
    },
    showOptional: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
})

const input = ref<HTMLInputElement>()

const hasErrors = computed(
    () => props.formErrors && props.formErrors.has(props.name)
)

onMounted(() => {
    if (props.autofocus) {
        focus()
    }
    if (props.autoHighlight) {
        select()
    }
})

function focus() {
    nextTick(() => setTimeout(() => input.value?.focus()))
}

function select() {
    nextTick(() => setTimeout(() => input.value?.select()))
}

defineExpose({ focus, select })
</script>
