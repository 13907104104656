import { useQuery } from "@tanstack/vue-query"
import useAxios from "@/composables/useAxios"
import type {
    ApiPaymentMethodData,
    BillingModelId,
    BillingModelType,
} from "types/api"
import { PaymentMethod } from "@/models/PaymentMethod"
import type { Ref } from "vue"
import config from "@/config"
import { QueryKey } from "@/queries/QueryKey"

export function usePaymentMethodsQuery(
    modelType: Ref<BillingModelType>,
    modelId: Ref<BillingModelId | undefined>
) {
    return useQuery(
        [QueryKey.base("payment-methods"), modelType, modelId],
        () =>
            useAxios()
                .axios.get<ApiPaymentMethodData[]>(
                    `/api/${modelType.value}/${modelId.value}/billing/payment-methods`
                )
                .then((response) => response.data),
        {
            select: (data) => data.map((data) => new PaymentMethod(data)),
            enabled: computed(() => !!modelId.value),
            staleTime: config.staleTime.long,
        }
    )
}
