import type User from "@/models/User"
import useAxios from "@/composables/useAxios"
import type Organization from "@/models/Organization"
import { useMutation, useQueryClient } from "@tanstack/vue-query"
import useNotification from "@/composables/useNotification"
import type { ModelSubscription } from "@/models/ModelSubscription"
import { QueryKey } from "../QueryKey"

export function useModelSubscriptionResume() {
    const queryClient = useQueryClient()

    return useMutation(
        (payload: {
            modelType: ModelSubscription["modelType"]
            modelId: User["id"] | Organization["id"]
        }) =>
            useAxios().axios.put(
                `/api/${payload.modelType}/${payload.modelId}/billing/subscription/resume`
            ),
        {
            onSuccess() {
                queryClient.invalidateQueries(
                    QueryKey.baseKey("model-subscriptions")
                )
                queryClient.invalidateQueries(QueryKey.baseKey("invoices"))

                useNotification().open({
                    type: "success",
                    title: "Subscription resumed",
                    message: "Your subscription has been resumed. Thank you!",
                })
            },
        }
    )
}
