import useAxios from "@/composables/useAxios"
import { useQuery } from "@tanstack/vue-query"
import type { ApiStripeProductData } from "types/api"

export const stripeBaseKey = "stripe"

export function useStripeProductsQuery(enabled = true) {
    return useQuery(
        [stripeBaseKey],
        () =>
            useAxios()
                .axios.get<ApiStripeProductData[]>(
                    `/api/billing/stripe/products`
                )
                .then((res) => res.data),
        { enabled }
    )
}
