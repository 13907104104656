import { useDate } from "@/composables/useDate"
import type {
    ApiSubscriptionPlanPriceData,
    ApiModelSubscriptionData,
    StripeProduct,
    BillingModelType,
    ApiGlobalId,
    StripeStatus,
} from "types/api"
import type Organization from "./Organization"
import type User from "./User"

const { dayjs } = useDate()

export class ModelSubscription {
    id: ApiGlobalId
    model_type: "App\\Models\\User" | "App\\Models\\Organization"
    model_id: User["id"] | Organization["id"]
    stripe_id: string
    stripe_status: StripeStatus
    is_active: boolean
    is_valid: boolean
    is_canceled: boolean
    is_ended: boolean
    is_on_grace_period: boolean
    is_resumable: boolean
    is_restartable: boolean
    is_cancelable: boolean
    is_on_trial: boolean
    interval: string
    quantity: number
    stripe_product: StripeProduct
    stripe_price: ApiSubscriptionPlanPriceData
    created_at: string
    updated_at: string
    ends_at: string | null
    renews_at: string | null
    permissions: {
        add_seats: boolean
        remove_seats: boolean
        change_plan: boolean
        change_interval: boolean
    }
    human_readable_ends_at: string
    human_readable_renews_at: string
    applied_promo_code: null | {
        amount_off: null
        duration: string
        name: string
        percent_off: number
    }

    constructor(data: ApiModelSubscriptionData) {
        this.id = data.id
        this.model_type = data.model_type
        this.model_id = data.model_id
        this.stripe_id = data.stripe_id
        this.stripe_status = data.stripe_status
        this.is_active = data.is_active
        this.is_on_trial = data.is_on_trial
        this.is_valid = data.is_valid
        this.is_canceled = data.is_canceled
        this.is_ended = data.is_ended
        this.is_on_grace_period = data.is_on_grace_period
        this.is_resumable = data.is_resumable
        this.is_restartable = data.is_restartable
        this.is_cancelable = data.is_cancelable
        this.interval = data.interval
        this.stripe_product = data.stripe_product
        this.stripe_price = data.stripe_price
        this.quantity = data.quantity
        this.created_at = data.created_at
        this.updated_at = data.updated_at
        this.ends_at = data.ends_at
        this.renews_at = data.renews_at
        this.permissions = data.permissions
        this.human_readable_ends_at = data.human_readable_ends_at
        this.human_readable_renews_at = data.human_readable_renews_at
        this.applied_promo_code = data.applied_promo_code
    }

    get modelType(): BillingModelType {
        return this.model_type === "App\\Models\\User"
            ? "users"
            : "organizations"
    }

    get isCancelable() {
        return this.is_cancelable
    }

    get isGracePeriod() {
        return this.is_on_grace_period
    }

    get isOnTrial() {
        return this.is_on_trial
    }

    get isResumable() {
        return this.is_resumable
    }

    get stripeProductId() {
        return this.stripe_product.id
    }

    get endsAtReadableDate() {
        return dayjs(this.ends_at).format("MMMM D, YYYY")
    }

    get renewsAtReadableDate() {
        return dayjs(this.renews_at).format("MMMM D, YYYY")
    }

    get incrementDisabled() {
        return !this.permissions.add_seats
    }

    get decrementDisabled() {
        return !this.permissions.remove_seats
    }

    get changePlanDisabled() {
        return !this.permissions.change_plan
    }

    get changeIntervalDisabled() {
        return !this.permissions.change_interval
    }
}
