<template>
    <GFModalSimple v-model:show="modalOpen" size="fit" @closed="reset">
        <div class="flex flex-col space-y-4">
            <GFInput
                v-model="orgName"
                label="Organization name"
                data-testid="organization-name-input"
                name="name"
                :autofocus="true"
                :form-errors="formErrors"
            ></GFInput>

            <div>
                <label class="block text-sm font-medium text-gray-700">
                    Logo
                </label>
                <div class="mt-1 flex h-14 items-center">
                    <img
                        v-if="logoBase64"
                        :src="logoBase64"
                        class="mr-5 w-14"
                    />
                    <input
                        ref="logoUpload"
                        override
                        type="file"
                        accept="image/*"
                        class="hidden"
                        @input="fileAdded"
                    />
                    <GFButton class="" @click="openUploader">
                        {{ logoBase64 ? "Change" : "Select" }}
                    </GFButton>
                    <div
                        v-if="logoFile"
                        class="ml-4 flex flex-nowrap items-center space-x-2"
                    >
                        <div>
                            {{ logoFile.name }}
                        </div>
                        <GFButtonIcon
                            icon="times"
                            class="h-8 w-8 leading-none"
                            @click="logoFile = null"
                        ></GFButtonIcon>
                    </div>
                </div>
            </div>

            <div class="flex space-x-16">
                <div>
                    <label class="mb-1 block text-sm font-medium text-gray-700">
                        Primary Color
                    </label>
                    <ColorPicker v-model="primaryColor"></ColorPicker>
                </div>
                <div>
                    <label class="mb-1 block text-sm font-medium text-gray-700">
                        Secondary Color
                    </label>
                    <ColorPicker v-model="secondaryColor"></ColorPicker>
                </div>
            </div>
        </div>

        <template #buttons>
            <div class="flex space-x-2">
                <GFButtonSimple @click="modalOpen = false">
                    Cancel
                </GFButtonSimple>
                <GFButton :loading="loading" @click="createOrganization">
                    Save
                </GFButton>
            </div>
        </template>
    </GFModalSimple>
</template>
<script setup lang="ts">
import type { ApiError } from "types/api"
import GFInput from "../base/GFInput.vue"
import GFButton from "../base/GFButton.vue"
import useAxios from "@/composables/useAxios"
import useBase64 from "@/composables/useBase64"
import { useColorsStore } from "@/stores/colors"
import GFFormErrors from "@/helpers/GFFormErrors"
import ColorPicker from "../base/ColorPicker.vue"
import type { ComponentPublicInstance } from "vue"
import GFButtonIcon from "../base/GFButtonIcon.vue"
import GFModalSimple from "../base/GFModalSimple.vue"
import GFButtonSimple from "../base/GFButtonSimple.vue"
import useNotification from "@/composables/useNotification"
import { useOrganizationStore } from "@/stores/organization"

const emit = defineEmits<{
    (e: "update:show", p: boolean): void
}>()

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
})

const modalOpen = computed({
    get() {
        return props.show
    },
    set(value: boolean) {
        emit("update:show", value)
    },
})

const router = useRouter()
const formErrors = ref<GFFormErrors>(new GFFormErrors())
const { axios, isAxiosError } = useAxios()
const { toBase64 } = useBase64()
const organizationStore = useOrganizationStore()
const colorStore = useColorsStore()

const loading = ref(false)
const logoUpload = ref<ComponentPublicInstance<HTMLInputElement> | null>(null)
const orgName = ref("")
const primaryColor = ref(colorStore.gfPrimary)
const secondaryColor = ref(colorStore.gfSecondary)
const logoFile = ref<File | null>(null)

const logoBase64 = computed(() => {
    if (!logoFile.value) return null
    return URL.createObjectURL(logoFile.value)
})

function fileAdded(event: Event) {
    const files = (event.target as HTMLInputElement).files
    if (files) {
        logoFile.value = files[0]
    }
}

function openUploader() {
    logoUpload.value?.click()
}

async function createOrganization() {
    try {
        loading.value = true
        const resp = await axios
            .post("/api/organizations", {
                name: orgName.value,
                primary_color: primaryColor.value,
                secondary_color: secondaryColor.value,
                logo: logoFile.value
                    ? await toBase64(logoFile.value)
                    : undefined,
                is_active: true,
            })
            .then((resp) => resp.data)

        await organizationStore.changeOrganization(resp)
        await organizationStore.init()

        useNotification().open({
            message: "Organization created!",
            title: "Success",
            type: "success",
        })

        modalOpen.value = false

        nextTick(() => {
            router.push({
                name: "billing",
                hash: "#subscription-plan",
            })
        })
    } catch (error) {
        if (isAxiosError(error)) {
            formErrors.value = new GFFormErrors(
                error.response?.data as ApiError
            )
            useNotification().open({
                message: "There was a problem creating the organization",
                title: "Error",
                type: "danger",
            })
        }
    } finally {
        loading.value = false
    }
}

function reset() {
    orgName.value = ""
    primaryColor.value = colorStore.gfPrimary
    secondaryColor.value = colorStore.gfSecondary
    logoFile.value = null
    formErrors.value = new GFFormErrors()
}
</script>
