<template>
    <div class="flex flex-col space-y-8">
        <SectionOrganizationList />

        <SectionLayout
            v-if="organizationStore.organization !== null"
            title="Manage Organization"
            :description="`Update the settings for the organization ${organizationStore.organization?.name}.`"
        >
            <SectionLayoutForm>
                <div>
                    <div class="flex flex-col space-y-6">
                        <GFInput
                            v-model="formData.name"
                            label="Organization name"
                            data-testid="organization-name-input"
                            name="name"
                            :form-errors="formErrors"
                        />

                        <div>
                            <label
                                class="block text-sm font-medium text-gray-700"
                            >
                                Logo
                            </label>
                            <div class="mt-1 flex h-14 items-center">
                                <img
                                    v-if="logoBase64"
                                    :src="logoBase64"
                                    class="mr-5 w-14"
                                />
                                <input
                                    ref="logoUpload"
                                    override
                                    type="file"
                                    accept="image/*"
                                    class="hidden"
                                    @input="fileAdded"
                                />

                                <GFButton
                                    :label="logoBase64 ? 'Change' : 'Select'"
                                    @click="openUploader"
                                />

                                <div
                                    v-if="logoFile"
                                    class="ml-4 flex flex-nowrap items-center space-x-2"
                                >
                                    <div>
                                        {{ logoFile.name }}
                                    </div>

                                    <GFButtonIcon
                                        icon="times"
                                        class="h-8 w-8 leading-none"
                                        @click="logoFile = null"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="md:flex md:space-x-16">
                            <div>
                                <label
                                    class="mb-1 block text-sm font-medium text-gray-700"
                                >
                                    Primary Color
                                </label>

                                <ColorPicker v-model="formData.primary_color" />
                            </div>
                            <div class="mt-6 md:mt-0">
                                <label
                                    class="mb-1 block text-sm font-medium text-gray-700"
                                >
                                    Secondary Color
                                </label>

                                <ColorPicker
                                    v-model="formData.secondary_color"
                                />
                            </div>
                        </div>

                        <GFSwitch v-model="formData.is_active" label="Active" />
                    </div>
                </div>

                <template
                    v-if="
                        organizationStore.organizations.length &&
                        organizationStore.organization !== null
                    "
                    #footer
                >
                    <div class="flex w-full items-center justify-between">
                        <div class="h-full">
                            <GFButtonText
                                v-if="organizationStore.organization"
                                class="py-2 text-black"
                                type="danger"
                                size="sm"
                                label="Delete Organization"
                                @click="toggleDeleteModal"
                            />
                        </div>

                        <GFButton
                            :disabled="!hasChanges"
                            :loading="loading"
                            data-testid="manage-orgs-save-button"
                            label="Save"
                            @click="save"
                        />
                    </div>
                </template>
            </SectionLayoutForm>
        </SectionLayout>

        <SectionInviteUsers :users="users ?? []" :invitees="invitees ?? []" />

        <SectionOrganizationUsers :users="users ?? []" />
    </div>

    <GFModalSimple v-model:show="showDeleteModal" title="Are you sure?">
        <div>
            Deleting this organization will delete all documents within the
            organization.
        </div>

        <template #buttons>
            <div class="flex space-x-2">
                <GFButton label="Cancel" outline @click="toggleDeleteModal" />

                <GFButton
                    label="Delete"
                    danger
                    :loading="loading"
                    @click="deleteOrganization"
                />
            </div>
        </template>
    </GFModalSimple>
</template>
<script lang="ts" setup>
import type { ApiError } from "types/api"
import { cloneDeep, isEqual } from "lodash"
import useAxios from "@/composables/useAxios"
import useBase64 from "@/composables/useBase64"
import { useColorsStore } from "@/stores/colors"
import GFFormErrors from "@/helpers/GFFormErrors"
import type { ComponentPublicInstance } from "vue"
import GFInput from "@/components/base/GFInput.vue"
import GFButton from "@/components/base/GFButton.vue"
import GFSwitch from "@/components/base/GFSwitch.vue"
import SectionInviteUsers from "./SectionInviteUsers.vue"
import useNotification from "@/composables/useNotification"
import ColorPicker from "@/components/base/ColorPicker.vue"
import { useOrganizationStore } from "@/stores/organization"
import GFButtonIcon from "@/components/base/GFButtonIcon.vue"
import GFButtonText from "@/components/base/GFButtonText.vue"
import GFModalSimple from "@/components/base/GFModalSimple.vue"
import SectionLayout from "@/components/layout/SectionLayout.vue"
import SectionOrganizationList from "./SectionOrganizationList.vue"
import SectionOrganizationUsers from "./SectionOrganizationUsers.vue"
import SectionLayoutForm from "@/components/layout/SectionLayoutForm.vue"
import { useOrganizationUsersAllQuery } from "@/queries/organizations/useOrganizationUsers"
import { useOrgInvitationsAllQuery } from "@/queries/org-invitations/useOrgInvitationsQuery"

type FormFields = {
    primary_color: string
    secondary_color: string
    name: string | null
    logo: string | null
    is_active: boolean
}

const { toBase64 } = useBase64()
const notification = useNotification()
const organizationStore = useOrganizationStore()
const { orgId, orgIdString } = storeToRefs(organizationStore)
const { axios, isAxiosError } = useAxios()
const loading = ref(false)
const showDeleteModal = ref(false)
const logoFile = ref<File | null>(null)
const logoUpload = ref<ComponentPublicInstance<HTMLInputElement> | null>(null)
const colorStore = useColorsStore()
const formErrors = ref<GFFormErrors>(new GFFormErrors())
const hasChanges = computed(
    () => !isEqual(initialFormData.value, formData.value)
)
const initialFormData = ref<FormFields>({
    primary_color: colorStore.primary,
    secondary_color: colorStore.secondary,
    logo: null,
    is_active: organizationStore.organization
        ? organizationStore.organization.is_active
        : true,
    name: organizationStore.organization
        ? organizationStore.organization.name
        : null,
})
const formData = ref(cloneDeep(initialFormData.value))

const logoBase64 = computed(() => {
    if (!logoFile.value) {
        return (
            organizationStore.organization &&
            organizationStore.organization.logo_base64
        )
    }

    return URL.createObjectURL(logoFile.value)
})

organizationStore.$subscribe(() => {
    setInitial({
        primary_color: colorStore.primary,
        secondary_color: colorStore.secondary,
        logo: null,
        is_active: organizationStore.organization
            ? organizationStore.organization.is_active
            : true,
        name: organizationStore.organization
            ? organizationStore.organization.name
            : null,
    })
})

const { data: users } = useOrganizationUsersAllQuery(orgId)
const { data: invitees } = useOrgInvitationsAllQuery(orgIdString)

onMounted(() => {
    setInitial(initialFormData.value)
})

watch(
    () => formData.value.primary_color,
    (hex) => {
        if (hex) {
            colorStore.primary = hex
            colorStore.setCSSVars()
        }
    }
)

watch(
    () => formData.value.secondary_color,
    (hex) => {
        if (hex) {
            colorStore.secondary = hex
            colorStore.setCSSVars()
        }
    }
)

watch(logoFile, async (val) => {
    if (val) {
        formData.value.logo = (await toBase64(val)) as string
    } else {
        formData.value.logo = null
    }
})

function toggleDeleteModal() {
    showDeleteModal.value = !showDeleteModal.value
}

async function deleteOrganization() {
    if (organizationStore.organization) {
        try {
            loading.value = true
            await Promise.all([
                // Switch to personal org
                organizationStore.changeOrganization(null),
                axios.delete(
                    "/api/organizations/" + organizationStore.organization.id
                ),
                organizationStore.init(),
            ])

            notification.open({
                message: "Organization deleted",
                title: "Success",
                type: "success",
            })
        } catch (error) {
            if (isAxiosError(error)) {
                notification.open({
                    message: "There was a problem deleting the organization",
                    title: "Error",
                    type: "danger",
                })
            }
        } finally {
            loading.value = false
            toggleDeleteModal()
        }
    }
}

async function save() {
    if (organizationStore.organization) {
        try {
            loading.value = true
            await axios.put(
                `/api/organizations/${organizationStore.organization?.id}`,
                formData.value
            )
            organizationStore.organization.primary_color = colorStore.primary
            organizationStore.organization.secondary_color =
                colorStore.secondary

            notification.open({
                title: "Success",
                message: "Organization updated successfully",
                type: "success",
            })

            formErrors.value.clear()
        } catch (error) {
            if (isAxiosError(error)) {
                formErrors.value = new GFFormErrors(
                    error.response?.data as ApiError
                )
            }
            notification.open({
                title: "Error",
                message: "Error updating organization",
                type: "danger",
            })
        } finally {
            setInitial(formData.value)
            await organizationStore.init()
            loading.value = false
            logoFile.value = null
        }
    }
}

/**
 * Populates the form with initial data.
 * Handles both the initial data and form data objects
 * @param initial Initial form data to use
 */
function setInitial(initial: FormFields) {
    initialFormData.value = cloneDeep(initial)
    formData.value = cloneDeep(initial)
}

function fileAdded(event: Event) {
    const files = (event.target as HTMLInputElement).files
    if (files) {
        logoFile.value = files[0]
    }
}

function openUploader() {
    logoUpload.value?.click()
}
</script>
