import { useRouteQuery } from "@/composables/useRouteQuery"
import type PDF from "@/models/PDF"
import { defineStore } from "pinia"
import config from "@/config"
import type { GallerySortOption } from "types/types"

type GalleryCardSize = "sm" | "md" | "lg"

export const useDocumentStore = defineStore("documents", () => {
    /**
     * If populated, the document list (gallery) will attempt to automatically scroll to this PDF.
     */
    const lastOpenPDF = ref<PDF["id"] | null>(null)
    const lastScrollY = ref<number | null>(null)
    const galleryGridSize = ref<GalleryCardSize>("md")

    const gallerySort = ref<GallerySortOption>(config.galleryDefaultSort)

    function hideGalleryNewDocumentButton() {
        const route = useRoute()
        const { documentsTabQuery } = useRouteQuery()

        if (route.name === "signing") return true
        if (route.name === "templates") return true
        // show for documents

        if (documentsTabQuery.value === "favorite") return true
        if (documentsTabQuery.value === "deleted") return true

        return false
    }

    function hideGalleryNewFolderButton() {
        const route = useRoute()
        const { documentsTabQuery } = useRouteQuery()

        if (route.name === "signing") return true
        // show for templates
        // show for documents

        if (documentsTabQuery.value === "favorite") return true
        if (documentsTabQuery.value === "deleted") return true

        return false
    }

    return {
        lastOpenPDF,
        lastScrollY,
        galleryGridSize,
        gallerySort,
        hideGalleryNewDocumentButton,
        hideGalleryNewFolderButton,
    }
})
