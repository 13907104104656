/* eslint-disable @typescript-eslint/no-explicit-any */

import type { App } from "vue"
import Echo from "laravel-echo"
import useEnv from "@/composables/useEnv"
import { PresenceChannel } from "pusher-js"
import useAxios from "@/composables/useAxios"

export const echo = new Echo({
    ...(useEnv().isUsingLocalWebsockets && {
        key: import.meta.env.VITE_ECHO_KEY,
        wsHost: import.meta.env.VITE_ECHO_WSHOST,
        wsPort: import.meta.env.VITE_ECHO_WSPORT,
        wssPort: import.meta.env.VITE_ECHO_WSPORT,
        broadcaster: import.meta.env.VITE_ECHO_BROADCASTER,
        forceTLS: false,
        disableStats: true,
    }),

    ...(useEnv().isUsingLiveWebsockets && {
        broadcaster: import.meta.env.VITE_ECHO_BROADCASTER,
        key: import.meta.env.VITE_ECHO_KEY,
        cluster: import.meta.env.VITE_ECHO_CLUSTER,
        forceTLS: true,
    }),

    client: PresenceChannel,
    /**
     * @see https://laravel.com/docs/9.x/broadcasting#customizing-the-authorization-request
     */
    authorizer: (channel: any, _options: any) => {
        return {
            authorize: (socketId: any, callback: any) => {
                useAxios()
                    .axios.post("/api/broadcasting/auth", {
                        socket_id: socketId,
                        channel_name: channel.name,
                        withCredentials: true,
                        baseURL: useEnv().hostURL,
                    })
                    .then((response: any) => {
                        callback(false, response.data)
                    })
                    .catch((error: any) => {
                        callback(true, error)
                    })
            },
        }
    },
})

export default {
    install: (app: App) => {
        app.config.globalProperties.$echo = echo
        app.provide("echo", echo)
    },
}
