<template>
    <div>
        <label
            :for="name"
            class="flex justify-between text-sm font-medium text-gray-700"
        >
            <slot>{{ label }}</slot>

            <span
                v-if="showOptional"
                class="ml-auto mr-0.5 font-normal italic text-gray-400"
            >
                Optional
            </span>
        </label>
        <select
            :id="name"
            v-model="selected"
            :name="name"
            :disabled="disabled"
            :class="[label && 'mt-1']"
            class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-sm focus:border-primary focus:outline-none focus:ring-primary"
        >
            <option
                v-for="(option, index) in options"
                :key="index"
                :value="option.value"
            >
                {{ option.label }}
            </option>
        </select>
    </div>
</template>
<script setup lang="ts">
const emit = defineEmits<{
    (e: "update:modelValue", p: string | number | undefined | null): void
}>()

const props = defineProps<{
    modelValue?: string | number | undefined | null
    /** Not bound via v-bind */
    value?: string | number | undefined | null
    label?: string
    name: string
    options: {
        label: string | number
        value: string | number | null
    }[]
    disabled?: boolean
    showOptional?: boolean
}>()

const selected = computed<string | number | undefined | null>({
    get() {
        return props.modelValue ?? props.value
    },
    set(value) {
        emit("update:modelValue", value)
    },
})
</script>
