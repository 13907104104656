import type User from "@/models/User"
import type {
    ApiUpdateUserSignature,
    ApiError,
    ApiUserSignatureData,
} from "types/api"
import type { AxiosError } from "axios"
import useAxios from "@/composables/useAxios"
import UserSignature from "@/models/UserSignature"
import {
    useMutation,
    useQueryClient,
    type UseMutationOptions,
} from "@tanstack/vue-query"
import { QueryKey } from "../QueryKey"

export function useUserSignatureUpdate(
    config?: UseMutationOptions<
        UserSignature,
        AxiosError<ApiError>,
        {
            payload: ApiUpdateUserSignature
            userId: User["id"] | "me"
            userSigId: UserSignature["id"]
        },
        unknown
    >
) {
    const queryClient = useQueryClient()
    const { axios } = useAxios()

    return useMutation(
        ({ payload, userId, userSigId }) =>
            axios
                .put<ApiUserSignatureData>(
                    `/api/users/${userId}/signatures/${userSigId}`,
                    payload
                )
                .then((resp) => new UserSignature(resp.data)),
        {
            onSettled() {
                queryClient.invalidateQueries(
                    QueryKey.baseKey("user-signatures")
                )
            },
            ...config,
        }
    )
}
