<template>
    <GFTable
        :cols="[['fullName', 'Name'], ['role', 'Role'], 'actions']"
        :data="users"
    >
        <template #fullName="{ row }">
            <div class="flex items-center">
                <div
                    v-if="row.user_avatar_base64"
                    class="h-10 w-10 flex-shrink-0"
                >
                    <GFAvatar
                        :src="row.user_avatar_base64"
                        show-status
                        :color="row.user?.statusColor"
                        size="lg"
                    />
                </div>
                <div class="ml-4">
                    <div class="flex justify-between font-medium text-gray-900">
                        <span class="truncate">
                            {{ row.fullName }}
                        </span>

                        <GFTag
                            v-if="row.user_id === user?.id"
                            label="Me"
                            class="ml-3"
                        />
                    </div>

                    <div class="truncate text-gray-500">
                        {{ row.user?.email }}
                    </div>
                </div>
            </div>
        </template>
        <template #role="{ row }">
            <div v-if="row.user_id === user?.id">
                <GFTag :label="row.role" />
            </div>

            <GFSelectNative
                v-else-if="userIsAdmin"
                name="role"
                class="w-28"
                :value="row.role"
                :options="roles"
                @update:model-value="(value) => onUserRoleUpdate(row.user_id, value as OrganizationUserRole)"
            />

            <div v-else>
                <GFTag :label="row.role" />
            </div>
        </template>
        <template #actions="{ row }">
            <div v-if="row.user_id === user?.id">
                <GFButtonText
                    size="sm"
                    type="danger"
                    @click="deleteOrgUser = row"
                >
                    Leave organization
                </GFButtonText>
            </div>
            <GFButtonText
                v-else-if="userIsAdmin"
                size="sm"
                type="danger"
                @click="deleteOrgUser = row"
            >
                Remove
            </GFButtonText>
        </template>
    </GFTable>

    <ConfirmDeleteOrgUser
        v-if="organization"
        v-model:show="showConfirmDeleteUser"
        v-model:org-user="deleteOrgUser"
        :org="organization"
    />
</template>

<script lang="ts" setup>
import type { PropType } from "vue"
import GFTag from "../base/GFTag.vue"
import type User from "@/models/User"
import GFTable from "../base/GFTable.vue"
import GFAvatar from "../base/GFAvatar.vue"
import { useUserStore } from "@/stores/user"
import GFButtonText from "../base/GFButtonText.vue"
import type { OrganizationUserRole } from "types/api"
import GFSelectNative from "../base/GFSelectNative.vue"
import useNotification from "@/composables/useNotification"
import { useOrganizationStore } from "@/stores/organization"
import type OrganizationUser from "@/models/OrganizationUser"
import ConfirmDeleteOrgUser from "../modals/ConfirmDeleteOrgUser.vue"
import { useOrganizationAuthUser } from "@/composables/useOrganizationAuthUser"
import { useOrganizationUsersUpdate } from "@/queries/organizations/useOrganizationUsersUpdate"

defineProps({
    users: {
        type: Array as PropType<OrganizationUser[]>,
        required: true,
    },
})

const showConfirmDeleteUser = ref(false)
const { user } = storeToRefs(useUserStore())
const deleteOrgUser = ref<null | OrganizationUser>(null)
const organizationStore = useOrganizationStore()
const { orgId, organization } = storeToRefs(organizationStore)
const { mutate: updateOrgUser } = useOrganizationUsersUpdate()

const { userIsAdmin } = useOrganizationAuthUser()

const roles: { label: string; value: OrganizationUserRole }[] = [
    { value: "admin", label: "Admin" },
    {
        value: "standard",
        label: "Standard",
    },
]

watch(deleteOrgUser, (id) => {
    if (id) showConfirmDeleteUser.value = true
})

function onUserRoleUpdate(
    userId: User["id"],
    value: OrganizationUserRole,
    isMe?: boolean
) {
    if (orgId.value)
        updateOrgUser(
            { organizationId: orgId.value, userId, payload: { role: value } },
            {
                onSuccess() {
                    useNotification().open({
                        title: "Success",
                        message: "User role updated.",
                        type: "success",
                    })
                    if (isMe) organizationStore.changeOrganization(null)
                },
            }
        )
}
</script>
