<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="rounded-md p-4" :class="[classes]">
        <div class="flex">
            <div class="flex-shrink-0">
                <GFIcon class="h-5 w-5" :class="[iconColor]" :icon="icon" />
            </div>

            <div
                class="ml-3 flex-1 space-y-1 text-sm md:flex md:space-y-0 md:space-x-1"
                :class="[textColor]"
            >
                <div class="flex space-x-0.5">
                    <span
                        v-if="subscriptionPlanHtml"
                        v-html="subscriptionPlanHtml"
                    />
                    <span>{{ subscriptionText }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import type { PropType } from "vue"
import type { ModelSubscription } from "@/models/ModelSubscription"
import GFIcon from "../base/GFIcon.vue"

const props = defineProps({
    isOrg: {
        type: Boolean,
        required: true,
    },
    subscription: {
        type: [Object, null] as PropType<ModelSubscription | null>,
        required: true,
    },
})

const type = computed<"info" | "warning">(() => {
    if (props.isOrg && props.subscription === null) return "warning"
    if (isGracePeriod.value) return "warning"

    if (props.subscription?.stripe_status === "active") return "info"
    if (props.subscription?.stripe_status === "trialing") return "info"

    if (props.subscription?.stripe_status === "canceled") return "warning"
    if (props.subscription?.stripe_status === "incomplete") return "warning"
    if (props.subscription?.stripe_status === "incomplete_expired")
        return "warning"
    if (props.subscription?.stripe_status === "past_due") return "warning"
    if (props.subscription?.stripe_status === "unpaid") return "warning"

    return "info"
})

const isFreePlan = computed(() => {
    return props.subscription === null
})
const isGracePeriod = computed(() => props.subscription?.isGracePeriod)

const subscriptionPlanHtml = computed(() => {
    if (props.subscription?.stripe_status === "active")
        return `You're on the <b>${props.subscription.stripe_product.name}</b> plan.`

    if (props.subscription?.stripe_status === "canceled")
        return `You've canceled your <b>${props.subscription.stripe_product.name}</b> plan.`

    if (props.subscription?.stripe_status === "trialing")
        return `You're on the <b>${props.subscription.stripe_product.name}</b> plan free trial.`

    if (props.subscription?.stripe_status === "past_due")
        return `There are issues with your <b>${props.subscription.stripe_product.name}</b> plan subscription.`
    if (props.subscription?.stripe_status === "incomplete")
        return `There are issues with your <b>${props.subscription.stripe_product.name}</b> plan subscription.`
    if (props.subscription?.stripe_status === "incomplete_expired")
        return `There are issues with your <b>${props.subscription.stripe_product.name}</b> plan subscription.`
    if (props.subscription?.stripe_status === "unpaid")
        return `There are issues with your <b>${props.subscription.stripe_product.name}</b> plan subscription.`

    return null
})

const isFreePlanTextUser = ref("You are on the Free Plan.")
const isFreePlanTextOrg = ref(
    "Your organization must be subscribed to a plan to use Galaxy Forms."
)

const subscriptionTimelineText = computed(() => {
    /**
     * If the user has canceled their subscription, but is still in the grace period.
     */
    if (isGracePeriod.value)
        return `Your subscription is set to expire on ${props.subscription?.endsAtReadableDate}.`

    /**
     * If the user is still in their free trial.
     */
    if (props.subscription?.stripe_status === "trialing")
        return `Your subscription is set to begin on ${props.subscription?.renewsAtReadableDate}.`

    /**
     * If the user has canceled their subscription, and the grace period has ended.
     */
    if (
        !isGracePeriod.value &&
        props.subscription?.stripe_status === "canceled"
    )
        return `Your subscription has expired as of ${props.subscription.endsAtReadableDate}.`

    /**
     * If the user has a renews at date.
     */
    if (props.subscription?.renews_at)
        return `Your subscription is set to renew on ${props.subscription?.renewsAtReadableDate}.`

    /**
     * In this case we are not sure what the subscription status is, so we just return an empty string.
     */
    return ``
})

const subscriptionText = computed(() => {
    if (props.isOrg && isFreePlan.value) return isFreePlanTextOrg.value
    if (isFreePlan.value) return isFreePlanTextUser.value
    else return subscriptionTimelineText.value
})

const classes = computed(() => {
    return {
        "bg-blue-50": type.value === "info",
        "bg-orange-50": type.value === "warning",
    }
})

const iconColor = computed(() => {
    return {
        "text-blue-400": type.value === "info",
        "text-orange-400": type.value === "warning",
    }
})

const icon = computed(() => {
    return {
        info: "info-circle",
        warning: "exclamation-circle",
    }[type.value]
})

const textColor = computed(() => {
    return {
        info: "text-blue-700",
        warning: "text-orange-700",
    }[type.value]
})
</script>
