import axios, { type AxiosInstance } from "axios"
import useEnv from "./useEnv"
import { onError } from "@/queries/errorHandling"
import { useStatelessAuth } from "./useStatelessAuth"

let axiosInstance: null | AxiosInstance = null
/** Just an instance of axios for hitting public urls */
let axiosPublic: null | AxiosInstance = null
/** Same instance of axios, except without error handling */
let axiosInstanceDangerous: null | AxiosInstance = null

export default function useAxios(
    {
        withCredentials,
    }: {
        withCredentials: boolean
    } = {
        withCredentials: true,
    }
) {
    const setAxiosToken = (token: string) => {
        if (axiosInstance)
            axiosInstance.defaults.headers.common["Authorization"] =
                "Bearer " + token
        if (axiosInstanceDangerous)
            axiosInstanceDangerous.defaults.headers.common["Authorization"] =
                "Bearer " + token
    }

    /**
     * If there is no axios instance yet, instantiate one.
     * Otherwise use the current instance.
     */
    if (!axiosInstance || !axiosInstanceDangerous || axiosPublic) {
        axiosInstance = axios.create()
        axiosInstanceDangerous = axios.create()
        axiosPublic = axios.create()

        const { isStateless, testEnv } = useStatelessAuth()

        if (withCredentials) {
            axiosInstance.defaults.withCredentials = true
            axiosInstanceDangerous.defaults.withCredentials = true
        }

        axiosInstance.defaults.baseURL = useEnv().hostURL
        axiosInstanceDangerous.defaults.baseURL = useEnv().hostURL

        /**
         * Stateless config
         * Add header for stateless calls
         */
        if (isStateless) {
            const token = localStorage.getItem("gf-token")
            token && setAxiosToken(token)
        }

        /**
         * If in the test environment, include the cypress seed data to access the test database.
         */
        if (testEnv.value) {
            const seed = window.localStorage.getItem("cypress-seed")
            if (seed) {
                axiosInstance.defaults.headers.common["Cypress-Seed"] = seed
                axiosInstanceDangerous.defaults.headers.common["Cypress-Seed"] =
                    seed
            }
        }

        axiosInstance.interceptors.response.use(
            function (response) {
                // Any status code that lie within the range of 2xx cause this function to trigger
                // Do something with response data
                return response
            },
            function (error) {
                // Any status codes that falls outside the range of 2xx cause this function to trigger
                // Do something with response error

                onError(error)
                return Promise.reject(error)
            }
        )

        axiosInstance.interceptors.request.use((config) => {
            if (!config.headers) return config

            const urlParams = new URLSearchParams(window.location.search)

            /**
             * If signing in person, add the "signing as" signee-id to the request headers.
             */
            const signingAsSigneeId = urlParams.get("signing_as")

            if (signingAsSigneeId) {
                config.headers["x-gf-in-person-signee"] = signingAsSigneeId
            }

            return config
        })
    }

    /**
     * Axios does not respect a param with the value of null, it will be removed from the param object.
     * If null must be sent as a param value, it must be string for axios to respect it.
     * This function wraps all null values and convert them to 'null'
     * @param params
     */
    function makeSafeParams(params: Record<string, string | null>) {
        const safeParams: Record<string, string> = {}
        Object.keys(params).forEach((key) => {
            // Undefined params should not be set to null
            if (params[key] === undefined) return

            safeParams[key] = params[key] ?? "null"
        })
        return safeParams
    }

    return {
        axios: axiosInstance,
        axiosInstanceDangerous,
        makeSafeParams,

        /**
         * Loading the static method for use throughout the app
         */
        isAxiosError: axios.isAxiosError,
        setAxiosToken,
        axiosPublic,
    }
}
