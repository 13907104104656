import type { SubscriptionPlan } from "@/models/SubscriptionPlan"

export function useStaticSite() {
    const staticSiteUrl = import.meta.env.VITE_STATIC_SITE_URL

    function goToStaticSite(path = "/") {
        return window.open(staticSiteUrl + path, "_blank")
    }
    function goToStaticSiteBeta(
        config?: Partial<{ plans: SubscriptionPlan["id"][] }>
    ) {
        if (config) {
            const { plans } = config
            if (plans) {
                const plansString = plans.join(",")
                return window.open(
                    `${staticSiteUrl}/?beta=true&plans=${plansString}#pricing`,
                    "_blank"
                )
            }
        }

        return window.open(staticSiteUrl, "_blank")
    }

    return {
        goToStaticSite,
        goToStaticSiteBeta,
    }
}
