<template>
    <div class="min-w-full space-y-1">
        <div
            v-for="(tab, i) in tabs"
            :key="i"
            @click="() => !locked && $emit('update:selected', tab)"
        >
            <slot :name="tab.value" :tab="tab">
                <GFVerticalTab
                    :tab="tab"
                    :selected="selected?.value === tab.value"
                ></GFVerticalTab>
            </slot>
        </div>
    </div>
</template>
<script setup lang="ts">
import type { PropType } from "vue"
import type { VerticalTab } from "types/types"
import GFVerticalTab from "./GFVerticalTab.vue"

const emit = defineEmits<{
    (e: "update:selected", tab: VerticalTab): void
}>()

const props = defineProps({
    tabs: {
        type: Array as PropType<VerticalTab[]>,
        required: true,
    },
    selected: {
        type: Object as PropType<VerticalTab | null>,
        default: null,
    },
    locked: {
        type: Boolean,
        default: false,
    },
})

onMounted(() => {
    if (!props.selected && !props.tabs[0])
        emit("update:selected", props.tabs[0])
})
</script>
