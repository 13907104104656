import { POSITION, useToast } from "vue-toastification"
import type {
    CommonOptions,
    RenderableToastContent,
} from "vue-toastification/dist/types/types"
import DefaultNotification from "@/components/toast/DefaultNotification.vue"

export type NotificationType =
    | "default"
    | "success"
    | "error"
    | "info"
    | "danger"
    | "warning"
    | "forbidden"

export interface Notification {
    type?: NotificationType
    title: string
    message: string
    /**
     * The duration of the notification. If false is provided, the notification will persist until manually closed.
     * @default 5000
     */
    duration?: number | false
    actions?: Action[]
    content?: RenderableToastContent
    draggable?: boolean
    hideCloseButton?: boolean
}

export interface Action {
    text: string
    type: "primary" | "secondary"
    click: ({ close }: { close: () => void }) => void
}

const defaults = {
    type: "default",
    title: "",
    message: "",
    duration: 5000,
    actions: [],
    draggable: true,
    hideCloseButton: false,
}

export default function useNotification() {
    function open({
        type: notificationType,
        title,
        message,
        duration,
        actions,
        draggable,
        hideCloseButton = defaults.hideCloseButton,
    }: Notification) {
        // This allows the use of 'danger' as a type in place of toasts 'warning' type
        const type =
            notificationType === "danger" ? "warning" : notificationType
        const toast = useToast()

        const toastConfig: CommonOptions = {
            timeout: duration ?? defaults.duration,
            toastClassName:
                "gf-notification bg-white shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden text-black p-0",
            icon: false,
            position: POSITION.TOP_RIGHT,
            closeButton: false,
            closeOnClick: false,
            draggable: draggable ?? defaults.draggable,
        }

        if (type === "default" || !type) {
            toast(
                {
                    component: DefaultNotification,
                    props: {
                        message,
                        title,
                        actions,
                        hideCloseButton,
                    },
                },
                toastConfig
            )
        } else if (type === "forbidden") {
            toast(
                {
                    component: DefaultNotification,
                    props: {
                        message,
                        title,
                        actions,
                        type: type,
                        hideCloseButton,
                    },
                },
                toastConfig
            )
        } else {
            toast[type](
                {
                    component: DefaultNotification,
                    props: {
                        message,
                        title,
                        actions,
                        type: type,
                        hideCloseButton,
                    },
                },
                toastConfig
            )
        }
    }
    return { open }
}
