import type { ApiError } from "types/api"

export default class GFFormErrors {
    error: Error

    constructor(error?: ApiError) {
        this.error = error ?? {
            message: null,
            errors: {},
        }
    }

    public has(key: string): boolean {
        if (this.error.errors) return key in this.error.errors
        return false
    }

    public first(key: string): string | null {
        if (this.error.errors) return this.error.errors[key][0] ?? null
        return null
    }

    public clear(): void {
        this.error = {
            message: null,
            errors: {},
        }
    }
}

export interface Error {
    message: string | null
    errors?: FieldErrors
}

export interface FieldErrors {
    [key: string]: string[]
}
