<template>
    <SectionLayout
        v-if="organizationStore.orgId !== 'private'"
        id="organization-users"
        title="Users"
        description="View and manage your organization's users."
        class="mt-8"
        is-table
    >
        <OrgUsersTable :users="users" />
    </SectionLayout>
</template>
<script setup lang="ts">
import type { PropType } from "vue"
import { useOrganizationStore } from "@/stores/organization"
import type OrganizationUser from "@/models/OrganizationUser"
import SectionLayout from "@/components/layout/SectionLayout.vue"
import OrgUsersTable from "@/components/tables/OrgUsersTable.vue"

defineProps({
    users: {
        type: Array as PropType<OrganizationUser[]>,
        required: true,
    },
})

const organizationStore = useOrganizationStore()
</script>
