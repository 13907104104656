<template>
    <div class="flex flex-col space-y-8">
        <SectionLayout
            title="Subscription Plans"
            description="Here is a complete list of all the subscription plans available for our users. List sorted by order."
            is-table
        >
            <template #actions>
                <GFButton @click="showNewPlanModal = true">Create new</GFButton>
            </template>
            <div v-if="isLoadingPlans" class="flex items-center justify-center">
                <GFSpinner class="h-10 w-10" />
            </div>
            <GFTable
                v-if="!isLoadingPlans"
                :key="isRefetchingPlans ? 'refetching' : 'not-refetching'"
                :data="subscriptionPlans"
                reorderable
                :cols="[
                    ['name', 'Name'],
                    ['description', 'Description'],
                    ['is_published', 'Published'],
                    'actions',
                ]"
                @reorder="onReorder"
            >
                <template #is_published="{ fieldData: isPublished }">
                    <GFTag v-if="isPublished" color="green">Published</GFTag>
                    <GFTag v-if="!isPublished" color="gray">Draft</GFTag>
                </template>
                <template #actions="{ row }">
                    <GFButtonText
                        size="sm"
                        class="-m-1"
                        @click="
                            $router.push({
                                name: 'subscription-plan',
                                params: { subscriptionPlanId: row.id },
                            })
                        "
                    >
                        View
                    </GFButtonText>
                </template>
                <template #first-row>
                    <div></div>
                </template>
            </GFTable>
        </SectionLayout>

        <SectionLayout
            title="Preview UI"
            description="Check the plans you'd like to preview on the marketing site. By default, published plans are checked."
            is-table
        >
            <template #actions>
                <GFButton @click="onStaticPreview">Preview UI</GFButton>
            </template>
            <div v-if="isLoadingPlans" class="flex items-center justify-center">
                <GFSpinner class="h-10 w-10" />
            </div>
            <GFTable
                v-if="!!subscriptionPlans && !isLoadingPlans"
                v-model:selected="selectedPlans"
                :data="subscriptionPlans"
                :cols="[
                    'checkbox',
                    ['id', 'Id'],
                    ['name', 'Name'],
                    ['order', 'Order'],
                    ['is_published', 'Published'],
                ]"
            >
                <template #is_published="{ fieldData: isPublished }">
                    <GFTag v-if="isPublished" color="green">Published</GFTag>
                    <GFTag v-if="!isPublished" color="gray">Draft</GFTag>
                </template>
            </GFTable>
        </SectionLayout>

        <SubscriptionPlanFormModal v-model:show="showNewPlanModal" />
    </div>
</template>
<script setup lang="ts">
import GFTag from "../base/GFTag.vue"
import GFTable from "../base/GFTable.vue"
import GFButton from "../base/GFButton.vue"
import GFSpinner from "../base/GFSpinner.vue"
import GFButtonText from "../base/GFButtonText.vue"
import SectionLayout from "../layout/SectionLayout.vue"
import { useStaticSite } from "@/composables/useStaticSite"
import type { SubscriptionPlan } from "@/models/SubscriptionPlan"
import SubscriptionPlanFormModal from "./SubscriptionPlanFormModal.vue"
import { useSubscriptionPlansQuery } from "@/queries/subscription-plans/useSubscriptionPlansQuery"
import { useSubscriptionPlansReorder } from "@/queries/subscription-plans/useSubscriptionPlansReorder"

const { goToStaticSiteBeta } = useStaticSite()
const {
    data: subscriptionPlans,
    /**
     * //Fixme: I needed to do this due to getting weird behavior with the sortable plugin and new data from the reorder mutation
     * I ended up doing this, which creates a weird flicker when the table is refetched, but it works.
     */
    isRefetching: isRefetchingPlans,
    isLoading: isLoadingPlans,
} = useSubscriptionPlansQuery()

const { mutate: updateOrder } = useSubscriptionPlansReorder()

const selectedPlans = ref<SubscriptionPlan[]>([])

/**
 * Set the selected plans to the published plans by default
 */
watch(
    [subscriptionPlans],
    ([plans]) => {
        if (plans)
            selectedPlans.value = plans.filter((plan) => plan.is_published)
    },
    { immediate: true }
)

const showNewPlanModal = ref(false)

function onStaticPreview() {
    goToStaticSiteBeta({ plans: selectedPlans.value.map((plan) => plan.id) })
}

/**
 * Reorder the subscription plans
 */
function onReorder(newOrder: string[]) {
    updateOrder(newOrder.map((idString) => idString))
}
</script>
