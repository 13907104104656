import type User from "@/models/User"
import useAxios from "@/composables/useAxios"
import type Organization from "@/models/Organization"
import { useMutation, useQueryClient } from "@tanstack/vue-query"
import useNotification from "@/composables/useNotification"
import type { SubscriptionPlanPriceInterval } from "types/api"
import type { SubscriptionPlan } from "@/models/SubscriptionPlan"
import type { ModelSubscription } from "@/models/ModelSubscription"
import { QueryKey } from "../QueryKey"

interface Payload {
    subscription_plan_id: SubscriptionPlan["id"]
    interval: SubscriptionPlanPriceInterval
    users?: number
    promo_code?: null | string
}

export function useModelSubscriptionSwap() {
    const queryClient = useQueryClient()

    return useMutation(
        (payload: {
            modelType: ModelSubscription["modelType"]
            modelId: User["id"] | Organization["id"]
            payload: Payload
        }) =>
            useAxios().axios.put(
                `/api/${payload.modelType}/${payload.modelId}/billing/subscription/swap`,
                payload.payload
            ),
        {
            onSuccess() {
                queryClient.invalidateQueries(
                    QueryKey.baseKey("model-subscriptions")
                )
                queryClient.invalidateQueries(QueryKey.baseKey("invoices"))

                useNotification().open({
                    type: "success",
                    title: "Subscription updated",
                    message: "Your subscription has been updated. Thank you!",
                })
            },
        }
    )
}
