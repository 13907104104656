import useAxios from "@/composables/useAxios"
import type { Alert } from "@/models/Alert"
import { alertBaseKey } from "./useAlertQuery"
import { useMutation, useQueryClient } from "@tanstack/vue-query"

export function useAlertDelete() {
    const queryClient = useQueryClient()

    return useMutation(
        [alertBaseKey],
        (id: Alert["id"]) =>
            useAxios().axios.delete(`/api/system/alerts/${id}`),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([alertBaseKey])
            },
        }
    )
}
