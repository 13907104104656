<template>
    <form
        class="flex flex-col space-y-4"
        v-bind="$attrs"
        @submit.prevent="$emit('submit')"
    >
        <GFToggleSimple
            v-if="!isEdit"
            v-model="isStripePlan"
            label="Stripe plan?"
        />

        <template v-if="isStripePlan">
            <GFInputCombobox
                v-if="!isEdit"
                ref="stripeProductIdCombobox"
                v-model="stripeProductInput"
                display-field="name"
                by="name"
                :display-value="(item: any) => item?.name ?? ''"
                :display-option="(item: any) => item?.name ?? ''"
                :loading="stripeProductsIsLoading"
                placeholder="Pro plan"
                :list="stripeProducts"
                label="Stripe product ID"
                data-testid="stripe-product-id-combobox"
                name="stripe_product_id"
                class="w-full"
            >
                <template #option="{ item, selected }">
                    <div
                        :class="[
                            'flex justify-between',
                            selected && 'font-semibold',
                        ]"
                    >
                        <span class="truncate">{{ item.name }}</span>
                        <span class="text-gray-300">{{ item.id }}</span>
                    </div>
                </template>
            </GFInputCombobox>

            <template v-if="(!isEdit && !!stripeProductInput) || isEdit">
                <GFInputCombobox
                    ref="stripeMonthlyPriceIdCombobox"
                    v-model="stripeMonthlyPriceInput"
                    :list="stripePrices"
                    by="interval"
                    :loading="stripeProductsPricesIsLoading"
                    :display-value="(item: any) => item ? priceText(item) : ''"
                    :display-option="(item: any) => item?.interval ?? ''"
                    show-optional
                    placeholder="Monthly price"
                    nullable
                    label="Stripe monthly price"
                    name="stripe_monthly_price_id"
                >
                    <template #option="{ item, selected }">
                        <div
                            :class="[
                                'flex justify-between',
                                selected && 'font-semibold',
                            ]"
                        >
                            <span class="truncate">
                                {{ priceText(item as any) }}
                            </span>
                            <span class="text-gray-300">{{ item.id }}</span>
                        </div>
                    </template>
                </GFInputCombobox>
                <GFInputCombobox
                    ref="stripeYearlyPriceIdCombobox"
                    v-model="stripeYearlyPriceInput"
                    :list="stripePrices"
                    by="interval"
                    :loading="stripeProductsPricesIsLoading"
                    :display-value="(item: any) => item ? priceText(item) : ''"
                    :display-option="(item: any) => item?.interval ?? ''"
                    show-optional
                    placeholder="Yearly price"
                    nullable
                    label="Stripe yearly price"
                    name="stripe_yearly_price_id"
                >
                    <template #option="{ item, selected }">
                        <div
                            :class="[
                                'flex justify-between',
                                selected && 'font-semibold',
                            ]"
                        >
                            <span class="truncate">
                                {{ priceText(item as any) }}
                            </span>
                            <span class="text-gray-300">{{ item.id }}</span>
                        </div>
                    </template>
                </GFInputCombobox>

                <GFSelectNative
                    v-if="!isEdit"
                    v-model="order"
                    label="Order"
                    :loading="stripeProductsPricesIsLoading"
                    :options="options"
                    show-optional
                    data-testid="subscription-plan-form-order-select"
                    name="order"
                />

                <SubscriptionPlanButtonFields
                    v-model:button-text="buttonText"
                    v-model:button-url="buttonUrl"
                />
            </template>
        </template>

        <template v-if="!isStripePlan">
            <GFInput v-model="fallbackName" label="Name" autofocus />

            <GFInput v-model="fallbackDescription" label="Description" />

            <div class="flex flex-grow space-x-2">
                <GFInput
                    v-model="fallbackMonthlyPrice"
                    label="Monthly price"
                    class="w-full"
                />

                <GFInput
                    v-model="fallbackYearlyPrice"
                    label="Yearly price"
                    class="w-full"
                />
            </div>

            <SubscriptionPlanButtonFields
                v-model:button-text="buttonText"
                v-model:button-url="buttonUrl"
            />
        </template>
    </form>
    <div v-if="!hideDelete">
        <div
            v-tooltip="{
                content: 'Subscriptions cannot be deleted while active',
                disabled: !isPublished,
            }"
            class="w-fit"
        >
            <GFButtonText
                type="danger"
                data-testid="delete-subscription-plan-button"
                button-style="underline"
                :loading="isDeleting"
                :class="[isPublished ? 'text-gray-500' : '']"
                :disabled="isPublished"
                @click="$emit('delete')"
            >
                Delete subscription plan
            </GFButtonText>
        </div>
    </div>
</template>
<script setup lang="ts">
import type { PropType } from "vue"
import { capitalize } from "lodash"
import GFInput from "../base/GFInput.vue"
import GFButtonText from "../base/GFButtonText.vue"
import GFSelectNative from "../base/GFSelectNative.vue"
import GFToggleSimple from "../base/GFToggleSimple.vue"
import GFInputCombobox from "../base/GFInputCombobox.vue"
import type { SubscriptionPlan } from "@/models/SubscriptionPlan"
import type { SubscriptionPlanPrice } from "@/models/SubscriptionPlanPrice"
import SubscriptionPlanButtonFields from "./SubscriptionPlanButtonFields.vue"
import { useStripeProductsQuery } from "@/queries/stripe/useStripeProductsQuery"
import type { SubscriptionPlanPriceTier } from "@/models/SubscriptionPlanPriceTier"
import { useStripeProductPricesQuery } from "@/queries/stripe/useStripeProductPricesQuery"
import { useSubscriptionPlansQuery } from "@/queries/subscription-plans/useSubscriptionPlansQuery"

const emit = defineEmits<{
    (event: "delete"): void
    (event: "submit"): void
    (
        event: "update:order",
        stripeProductId: SubscriptionPlan["order"] | null
    ): void
    (
        event: "update:stripe-product-id",
        stripeProductId: SubscriptionPlan["stripe_product_id"] | null
    ): void
    (
        event: "update:stripe-monthly-price-id",
        stripeMonthlyPriceId: SubscriptionPlan["stripe_monthly_price_id"] | null
    ): void
    (
        event: "update:stripe-yearly-price-id",
        stripeYearlyPriceId: SubscriptionPlan["stripe_yearly_price_id"] | null
    ): void
    (
        event: "update:is-stripe-plan",
        isStripePlan: SubscriptionPlan["is_stripe_plan"]
    ): void
    (
        event: "update:fallback-name",
        fallbackName: SubscriptionPlan["fallback_name"] | null
    ): void
    (
        event: "update:fallback-description",
        fallbackDescription: SubscriptionPlan["fallback_description"] | null
    ): void
    (
        event: "update:fallback-monthly-price",
        fallbackMonthlyPrice: SubscriptionPlan["fallback_monthly_price"] | null
    ): void
    (
        event: "update:fallback-yearly-price",
        fallbackYearlyPrice: SubscriptionPlan["fallback_yearly_price"] | null
    ): void
    (
        event: "update:button-text",
        buttonText: SubscriptionPlan["button_txt"] | null
    ): void
    (
        event: "update:button-url",
        buttonText: SubscriptionPlan["button_url"] | null
    ): void
}>()

const props = defineProps({
    isPublished: {
        type: Boolean,
        required: true,
    },
    hideDelete: {
        type: Boolean,
        default: false,
    },
    order: {
        type: Number as PropType<SubscriptionPlan["order"] | null>,
        default: null,
    },
    stripeProductId: {
        type: String as PropType<SubscriptionPlan["stripe_product_id"] | null>,
        default: null,
    },
    stripeMonthlyPriceId: {
        type: String as PropType<
            SubscriptionPlan["stripe_monthly_price_id"] | null
        >,
        default: null,
    },
    stripeYearlyPriceId: {
        type: String as PropType<
            SubscriptionPlan["stripe_yearly_price_id"] | null
        >,
        default: null,
    },
    isEdit: {
        type: Boolean,
        default: false,
    },
    isDeleting: {
        type: Boolean,
        default: false,
    },
    isStripePlan: {
        type: Boolean,
        default: true,
    },
    fallbackName: {
        type: String as PropType<SubscriptionPlan["fallback_name"] | null>,
        default: null,
    },
    fallbackDescription: {
        type: String as PropType<
            SubscriptionPlan["fallback_description"] | null
        >,
        default: null,
    },
    fallbackMonthlyPrice: {
        type: String as PropType<
            SubscriptionPlan["fallback_monthly_price"] | null
        >,
        default: null,
    },
    fallbackYearlyPrice: {
        type: String as PropType<
            SubscriptionPlan["fallback_yearly_price"] | null
        >,
        default: null,
    },
    buttonText: {
        type: String as PropType<SubscriptionPlan["button_txt"] | null>,
        default: null,
    },
    buttonUrl: {
        type: String as PropType<SubscriptionPlan["button_url"] | null>,
        default: null,
    },
})

const order = computed({
    get: () => props.order,
    set: (value) => {
        emit("update:order", value)
    },
})

const stripeProductInput = computed({
    get: () =>
        stripeProducts.value.find(
            (product) => product.id === props.stripeProductId
        ),
    set: (value) => emit("update:stripe-product-id", value?.id ?? ""),
})

const stripeMonthlyPriceInput = computed({
    get: () =>
        stripePrices.value.find(
            (product) => product.id === props.stripeMonthlyPriceId
        ),
    set: (value) => emit("update:stripe-monthly-price-id", value?.id ?? ""),
})

const stripeYearlyPriceInput = computed({
    get: () =>
        stripePrices.value.find(
            (product) => product.id === props.stripeYearlyPriceId
        ),
    set: (value) => emit("update:stripe-yearly-price-id", value?.id ?? ""),
})

const isStripePlan = computed({
    get: () => props.isStripePlan,
    set: (value) => emit("update:is-stripe-plan", value),
})

const fallbackName = computed({
    get: () => props.fallbackName,
    set: (value) => emit("update:fallback-name", value),
})

const fallbackDescription = computed({
    get: () => props.fallbackDescription,
    set: (value) => emit("update:fallback-description", value),
})

const fallbackMonthlyPrice = computed({
    get: () => props.fallbackMonthlyPrice,
    set: (value) => emit("update:fallback-monthly-price", value),
})

const fallbackYearlyPrice = computed({
    get: () => props.fallbackYearlyPrice,
    set: (value) => emit("update:fallback-yearly-price", value),
})

const buttonText = computed({
    get: () => props.buttonText,
    set: (value) => emit("update:button-text", value),
})

const buttonUrl = computed({
    get: () => props.buttonUrl,
    set: (value) => emit("update:button-url", value),
})

const { data: stripeProductsData, isLoading: stripeProductsIsLoading } =
    useStripeProductsQuery(!props.isEdit)

const {
    data: stripeProductsPricesData,
    isLoading: stripeProductsPricesIsLoading,
} = useStripeProductPricesQuery(
    computed(() => props.stripeProductId ?? undefined)
)

const { data: subscriptionPlans } = useSubscriptionPlansQuery()

const stripeProducts = computed(
    () => stripeProductsData.value?.map((data) => data) ?? []
)

const plansCount = computed(() => subscriptionPlans.value?.length ?? 0)

const stripePrices = computed(
    () => stripeProductsPricesData?.value?.map((data) => data) ?? []
)

const options = computed(() => {
    const options = []

    for (let i = 1; i <= plansCount.value + 1; i++) {
        options.push({
            value: i,
            label: i,
        })
    }

    return options
})

function priceText(price: SubscriptionPlanPrice) {
    return `${capitalize(price.interval)} (${price.tiers
        .map((t: SubscriptionPlanPriceTier) => "$" + t.unitCostInDollars)
        .join(" / ")})`
}

defineExpose({
    stripeProducts,
    stripePrices,
    stripeProductInput,
    stripeProductsData,
})
</script>
