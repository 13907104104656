import { computed, ref } from "vue"
import useEnv from "./useEnv"
// import { useRoute } from "vue-router"

const token = ref<string | null>(null)

/**
 * Set to true if the router is contains a stateless query param
 */
const routeStateless = ref<boolean | null>(null) // note: in order to make route stateless work, I'm gonna need to refactor the login auth guard. When you use the query param at the login route, the query param is lost due to the reroute
const envStateless = ref<boolean>(
    import.meta.env.VITE_AUTH_STATELESS === "true"
)

const testEnv = ref(sessionStorage.getItem("test-env") === "true")

const isStateless = computed(
    () =>
        !!routeStateless.value ||
        !!envStateless.value ||
        testEnv.value ||
        useEnv().usingStatelessHost
)

export const useStatelessAuth = (_tokenVal?: string) => {
    // const route = useRoute()
    // if (route) {
    //     routeStateless.value = route.query.stateless === "true"
    // }

    return {
        token,
        isStateless,
        testEnv,
    }
}
